import { html, LitElement, PropertyValues, unsafeCSS } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { getOr } from 'lodash/fp';
import { captureClicks, emitCustomEvent } from '../../core/utils';
import useStyles from '../../global-header/gntc-global-header.styles';
import { arrowSvg } from '../../shared';
import { animate } from '../../shared/animate';
import { generateHeaderMenuToggleTriggers, HeaderMenus } from '../gntc-global-header.events';
import { Language } from '../gntc-global-header.interface';

const [emitLanguageOptionToggle, addListenerOnOtherHeaderOptionToggled] =
  generateHeaderMenuToggleTriggers(HeaderMenus.LanguageSection);

const equalsLanguageCode = (firstLangConde = '', secondLangCode = '') =>
  firstLangConde.toLowerCase() === secondLangCode.toLowerCase();

@customElement('gntc-language-list')
export class LanguageListBase extends LitElement {
  static styles = unsafeCSS(useStyles.toString());

  @property({ type: Boolean, reflect: true })
  protected isExternal = false;

  @query(`.language-container`)
  languageContainerElementRef?: HTMLElement;

  @query(`.language-list`)
  languageListElement?: HTMLElement;

  @query(`.language-selector`)
  languageSelectorElement?: HTMLElement;

  @property({ type: Array, reflect: true })
  protected languageList?: Language[];

  @property({ type: String, reflect: true })
  protected selectedLanguageCode = '';

  @property({ type: String, reflect: false })
  protected cmsbaseurl = '';

  @property({ attribute: false, reflect: false })
  protected onLanguageChange: (lang: string) => null = () => null;

  @property({ type: String, reflect: false })
  datalayerVariant?: string = '';

  @property({ type: String, reflect: true })
  protected siteLanguage? = '';

  @property({ reflect: true })
  protected alternativeLanguageCodes?: Record<string, string> = {};

  @state()
  isExpanded = false;

  protected setButtonAriaAttributes(button: any, expanded: boolean) {
    button.setAttribute('aria-expanded', expanded);
  }

  protected addLanguageNavigationListeners() {
    document.addEventListener('keydown', this.languageHandlers.onContainerKeyDown);
  }

  protected removeLanguageNavigationListeners() {
    document.removeEventListener('keydown', this.languageHandlers.onContainerKeyDown);
  }

  protected languageHandlers = {
    onButtonClick: this.handleButtonLanguageClick.bind(this),
    onContainerKeyDown: this.handleLanguageContainerKeyDown.bind(this),
    onContainerClick: this.handlerLanguageContainerClick.bind(this),
    onDocumentClick: this.handleLanguageDocumentClick.bind(this),
  };

  protected handlerLanguageContainerClick(event: MouseEvent) {
    event.stopPropagation();
  }

  protected handleButtonLanguageClick(event: MouseEvent) {
    event.stopPropagation();
    this.toggleLanguageList();
  }

  protected handleLanguageContainerKeyDown(event: KeyboardEvent) {
    const isTab = /^tab$/i.test(event.key);
    const isEscape = /^esc/i.test(event.key);
    const isArrowUp = /^(arrow)?up$/i.test(event.key);
    const isArrowDown = /^(arrow)?down$/i.test(event.key);

    if (isArrowUp || isArrowDown) {
      event.preventDefault();
    }

    const children = Array.prototype.slice.call(
      this.languageListElement?.children,
    ) as HTMLElement[];
    const activeElement = this.shadowRoot?.activeElement as HTMLElement;
    const isChildrenActive =
      activeElement?.parentElement && children.includes(activeElement.parentElement);

    if (isArrowUp) {
      this.focusLanguageItem(activeElement, 'previous');
    }

    if (isArrowDown) {
      this.focusLanguageItem(activeElement, 'next');
    }

    if (isTab && isChildrenActive) {
      this.closeLanguageList();
    }

    if (isEscape) {
      this.closeLanguageList();
    }
  }

  protected handleLanguageDocumentClick() {
    setTimeout(() => this.closeLanguageList());
  }

  protected focusLanguageItem(activeElement: HTMLElement, direction: 'next' | 'previous') {
    if (!this.languageListElement) return;

    const totalElements = this.languageListElement.children.length;

    if (!totalElements) return;

    const children = Array.prototype.slice.call(this.languageListElement.children) as HTMLElement[];
    const directionIndex = /next/i.test(direction) ? 0 : totalElements - 1;

    if (activeElement === this.languageSelectorElement) {
      children[directionIndex].querySelector('a')?.focus();
      return;
    }

    const currentLanguageItem =
      activeElement.parentElement &&
      children.indexOf(activeElement.parentElement) != -1 &&
      activeElement.parentElement;

    if (!currentLanguageItem) {
      return;
    }

    const sibling = currentLanguageItem[
      `${direction}ElementSibling` as 'previousElementSibling' | 'nextElementSibling'
    ] as HTMLElement;

    activeElement.tabIndex = -1;

    if (!sibling) {
      children[directionIndex].querySelector('a')?.focus();
      children[directionIndex].querySelector('a')?.setAttribute('tabindex', '0');
      return;
    }

    sibling.querySelector('a')?.focus();
    sibling.querySelector('a')?.setAttribute('tabindex', '0');
  }

  protected toggleLanguageList() {
    this.isExpanded = !this.isExpanded;
    if (this.languageContainerElementRef) {
      animate(this.languageContainerElementRef, this.isExpanded, 'is-expanded');
    }
    this.setButtonAriaAttributes(this.languageSelectorElement, Boolean(this.isExpanded));

    const children = Array.prototype.slice.call(
      this.languageListElement?.children,
    ) as HTMLElement[];
    children.forEach((item) => {
      const link = item.querySelector('a');
      const isSelected = link && item.getAttribute('aria-selected') === 'true';

      link?.setAttribute('tabindex', isSelected ? '0' : '-1');
    });

    emitLanguageOptionToggle(Boolean(this.isExpanded));

    if (this.isExpanded) {
      this.addLanguageNavigationListeners();
    } else {
      this.removeLanguageNavigationListeners();
    }

    emitCustomEvent<any>({
      context: this,
      eventName: 'onToggleMenu',
      event: {
        detail: { isExpanded: this.isExpanded },
      },
    });
  }

  public closeLanguageList() {
    this.isExpanded = false;
    if (this.languageContainerElementRef) {
      animate(this.languageContainerElementRef, this.isExpanded, 'is-expanded');
    }
    this.setButtonAriaAttributes(this.languageSelectorElement, false);
  }

  protected updateCurrentLanguage() {
    const selectedLanguageEl = this.languageListElement?.querySelector('[aria-selected="true"] a');
    const language = selectedLanguageEl?.getAttribute('data-datalayer-content') || 'en';

    this.onLanguageChange(language);
  }

  protected updated(_changedProperties: PropertyValues) {
    super.update(_changedProperties);

    if (_changedProperties.has('languageList')) {
      if (this.languageList) {
        document.addEventListener('click', this.languageHandlers.onDocumentClick);
        this.languageContainerElementRef?.addEventListener(
          'click',
          this.languageHandlers.onContainerClick,
        );
        this.updateCurrentLanguage();
      } else {
        document.removeEventListener('click', this.languageHandlers.onDocumentClick);
        this.languageContainerElementRef?.removeEventListener(
          'click',
          this.languageHandlers.onContainerClick,
        );
      }
    }
  }

  protected getLanguageContainer() {
    const { languageContainer: languageContainerClass, languageTitle: languageTitleClass } =
      useStyles.classes;
    const languageContainerClasses = classMap({
      [languageContainerClass]: true,
      'language-container': true,
    });
    const languageTitleClasses = classMap({
      [languageTitleClass]: true,
    });

    return html`
      <div class="${languageContainerClasses}">
        <h4 class="${languageTitleClasses}">Language</h4>
        ${this.getLanguageList()}
      </div>
    `;
  }

  protected getLanguageList() {
    const { languageList: languageListClass } = useStyles.classes;
    const languageListClasses = classMap({
      [languageListClass]: true,
      'language-list': true,
    });

    return html`
      <ul role="menu" class="${languageListClasses}" aria-controls="lang">
        ${this.languageList?.map((language) => this.getLanguageItemLink({ link: language }))}
      </ul>
    `;
  }

  protected getLanguageItemLink({ link }: { link: Language }) {
    const { initial: langItemLanguageKey, displayText, url } = link;
    const { languageItemLink: languageItemLinkClass } = useStyles.classes;
    const isSelected = equalsLanguageCode(langItemLanguageKey, this.siteLanguage);
    const languageItemLinkClasses = classMap({
      [languageItemLinkClass]: true,
      selected: isSelected,
    });
    const handleGTM = (e: MouseEvent) => {
      const target = e.target as HTMLAnchorElement;
      const newEvent = new CustomEvent('gtm:click:link', {
        bubbles: true,
        composed: true,
        detail: { target },
      });

      this.dispatchEvent(newEvent);
    };

    return html`<li
      role="menuitem"
      aria-controls="lang"
      aria-selected="${isSelected ? 'true' : 'false'}"
      tabindex="-1"
    >
      <a
        data-datalayer-scenario="language"
        data-datalayer-content="${langItemLanguageKey?.toLowerCase()}"
        data-datalayer-variant="${this.datalayerVariant}"
        data-datalayer-ignore-cta
        tabindex="${isSelected ? '0' : '-1'}"
        class="${languageItemLinkClasses}"
        href="${url}"
        @click=${(e: MouseEvent) => handleGTM && handleGTM(e)}
      >
        <span>${displayText}</span>
      </a>
    </li> `;
  }

  protected getRoot() {
    const {
      selectorButton: selectorButtonClass,
      languageButtonContainer: languageButtonContainerClass,
    } = useStyles.classes;
    const buttonMenuClasses = classMap({
      'language-selector': true,
      [selectorButtonClass]: true,
    });

    const selectedLanguageCode = getOr(
      this.selectedLanguageCode,
      this.selectedLanguageCode,
      this.alternativeLanguageCodes,
    );
    return html`
      <div class="${languageButtonContainerClass}">
        <button
          @click="${this.languageHandlers.onButtonClick}"
          class="${buttonMenuClasses}"
          tabindex="0"
          aria-expanded="false"
          aria-haspopup="true"
        >
          <span>${selectedLanguageCode}</span>
          ${arrowSvg}
        </button>
        ${this.getLanguageContainer()}
      </div>
    `;
  }

  protected firstUpdated(_changedProperties: PropertyValues) {
    addListenerOnOtherHeaderOptionToggled(({ toggleValue }) => {
      if (toggleValue) {
        this.closeLanguageList();
      }
    });

    document.addEventListener('click', () =>
      this.languageSelectorElement?.classList.remove('a11y'),
    );
    document.addEventListener('keydown', (e: KeyboardEvent) => {
      const isTab = /^tab$/i.test(e.key);

      if (isTab) {
        this.languageSelectorElement?.classList.add('a11y');
      }
    });

    if (this.shadowRoot) {
      captureClicks({
        context: this.shadowRoot,
        isExternal: this.isExternal,
      });
    }

    return super.firstUpdated(_changedProperties);
  }

  protected render() {
    return html`${this.getRoot()}`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'gntc-language-list': LanguageListBase;
  }
}
