import { makeStyles2 } from '../../../../core';
import { Styles } from 'jss';
import { dynamicArrow } from '../../../../shared/icons/arrow';
import { COLORS } from '../../../../shared/theme/palette';

const useAppServicesStyles = ({
  responsive,
  mobileBreakpoint,
  responsiveMaxWidth,
  spacing,
  typography,
  secondaryNavLinkStyles,
  palette,
  components,
}: any): Styles => ({
  secNavMenuGroupSectionItems: {
    listStyle: 'none',
    paddingLeft: 0,
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignContent: 'flex-start',

    '& li': {
      '&:not(:first-child)': {
        marginTop: '4px',
      },

      '&.standard + .highlighted': {
        [responsive(mobileBreakpoint)]: {
          marginTop: '13px',
        },
      },
    },
  },
  secNavMenuGroupSectionTitle: {
    '&:not(.is-smallCaps)': {
      [responsive(mobileBreakpoint)]: {
        paddingBottom: 0,
      },

      [responsiveMaxWidth(mobileBreakpoint)]: {
        marginBottom: '12px',
      },
    },
    [responsive(mobileBreakpoint)]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      paddingBottom: spacing(1),
    },
  },
  secNavMenuGroupSectionTitleLink: {
    ...secondaryNavLinkStyles,
    '&.standard': {
      ...typography.headline7,
      marginBottom: spacing(1),
      marginTop: '-5px',
      fontWeight: 'bold',
      [responsiveMaxWidth(mobileBreakpoint)]: {
        marginTop: '0',
        fontSize: '18px',
        marginBottom: '15px',
      },
    },

    '&.smallCaps': {
      ...typography.overline,
      textTransform: 'uppercase',
      marginTop: '7px',
      minHeight: 'auto',
      paddingTop: '10.5px',
      paddingBottom: '10.5px',
      [responsive(mobileBreakpoint)]: {
        marginTop: '-3px',
        marginBottom: '12px',
      },
    },
  },
  secNavMenuItemDescription: {
    ...typography.body1,
    color: palette.primary.text,
    fontSize: '14px',
    lineHeight: '18px',
    opacity: 0.8,
    marginBottom: spacing(1.5),

    '& *': {
      margin: 0,
    },
  },
  secNavMenuGroupList: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: spacing(3),
    '&:last-child': {
      marginBottom: 0,
    },
    [responsive(mobileBreakpoint)]: {
      flexDirection: 'column',
      flexWrap: 'wrap',
      position: 'relative',
      flexGrow: '1',
      '&.promo-column': {
        maxWidth: '384px',
      },
    },
  },
  secondaryNavStandardLink: {
    ...secondaryNavLinkStyles,
    ...typography.subtitle1,
    justifyContent: 'center',

    '&.standard': {
      fontSize: '14px',
      height: 'auto',
      lineHeight: 1.73,
    },
    '&.smallCaps': {
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: 1.73,
    },
  },
  secondaryNavHighlightLink: {
    ...secondaryNavLinkStyles,
    ...typography.subtitle2,
    fontSize: '14px',
    color: palette.secondary.color + ' !important',

    '& span:after': {
      backgroundImage: `url(${dynamicArrow(COLORS.blue1)})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      content: '""',
      color: palette.secondary.color,
      display: 'inline-block',
      height: '7px',
      lineHeight: '100%',
      paddingLeft: spacing(1),
      transform: 'rotate(-90deg)',
      verticalAlign: 'middle',
      width: '10px',
    },

    '&.standard': {
      lineHeight: 1.73,
    },

    '&.smallCaps': {
      lineHeight: 1.73,
    },
  },
  secondaryNavButtonLink: {
    ...components.button.small.outlined,
    [responsive(mobileBreakpoint)]: {
      ...components.button.small.outlined[responsive(mobileBreakpoint)],
      margin: spacing(0, 1.75, 0.5, 0),
    },
  },
  appServicesWrapper: {
    padding: spacing(0, 3),
    [responsiveMaxWidth(mobileBreakpoint)]: {
      padding: 0,
    },
  },
});

export default makeStyles2(useAppServicesStyles);
