import { LitElement, PropertyValues, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { captureClicks } from '../../../core/utils';
import { ContentMenuItem } from '../../gntc-global-header.interface';
import useAppsAppItemStyles from './gntc-apps-app-item.styles';

@customElement('gntc-apps-app-item')
export class GntcAppsAppItem extends LitElement {
  static styles = useAppsAppItemStyles().css;

  @property({ type: Object, reflect: true })
  protected appItem: ContentMenuItem | null = null;

  protected firstUpdated(_changedProperties: PropertyValues) {
    super.firstUpdated(_changedProperties);

    Promise.resolve().then(() => {
      if (this.shadowRoot) {
        captureClicks({ context: this.shadowRoot });
      }
    });
  }

  protected render(): unknown {
    const { classes } = useAppsAppItemStyles();
    return html`
      <a
        href="${this.appItem?.link?.href}"
        class="${classes.appItem}"
        target="${this.appItem?.link?.openInNewWindow ? '_blank' : '_self'}"
      >
        <img src="${this.appItem?.icon?.url}" alt="" />
        <span>${unsafeHTML(this.appItem?.icon?.text)}</span>
        <svg
          width="7"
          height="10"
          viewBox="0 0 7 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1 1L5 5L1 9" stroke="#EDF0F4" stroke-width="1.5" />
        </svg>
      </a>
    `;
  }
}
