const rte = (theme: Theme) => ({
  color: theme.palette.background[theme.mode].text,
  '& h1': {
    ...theme.typography.headline6,
    marginBottom: '10px',
    lineHeight: 'normal',
  },
  '& h2': {
    ...theme.typography.headline6,
    marginBottom: '10px',
    lineHeight: 'normal',
  },
  '& h3': {
    ...theme.typography.headline6,
    marginBottom: '10px',
    lineHeight: 'normal',
  },
  '& h4': {
    ...theme.typography.headline6,
    marginBottom: '10px',
    lineHeight: 'normal',
  },
  '& h5': {
    ...theme.typography.headline6,
    marginBottom: '10px',
    lineHeight: 'normal',
  },
  '& h6': {
    ...theme.typography.headline6,
    marginBottom: '10px',
    lineHeight: 'normal',
  },
  '& p': {
    ...theme.typography.body1,
    fontSize: '14px',
    lineHeight: 'normal',
  },
  '& small': {
    ...theme.typography.body2,
  },
  '& a': {
    ...theme.typography.button,

    '&:not(:hover), &:hover': {
      color: theme.palette.primary[theme.mode].color,
    },
  },
  '& *': {
    '&:not(:first-child)': {
      marginTop: '10px',
    },
    '&:first-child': {
      marginTop: 0,
    },
    '&:not(:last-child)': {
      marginBottom: '10px',
    },
    '&:last-child': {
      marginBottom: 0,
    },
  },
});

export default (theme: Theme) => rte(theme);
