import { makeStyles2 } from '../../core';
import { Styles } from 'jss';

const useVerticalDividerStyles = ({ palette, spacing }: any): Styles => ({
  secNavMenuGroupListVerticalDivider: {
    flexGrow: 1,
    width: 'auto !important',
    padding: spacing(0, 1),
    height: '100%',
    '& div.verticalDivider': {
      margin: '0 36%',
      height: '100%',
      width: '1px',
      background: palette.background.dark.text,
      opacity: 0.38,
    },
  },
});

export default makeStyles2(useVerticalDividerStyles);
