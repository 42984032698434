import { svg } from 'lit';

export default svg`
  <svg width="10px" height="7px" viewBox="0 0 10 7" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <polygon id="Path" fill="#F1F0F0" fill-rule="nonzero" points="8.26 0.14 9.5 1.4 5.05 6.14 0.5 1.51 1.71 0.21 5.02 3.58"></polygon>
  </svg>
`;

export const dynamicArrow = (color: string) =>
  'data:image/svg+xml;base64,' +
  window.btoa(`<svg width="10px" height="7px" viewBox="0 0 10 7" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<polygon id="Path" fill="${color}" fill-rule="nonzero" points="8.26 0.14 9.5 1.4 5.05 6.14 0.5 1.51 1.71 0.21 5.02 3.58"></polygon>
</svg>`);
