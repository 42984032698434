import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import './components/gntc-avatar-image';
import './components/gntc-avatar-initials';
import gntcAvatarStyles from './gntc-avatar.styles';

@customElement('gntc-avatar')
export class GntcAvatar extends LitElement {
  static styles = gntcAvatarStyles().css;

  @property({ type: String })
  sessionName = '';

  @property({ type: 1 | 2 })
  initialLength = 1;

  @property({ type: String })
  size = 'base';

  @property({ type: String })
  src = '';

  render() {
    return !this.src
      ? html`
          <gntc-avatar-initials
            .sessionName="${this.sessionName}"
            .initialLength="${this.initialLength}"
            .size="${this.size}"
          ></gntc-avatar-initials>
        `
      : html` <gntc-avatar-image .src="${this.src}" .size="${this.size}"></gntc-avatar-image> `;
  }
}
