import { LitElement, PropertyValues, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { captureClicks } from '../../../../core/utils';
import usePartnerPortalLoginStyles from './gntc-partner-portal-login.styles';

@customElement('gntc-partner-portal-login')
export class GntcPartnerPortalLogin extends LitElement {
  static styles = usePartnerPortalLoginStyles().css;

  @property({ type: String, reflect: true })
  loginSectionTitle = '';

  @property({ type: String, reflect: true })
  loginButtonLabel = '';

  @property({ type: String, reflect: true })
  notAccountLabel = '';

  @property({ type: String, reflect: true })
  registerActionLabel = '';

  @property({ type: String, reflect: true })
  registrationUrl = '';

  protected firstUpdated(_changedProperties: PropertyValues) {
    super.firstUpdated(_changedProperties);

    Promise.resolve().then(() => {
      if (this.shadowRoot) {
        captureClicks({ context: this.shadowRoot });
      }
    });
  }

  protected onLoginClicked(e: Event) {
    e.preventDefault();
    this.dispatchEvent(new CustomEvent('onLoginClick'));
  }

  protected render(): unknown {
    const classes = usePartnerPortalLoginStyles().classes;
    return html`
      <div class="${classes.loginWrapper}">
        <h3 class="${classes.title}">${this.loginSectionTitle}</h3>
        <div class="${classes.loginButtonWrapper}">
          <button @click="${(e: Event) => this.onLoginClicked(e)}" class="${classes.loginButton}">
            ${this.loginButtonLabel}
          </button>
        </div>
        <p>
          ${this.notAccountLabel}
          <a href="${this.registrationUrl}" target="_blank" class="${classes.registerLink}"
            >${this.registerActionLabel}</a
          >
        </p>
      </div>
    `;
  }
}
