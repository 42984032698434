import { html, LitElement } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { styleMap } from 'lit/directives/style-map.js';
import { animate } from '../../shared/animate';
import useMenuDropdownContentStyles from './gntc-menu-dropdown-content.styles';

const HEADER_TOP_DESKTOP = 47;
const HEADER_TOP_MOBILE = 47;
const CONTENT_SPACE_BOTTOM = 80;
const ARROW_WIDTH = 24;

@customElement('gntc-menu-dropdown-content')
export class GntcMenuDropdownContent extends LitElement {
  static styles = useMenuDropdownContentStyles().css;

  @property({ type: Boolean, reflect: true })
  protected isMobile = false;

  @property({ type: Boolean, reflect: true })
  set isExpanded(val) {
    // waiting for all the process
    setTimeout(() => {
      this._isExpanded = val;
      this.requestUpdate('_isExpanded', val);
      this.getLeftDropdownContentDistance();
      if (this?.menuDropdownContent) {
        animate(this.menuDropdownContent, val);
      }
      if (this.isMobile) this.getViewportTopDistance();
    });
  }
  get isExpanded() {
    return this._isExpanded;
  }

  @property({ type: Boolean, reflect: false })
  protected dropdownArrow = false;

  @property({ type: String, reflect: false })
  protected widthContent = 'auto';

  @property({ type: String, reflect: true })
  protected desktopWidth = 'auto';

  @property({ type: Number, reflect: true })
  protected headerTopDistance = 0;

  @property({ type: Number, reflect: true })
  protected desktopRightDistance = 0;

  @property({ type: Boolean, reflect: true })
  protected hasPadding = true;

  @property({ type: Node, reflect: true })
  protected dropdownIconRef: HTMLElement | null = null;

  @property({ type: Object, reflect: true })
  protected contentDesktopStyles = {};

  @query('#menu-dropdown-content')
  menuDropdownContent?: HTMLElement;

  @state()
  windowWidth = 0;

  @state()
  mobileContentHeight = 'auto';

  @state()
  dropdownContentLeft = '';

  @state()
  _isExpanded = false;

  protected getWindowWidth() {
    this.windowWidth = window.innerWidth;
  }

  protected getViewportTopDistance() {
    const { top = 0 } = this.menuDropdownContent?.getBoundingClientRect() || {};
    this.mobileContentHeight = `calc(100vh -  ${top + CONTENT_SPACE_BOTTOM}px)`;
  }

  protected getLeftDropdownContentDistance() {
    const { left = 0 }: any = this.dropdownIconRef?.getBoundingClientRect() || {};
    this.dropdownContentLeft = `${left * -1}px`;
  }

  connectedCallback() {
    super.connectedCallback();
    this.getWindowWidth();
    window.addEventListener('resize', () => this.getWindowWidth());
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('resize', () => this.getWindowWidth());
  }

  render() {
    const { classes } = useMenuDropdownContentStyles();
    const dropdownContentClasses = classMap({
      'menu-dropdown-content': true,
      [classes.dropdownContent]: true,
      [classes.dropdownContentPadding]: this.hasPadding,
    });

    const getTopDistance = (): string => {
      let baseDistance = this.isMobile ? HEADER_TOP_MOBILE : HEADER_TOP_DESKTOP;
      if (this.dropdownArrow && !this.isMobile) {
        baseDistance = baseDistance + ARROW_WIDTH;
      }
      return `${baseDistance}px`;
    };

    const getArrowPosition = (): any => {
      return {
        right: `${Math.abs(this.desktopRightDistance) + 14}px`,
      };
    };

    const desktopStyles = {
      width: this.widthContent,
      marginRight: `${this.desktopRightDistance}px`,
    };

    const mobileStyles = {
      width: `${this.windowWidth}px`,
      left: this.dropdownContentLeft,
    };

    const contentStyles = this.isMobile ? mobileStyles : desktopStyles;

    return html`
      <div
        id="menu-dropdown-content"
        class="${dropdownContentClasses}"
        style="${styleMap({
          top: getTopDistance(),
          height: this.mobileContentHeight,
          ...(contentStyles as any),
        })}"
      >
        ${this.dropdownArrow
          ? html`<div
              class="${classes.dropdownArrow}"
              style="${styleMap(getArrowPosition())}"
            ></div>`
          : null}

        <div
          style="${styleMap({
            ...(!this.isMobile ? this.contentDesktopStyles : {}),
          })}"
        >
          <slot></slot>
        </div>
      </div>
    `;
  }
}
