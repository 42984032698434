import { html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
import { makeStyles2 } from '../../core';

const useCloseIconStyles = makeStyles2(() => ({
  root: {
    width: '23px',
    height: '23px',
    display: 'block',
    '& span': {
      left: '4px',
      width: '28px',
      height: '1px',
      margin: '4px 0px',
      display: 'block',
      opacity: '1',
      position: 'absolute',
      background: '#ffffff',
      transition: 'all 0.2s ease-in-out 0s',
      borderRadius: '9px',
      transformOrigin: 'left center',
    },
    '& > span:nth-child(1)': {
      transform: 'rotate(45deg)',
      marginTop: '0px',
    },
    '& > span:nth-child(2)': {
      transform: 'rotate(-45deg)',
      marginTop: '20px',
    },
  },
}));

@customElement('gntc-close-icon')
export class GntcCloseIcon extends LitElement {
  static styles = useCloseIconStyles().css;
  protected render(): unknown {
    const { classes } = useCloseIconStyles();

    return html`
      <a
        href=""
        class="${classes.root}"
        @click="${(event: any) => {
          event.preventDefault();
          this.dispatchEvent(new CustomEvent('closeClick'));
        }}"
      >
        <span></span>
        <span></span>
      </a>
    `;
  }
}
