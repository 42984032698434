import { html, LitElement, PropertyValues, unsafeCSS } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { emitCustomEvent } from '../../core/utils';
import useStyles from './gntc-search-navigation.styles';

@customElement('gntc-search-input')
export class SearchInputBase extends LitElement {
  static styles = unsafeCSS(useStyles.toString());

  @query('.search-input')
  rootElement?: HTMLElement;

  @property({ type: String, reflect: false })
  protected icon = '';

  @property({ type: Boolean, reflect: false })
  protected hasIcon = false;

  @property({ type: String, reflect: false })
  protected suggestion = '';

  @property({ type: Boolean, reflect: false })
  protected isCollapsed = false;

  @property({ type: String, reflect: false })
  protected value = '';

  @property({ attribute: false, reflect: false })
  protected onFocus: (event?: any) => null = () => null;

  @property({ attribute: false, reflect: false })
  protected onInputChange: (query?: string) => null = () => null;

  @property({ attribute: false, reflect: false })
  protected onEnable = () => null;

  @property({ attribute: false, reflect: false })
  protected onDisable = () => null;

  @property({ type: String, reflect: true })
  protected searchArialLabel = '';

  @property({ type: String, reflect: true })
  protected searchInputArialLabel = '';

  @state()
  protected isEnabled = false;

  protected handleHeaderSearchButtonClick(e: any) {
    const searchButtonEvent = new CustomEvent('searchButtonClick', e);
    this.isEnabled = !this.isEnabled;
    this.dispatchEvent(searchButtonEvent);
  }

  private get inputEl(): HTMLInputElement {
    return this.shadowRoot!.getElementById('search-input')! as HTMLInputElement;
  }

  private get inputSuggestionEl(): HTMLInputElement {
    return this.shadowRoot!.getElementById('search-input-suggestion')! as HTMLInputElement;
  }

  private focusElement() {
    this.inputEl.focus();
  }

  private handleFocus() {
    this.onEnable();
    this.onFocus();
    this.isEnabled = true;
  }

  private handleOnBlur() {
    if (this.value.length < 1) {
      this.isEnabled = false;
      this.onDisable();
    }
  }

  private handleOnInput(ev: Event) {
    this.onInputChange((ev.target as HTMLInputElement).value);
  }

  handleOnKeyDown(event: KeyboardEvent) {
    const keyboardEvent = new KeyboardEvent('onKeyDown', event);
    this.dispatchEvent(keyboardEvent);
  }

  handleOnSubmit(event: MouseEvent) {
    event.preventDefault();
    emitCustomEvent<MouseEvent>({ context: this, eventName: 'onSubmit', event });
  }

  protected getIconButton(label = '') {
    const { iconButton: iconButtonClass } = useStyles.classes;
    const iconButtonClasses = classMap({
      [iconButtonClass]: true,
    });

    return html`<button
      class="${iconButtonClasses}"
      aria-label="${label}"
      tabindex="-1"
      type="button"
      @click="${(e: any) => this.handleHeaderSearchButtonClick(e)}"
    >
      <img src="${this.icon}" alt="" />
    </button>`;
  }

  protected shouldUpdate(_changedProperties: PropertyValues) {
    if (_changedProperties.has('value') && this.inputEl) {
      this.inputEl.value = this.value;
    }

    if (_changedProperties.has('suggestion') && this.inputSuggestionEl) {
      this.inputSuggestionEl.value = this.suggestion;
    }

    return super.shouldUpdate(_changedProperties);
  }

  protected firstUpdated(_changedProperties: PropertyValues) {
    this.inputEl.value = this.value;

    document.addEventListener('click', () => this.rootElement?.classList.remove('a11y'));
    document.addEventListener('keydown', (e: KeyboardEvent) => {
      const isTab = /^tab$/i.test(e.key);

      if (isTab) {
        this.rootElement?.classList.add('a11y');
      }
    });

    if (this.inputSuggestionEl) {
      this.inputSuggestionEl.value = this.suggestion;
    }

    return super.firstUpdated(_changedProperties);
  }

  protected render() {
    const { input, inputSuggestion, inputIcon, inputWrapper, submitButton } = useStyles.classes;

    const wrapperClasses = classMap({
      'search-input': true,
      [inputWrapper]: true,
    });

    const inputClasses = classMap({
      [input]: true,
      'is-collapsed': this.isCollapsed,
      'has-icon': !!this.icon,
    });

    const inputSuggestionClasses = classMap({
      [inputSuggestion]: true,
      'is-collapsed': this.isCollapsed,
      'has-icon': !!this.icon,
    });

    const inputIconClasses = classMap({
      [inputIcon]: true,
    });

    const submitButtonClasses = classMap({
      [submitButton]: true,
    });

    return html`<form class="${wrapperClasses}" @submit="${this.handleOnSubmit}">
      ${this.icon
        ? html`<div class="${inputIconClasses}" @click="${this.focusElement}">
            ${this.getIconButton(this.searchArialLabel)}
          </div>`
        : html``}

      <input
        id="search-input-suggestion"
        class="${inputSuggestionClasses}"
        autocomplete="off"
        tabindex="-1"
      />
      <input
        class="${inputClasses}"
        id="search-input"
        arial-label="${this.searchInputArialLabel}"
        @keydown="${this.handleOnKeyDown}"
        @focus="${this.handleFocus}"
        @blur="${this.handleOnBlur}"
        @input="${this.handleOnInput}"
        tabindex="0"
        autocomplete="off"
      />
      <button
        arial-label="${this.searchInputArialLabel}"
        type="submit"
        class="${submitButtonClasses}"
      >
        ${this.searchInputArialLabel}}
      </button>
      <slot></slot>
    </form>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'gntc-search-input': SearchInputBase;
  }
}
