import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import useMenuDropdownIconStyles from './gntc-menu-dropdown-icon.styles';

@customElement('gntc-menu-dropdown-icon')
export class GntcMenuDropdownIcon extends LitElement {
  static styles = useMenuDropdownIconStyles().css;

  @property({ type: String })
  label = '';

  @property({ type: String })
  className = '';

  @property({ type: Boolean, reflect: true })
  isActive = false;

  @property({ type: String })
  iconUrl = '';

  protected render(): unknown {
    const { classes } = useMenuDropdownIconStyles();

    return html`
      <button class="${classes.iconButton}" aria-label="${this.label}" tabindex="0">
        <slot></slot>
      </button>
    `;
  }
}
