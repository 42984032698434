import { makeStyles } from '../../core';
import { opacityColor } from '../../core/utils';
import { createTheme } from '../../shared';
import spacing from '../../shared/theme/spacing';
import { getStyles } from '../gntc-global-header.styles';

const { typography, palette, responsive, responsiveMaxWidth }: any = createTheme(
  'globalHeader',
  'md',
  'dark',
);
const mapStyles = getStyles('dark');

const rootSyles = {
  ...typography.body1,
  backgroundColor: palette.primary.dark.color,
  color: palette.primary.dark.text,
  fontSmoothing: 'antialiased',
};

const searchNavigationContent = {
  ...mapStyles.secNav,

  '&.is-open': {
    display: 'block',
  },
};

const secNavContainerStyles = {
  ...mapStyles.secNavContainer,

  [responsiveMaxWidth('md')]: {
    padding: spacing(2, 0),
    '&.no-results': {
      display: 'none',
    },
  },
};
const secNavMenuGroupStyles = {
  ...mapStyles.secNavMenuGroup,
  maxWidth: '100%',

  [responsiveMaxWidth('md')]: {
    flexDirection: 'column-reverse',
  },
};

const navigationSearchStyles = {
  borderRadius: '1.25em',
  transition: 'width 250ms ease-in-out, width 250ms ease-in-out',
  width: '100%',

  '&:not(.a11y) *:focus': {
    outline: '0 !important',
  },

  [responsive('md')]: {
    width: '36px',

    '&.is-open': {
      marginRight: '8px',
      width: '175px',
    },
  },
};

const searchButtonStyles = {
  position: 'relative',
  zIndex: 2,
};

const resultsStyles = {
  display: 'flex',
};

const resultsSidebarStyles = {
  flex: '1',

  '& em': {
    color: palette.primary.dark.text,
    fontStyle: 'normal',
    fontWeight: 'bold',
  },

  [responsive('md')]: {
    flex: '0 0 25.6%',
  },

  [responsive('lg')]: {
    borderRight: `1px solid ${palette.primary.dark.color}`,
  },
};

const resultsSidebarItemStyles = {
  [responsiveMaxWidth('md')]: {
    display: 'none',
  },

  color: opacityColor('dark', palette.primary.dark.text, 1),
  display: 'inline-block',
  marginBottom: spacing(2.75),
  padding: spacing(0, 1.75),
  textDecoration: 'none',
  width: '100%',
  maxWidth: '100%',
  boxSizing: 'border-box',

  '&:hover': {
    '& .title, & .title *': {
      color: palette.secondary.color,
    },
  },

  '& .title': {
    ...typography.body1,
  },

  '& .category': {
    ...typography.body2,
  },
};

const resultsSidebarHightlightLinkStyles = {
  ...mapStyles.secondaryNavHighlightLink,
  marginLeft: 0,
};

const resultsContentStyles = {
  flex: 1,
  marginBottom: spacing(1),

  [responsive('lg')]: {
    borderLeft: `1px solid ${palette.primary.light.color}`,
    paddingLeft: spacing(6),
    marginBottom: spacing(0),
  },
};

const resultsContentFormStyles = {
  marginBottom: spacing(4.5),

  [responsiveMaxWidth('md')]: {
    display: 'none',
  },
};

const resultsContentInputStyles = {
  position: 'relative',
};

const resultsContentInputLinkStyles = {
  ...mapStyles.secondaryNavStandardLink,
  color: palette.secondary.color + ' !important',
  position: 'absolute',
  borderRadius: '18px',
  right: 0,
  top: '50%',
  height: '34px',
  transform: 'translateY(-50%)',
  zIndex: 10,
  padding: spacing(0, 1.75),
  margin: spacing(0),

  '&:before': {
    ...mapStyles.secondaryNavStandardLink['&:before'],
    borderRadius: '0 18px 18px 0',
  },
};

const resultsContentItemStyles = {
  color: opacityColor('dark', palette.primary.dark.text, 1),
  display: 'grid',
  gridTemplateColumns: '30px 1fr',
  gridTemplateRows: '1fr',
  gap: '0 20px',
  gridTemplateAreas: `"Image Content"`,
  marginBottom: '20px',
  textDecoration: 'none',

  '&:hover': {
    '& .title': {
      color: palette.secondary.color,
    },
  },

  '&:last-child': {
    marginBottom: 0,
  },

  '& .icon': {
    ...typography.headline5,
    gridArea: 'Image',
    padding: '2px 0',
    overflow: 'hidden',
  },

  '& img': {
    maxWidth: '100%',
  },

  '& .title': {
    ...typography.headline6,
    color: palette.primary.dark.text,
    gridArea: 'Title',
    fontWeight: 'normal',

    '& em': {
      fontStyle: 'normal',
      fontWeight: 'bold',
    },

    [responsiveMaxWidth('md')]: {
      fontSize: '16px',
    },
  },

  '& .description': {
    ...typography.body1,
    gridArea: 'Description',

    '& em': {
      color: palette.primary.dark.text,
      fontStyle: 'normal',
      fontWeight: 'bold',
    },

    [responsiveMaxWidth('md')]: {
      fontSize: '14px',
    },
  },
};

const inputWrapperStyles = {
  borderRadius: '1.25em',
  position: 'relative',

  '&:not(.a11y) *:focus': {
    outline: '0 !important',
  },
};

const inputStyles = {
  ...typography.body1,
  background: 'transparent',
  borderWidth: `1px`,
  borderStyle: 'solid',
  borderColor: palette.primary.dark.text,
  borderRadius: '1.25em',
  boxSizing: 'border-box',
  color: palette.primary.dark.text,
  height: '36px',
  lineHeight: '36px',
  padding: '0 0.75em',
  position: 'relative',
  width: '100%',
  zIndex: 3,
  fontSize: '15px',

  [responsive('md')]: {
    '&.is-collapsed': {
      borderColor: 'transparent',
      cursor: 'pointer',
      padding: 0,
    },
  },

  '&.has-icon': {
    paddingLeft: '36px',
  },
};

const inputHeaderStyles = {
  [responsiveMaxWidth('md')]: {
    marginRight: spacing(1),
    flex: 1,
  },
};

const inputSuggestionStyles = {
  ...inputStyles,
  position: 'absolute',
  top: 0,
  left: 0,
  opacity: 0.5,
  zIndex: 1,
};

const inputIconStyles = {
  top: 0,
  left: 0,
  position: 'absolute',
  height: '36px',
  width: '36px',
  zIndex: 4,
};

const iconButtonStyles = {
  ...mapStyles.iconButton,
  borderRadius: '1.25em',
};

const skeletonStyles = {
  animation: `$skeletonCard 1500ms ease-in-out infinite`,
  borderRadius: '.25em',
  background: palette.primary.dark.text,
  height: '1em',
  marginBottom: '.5em',
  width: '100%',

  '&.is-image': {
    height: '30px',
  },
};

const skeletonKeyframeStyles = {
  '0%, 100%': {
    opacity: 0.2,
  },
  '50%': {
    opacity: 0.3,
  },
};

const headerFormStyles = {
  display: 'flex',
};

const submitButtonStyles = {
  border: 0,
  margin: 0,
  padding: 0,
  position: 'absolute',
  visibility: 'hidden',
  zIndex: -1,
};

const partnerFilterWrapper = {
  marginLeft: '50px',
  '& .partner-only-filter': {
    marginBottom: '-8px',
  },
  [responsiveMaxWidth('md')]: {
    marginLeft: 0,
  },
};

const useStyles = makeStyles({
  root: rootSyles,
  iconButton: iconButtonStyles,
  navigationSearch: navigationSearchStyles,
  searchButton: searchButtonStyles,
  headerForm: headerFormStyles,
  results: resultsStyles,
  resultsSidebar: resultsSidebarStyles,
  resultsSidebarItem: resultsSidebarItemStyles,
  resultsSidebarHightlightLink: resultsSidebarHightlightLinkStyles,
  resultsContent: resultsContentStyles,
  resultsContentForm: resultsContentFormStyles,
  resultsContentInput: resultsContentInputStyles,
  resultsContentInputLink: resultsContentInputLinkStyles,
  resultsContentItem: resultsContentItemStyles,
  input: inputStyles,
  inputHeader: inputHeaderStyles,
  inputSuggestion: inputSuggestionStyles,
  submitButton: submitButtonStyles,
  inputWrapper: inputWrapperStyles,
  inputIcon: inputIconStyles,
  searchNavigationContent,
  secNavContainer: secNavContainerStyles,
  secNavMenuGroup: secNavMenuGroupStyles,
  skeleton: skeletonStyles,
  '@keyframes skeletonCard': skeletonKeyframeStyles,
  partnerFilterWrapper,
});

export default useStyles;
