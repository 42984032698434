import { makeStyles2 } from '../../core';
import { animateStyles } from '../../shared/animate';

const DEFAULT_DROPDOWN_WIDTH = '320px';

const gntcMenuDropdownContentStyles = ({
  palette,
  spacing,
  elevation,
  responsiveMaxWidth,
  mobileBreakpoint,
}: any) => ({
  dropdownContentPadding: {
    padding: spacing(0, 3),
  },
  dropdownContent: {
    '&.is-open': {
      display: 'block',
    },
    ...animateStyles(),
    display: 'none',
    position: 'absolute',
    backgroundColor: palette.background.dark.color,
    color: palette.background.dark.text,
    right: spacing(-0.75),
    marginRight: '14px',
    width: DEFAULT_DROPDOWN_WIDTH,
    boxShadow: elevation(4),
    [responsiveMaxWidth(mobileBreakpoint)]: {
      left: 0,
      right: 0,
      padding: spacing(0, 0, 10),
      overflow: 'auto',
      zIndex: '1200',
      position: 'absolute',
      listStyle: 'none',
      height: 'calc(100vh - 160px)',
      margin: '0',
      boxShadow: 'none',
    },
  },
  dropdownArrow: {
    position: 'absolute',
    top: '-15px',
    display: 'block',
    borderBottom: `solid 15px ${palette.background.dark.color}`,
    borderLeft: 'solid 10px transparent',
    borderRight: 'solid 10px transparent',
  },
});

export default makeStyles2(gntcMenuDropdownContentStyles);
