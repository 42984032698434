import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { useInitialsColors } from '../helpers';
import gntcAvatarInitialsStyles from './gntc-avatar-initials.styles';

@customElement('gntc-avatar-initials')
export class GntcAvatarInitials extends LitElement {
  static styles = gntcAvatarInitialsStyles().css;

  @property({ type: String })
  sessionName = '';

  @property({ type: Number })
  initialLength = 1;

  @property({ type: String })
  size = 'base';

  render() {
    const { classes } = gntcAvatarInitialsStyles();
    const { initials, color } = useInitialsColors(this.sessionName, this.initialLength);

    return html`
      <div
        class="${classMap({
          [classes.avatar]: true,
          [classes.initialsAvatar]: true,
          [classes[this.size]]: true,
        })}"
        title="${this.sessionName}"
      >
        <span
          style="${`background-color:${color};`}"
          class="${classMap({
            [classes.initialsAvatarBackground]: true,
          })}"
        ></span>
        <span class="${classes.initials}">${initials}</span>
      </div>
    `;
  }
}
