import { makeStyles2 } from '../../../../core';
import { avatarStyles, sizeStyles } from '../gntc-avatar.styles';

const gntcAvatarImageStyles = () => ({
  avatatImg: {
    color: 'transparent',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    textAlign: 'center',
    textIndent: '10000px',
  },
  ...avatarStyles,
  ...sizeStyles,
});

export default makeStyles2(gntcAvatarImageStyles);
