import { makeStyles2 } from '../../../../core';
import { opacityColor } from '../../../../core/utils';

const navigationListStyles = ({ spacing, palette }: any) => ({
  gntcNavigationListSpaceBottom: {
    marginBottom: '30px',
  },
  gntcNavigationList: {},
  gntcNavigationListTitle: {
    display: 'block',
    fontWeight: 'bold',
    fontSize: '10px',
    lineHeight: '100%',
    textTransform: 'uppercase',
    padding: spacing(0, 0, 2),
    color: opacityColor('dark', palette.primary.dark.text, 2),
  },
});

export default makeStyles2(navigationListStyles);
