import { makeStyles2 } from '../../../core';

export const sizeStyles = {
  small: {
    width: '28px',
    height: '28px',
    fontSize: '14px',
  },
  base: {
    width: '40px',
    height: '40px',
    fontSize: '18px',
  },
  large: {
    width: '56px',
    height: '56px',
    fontSize: '24px',
  },
};

export const avatarStyles = {
  avatar: {
    width: '40px',
    height: '40px',
    display: 'flex',
    overflow: 'hidden',
    position: 'relative',
    fontSize: '1.25rem',
    alignItems: 'center',
    flexShrink: 0,
    lineHeight: 1,
    userSelect: 'none',
    borderRadius: '50%',
    justifyContent: 'center',
  },
};

const gntcAvatarStyles = () => ({
  avatarWrapper: {},
  ...sizeStyles,
});

export default makeStyles2(gntcAvatarStyles);
