import { Styles } from 'jss';
import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { makeStyles2 } from '../../core';

const useLogoutButtonStyles = makeStyles2(
  ({ components }: any): Styles => ({
    gntcProfileLogout: {
      ...components.button.normal.outlined,
      display: 'inline-flex',
      cursor: 'pointer',
    },
  }),
);

@customElement('gntc-logout-button')
export class GntcLogoutButton extends LitElement {
  static styles = useLogoutButtonStyles().css;

  @property({ type: String, reflect: true })
  protected logoutLabel = '';

  onLogoutClicked(e: Event): void {
    e.preventDefault();
    this.dispatchEvent(new CustomEvent('onLogoutClick'));
  }

  protected render(): unknown {
    const { classes } = useLogoutButtonStyles();
    return html`
      <a @click="${(e: Event) => this.onLogoutClicked(e)}" class="${classes.gntcProfileLogout}"
        >${this.logoutLabel}</a
      >
    `;
  }
}
