import { pipe, getOr, map, get } from 'lodash/fp';
import { SearchResultCategory } from './gntc-search-navigation.interface';

export const getSearchResultCategories = (facets = {}): SearchResultCategory[] =>
  pipe<any, any, SearchResultCategory[]>(
    getOr([], ['category', '0', 'data']),
    map(
      (facetCategory: any): SearchResultCategory => ({
        count: get('count', facetCategory),
        name: get('value', facetCategory),
      }),
    ),
  )(facets);
