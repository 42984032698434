import { makeStyles2 } from '../../../../core';
import { Styles } from 'jss';

const usePartnerPortalLoginStyles = ({
  spacing,
  palette,
  components,
  responsiveMaxWidth,
  mobileBreakpoint,
}: any): Styles => ({
  loginWrapper: {
    padding: spacing(0, 3),
    [responsiveMaxWidth(mobileBreakpoint)]: {
      padding: 0,
    },
  },
  title: {
    margin: spacing(0, 0, 2),
    lineHeight: '18px',
  },
  registerLink: {
    textDecoration: 'none',
    fontWeight: 'bold',
    color: palette.primary.text,
  },
  loginButtonWrapper: {
    margin: spacing(3, 0),
  },
  loginButton: {
    ...components.button.small.filled,
    background: 'transparent',
    cursor: 'pointer',
    padding: `${spacing(2, 6)} !important`,
    border: 'none',
  },
});

export default makeStyles2(usePartnerPortalLoginStyles);
