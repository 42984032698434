import { html } from 'lit';
import { getHref } from '../core/resolvers';
import { prop } from '../libs/custom-ramda';

export class GntcGlobalHeaderBaseOptionsMixin {
  constructor(private $globalHeaderRef: any) {}

  getUrl(path: string): string {
    return getHref({ url: path, spaBaseUrl: this.$globalHeaderRef.spaBaseUrl });
  }

  protected updateCurrentLanguage(lang: string) {
    this.$globalHeaderRef.selectedLanguageCode = lang;
  }

  getLanguageOptionComponent() {
    return this.$globalHeaderRef.languageList?.length
      ? html`<gntc-language-list
          .onLanguageChange="${(languageCode: string) => this.updateCurrentLanguage(languageCode)}"
          languageList="${JSON.stringify(this.$globalHeaderRef.languageList)}"
          cmsbaseurl="${this.$globalHeaderRef.cmsbaseurl}"
          datalayerVariant="${this.$globalHeaderRef.datalayerVariant}"
          .selectedLanguageCode="${this.$globalHeaderRef.selectedLanguageCode}"
          .isExternal="${this.$globalHeaderRef.isExternal}"
          .siteLanguage="${this.$globalHeaderRef.languageConfig?.siteLanguage}"
          .alternativeLanguageCodes="${this.$globalHeaderRef.languageConfig
            ?.alternativeLanguageCodes}"
          @onToggleMenu="${this.$globalHeaderRef.handleLanguageOptionToggle}"
        ></gntc-language-list>`
      : undefined;
  }

  getLoginOptionComponent(isLoginMobile = false) {
    return !this.$globalHeaderRef.isExternal && !this.$globalHeaderRef.campaign
      ? html`<gntc-login-section
          iconUrl="${this.getUrl(prop('account_icon', this.$globalHeaderRef.config))}"
          iconActiveUrl="${this.getUrl(prop('account_icon_active', this.$globalHeaderRef.config))}"
          label="${prop('account_icon', this.$globalHeaderRef.labels)}"
          .isLoginMobile="${isLoginMobile}"
          .loginSection="${this.$globalHeaderRef.loginSection}"
          datalayerVariant="${this.$globalHeaderRef.datalayerVariant}"
          .isExternal="${this.$globalHeaderRef.isExternal}"
          .pageLabels="${this.$globalHeaderRef.labels}"
          .pageConfig="${this.$globalHeaderRef.config}"
          .urlParser="${(url: string) => this.getUrl(url)}"
          .headerTopDistance="${this.$globalHeaderRef.headerTopDistance}"
          .accountSection="${this.$globalHeaderRef.accountSection}"
          .sessionInfo="${this.$globalHeaderRef.sessionConfig?.userInfo}"
          .showCustomAccountSlotData="${this.$globalHeaderRef._showCustomAccountSlotData}"
          .isLogged="${this.$globalHeaderRef.sessionConfig?.isUserLogged}"
          .isUserBetaParticipant="${this.$globalHeaderRef.sessionConfig?.isUserBetaParticipant}"
          @onLogoutClick="${(e: CustomEvent) =>
            this.$globalHeaderRef.dispatchEvent(new CustomEvent('on-logout-click', e))}"
          @onLoginClick="${(e: CustomEvent) =>
            this.$globalHeaderRef.dispatchEvent(new CustomEvent('on-login-click', e))}"
        >
          <div slot="gntc-login-section-unauthorized">
            <slot name="gntc-login-section-unauthorized"></slot>
          </div>
        </gntc-login-section>`
      : undefined;
  }

  getAppsOptionComponent() {
    return html`
      <gntc-apps-option
        .isMobile="${this.$globalHeaderRef.isMobile}"
        .pageLabels="${this.$globalHeaderRef.labels}"
        .pageConfig="${this.$globalHeaderRef.config}"
        .urlParser="${(url: string) => this.getUrl(url)}"
        .headerTopDistance="${this.$globalHeaderRef.headerTopDistance}"
        .appsSection="${this.$globalHeaderRef.appsSection}"
      ></gntc-apps-option>
    `;
  }

  getNotificationsOptionComponent() {
    return html`
      <gntc-notifications-option
        .isMobile="${this.$globalHeaderRef.isMobile}"
        .pageLabels="${this.$globalHeaderRef.labels}"
        .pageConfig="${this.$globalHeaderRef.config}"
        .urlParser="${(url: string) => this.getUrl(url)}"
        .headerTopDistance="${this.$globalHeaderRef.headerTopDistance}"
        .appsSection="${this.$globalHeaderRef.appsSection}"
        .hasUnreadNotifications="${this.$globalHeaderRef.notificationConfig
          ?.hasUnreadNotifications}"
      >
        <slot name="gnct-notifications-content-slot"></slot>
      </gntc-notifications-option>
    `;
  }

  getSearchNavigationOptionComponent() {
    const { search_icon: searchIconUrl } = this.$globalHeaderRef.config || {};
    const {
      search_icon: searchIconLabel,
      search_arial_label: searchArialLabel,
      search_input_arial_label: searchInputArialLabel,
    } = this.$globalHeaderRef.labels || {};

    return html`<gntc-search-navigation
      config="${JSON.stringify(this.$globalHeaderRef.config)}"
      searchToken="${this.$globalHeaderRef.searchToken}"
      searchEngine="${this.$globalHeaderRef.searchEngine}"
      searchAuthEngine="${this.$globalHeaderRef.searchAuthEngine}"
      searchBaseUrl="${this.$globalHeaderRef.searchBaseUrl}"
      searchIcon="${JSON.stringify({
        iconUrl: searchIconUrl,
        label: searchIconLabel,
      })}"
      spaBaseUrl="${this.$globalHeaderRef.spaBaseUrl}"
      cmsBaseUrl="${this.$globalHeaderRef.cmsbaseurl}"
      labels="${JSON.stringify(this.$globalHeaderRef.labels)}"
      isDesktop
      searchArialLabel="${searchArialLabel ? searchArialLabel : ''}"
      searchInputArialLabel="${searchInputArialLabel ? searchInputArialLabel : 'search input'}"
      .isExternal="${this.$globalHeaderRef.isExternal}"
      .isLogged="${this.$globalHeaderRef.sessionConfig?.isUserLogged}"
      contentTypeLabels="${this.$globalHeaderRef.contentTypeLabels}"
      datalayerVariant="${this.$globalHeaderRef.datalayerVariant}"
    ></gntc-search-navigation>`;
  }

  getMobileSearchNavigationOptionComponent() {
    const { search_icon: searchIconUrl } = this.$globalHeaderRef.config || {};
    const { search_icon: searchIconLabel } = this.$globalHeaderRef.labels || {};

    return html`<gntc-search-navigation
      config="${JSON.stringify(this.$globalHeaderRef.config)}"
      searchToken="${this.$globalHeaderRef.searchToken}"
      searchEngine="${this.$globalHeaderRef.searchEngine}"
      searchAuthEngine="${this.$globalHeaderRef.searchAuthEngine}"
      searchBaseUrl="${this.$globalHeaderRef.searchBaseUrl}"
      searchIcon="${JSON.stringify({
        iconUrl: searchIconUrl,
        label: searchIconLabel,
      })}"
      spaBaseUrl="${this.$globalHeaderRef.spaBaseUrl}"
      cmsBaseUrl="${this.$globalHeaderRef.cmsbaseurl}"
      labels="${JSON.stringify(this.$globalHeaderRef.labels)}"
      contentTypeLabels="${this.$globalHeaderRef.contentTypeLabels}"
      .isExternal="${this.$globalHeaderRef.isExternal}"
      .isLogged="${this.$globalHeaderRef.sessionConfig?.isUserLogged}"
    >
    </gntc-search-navigation>`;
  }
}
