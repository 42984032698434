import { makeStyles2 } from '../../core';

const MenuDropdownIconStyles = ({ typography }: any) => ({
  iconButton: {
    ...typography.body2,
    ontWeight: typography.fontWeightMedium,
    alignContent: 'center',
    alignItems: 'center',
    background: 'transparent',
    border: 'none',
    display: 'inline-flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 0,
    textDecoration: 'none',
    cursor: 'pointer',
    borderRadius: '4px',
    position: 'relative',
    minHeight: '36px',
    outline: 'none',

    '& span': {
      position: 'relative',
      marginBottom: '-1px',
    },

    '&:before': {
      backgroundColor: '#000000',
      borderRadius: '18px',
      content: '""',
      height: '100%',
      left: 0,
      opacity: 0,
      position: 'absolute',
      top: 0,
      transition: 'opacity 150ms ease-in-out',
      width: '100%',
    },

    '&:not(:hover), &:hover': {
      color: 'inherit',
    },

    '&:hover, &:focus': {
      '&:before': {
        opacity: 0.15,
      },
    },

    width: '36px',
    '& > img': {
      position: 'relative',
      maxWidth: '24px',
    },
    '& ::slotted(img)': {
      position: 'relative',
      maxWidth: '24px',
    },
  },
});

export default makeStyles2(MenuDropdownIconStyles);
