import { LitElement, PropertyValues, unsafeCSS, html } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { captureClicks } from '../../core/utils';
import { when } from '../../libs/custom-lit-directives/when';
import { prop, propOr } from '../../libs/custom-ramda';
import '../../shared/components/avatar/gntc-avatar';
import { generateHeaderMenuToggleTriggers, HeaderMenus } from '../gntc-global-header.events';
import { MenuGroup, MenuGroupSection, MenuItem } from '../gntc-global-header.interface';
import useStyles from '../gntc-global-header.styles';
import '../shared/gntc-logout-button';
import '../shared/gntc-menu-dropdown-content';
import '../shared/gntc-menu-dropdown-icon';
import './components/gntc-login-content';
import './components/profile/gntc-navigation-list';
import './components/profile/gntc-profile-content';
import { NavigationItem, NavigationSection } from './components/profile/interfaces';

export const SLOT_NAME_UNAUTHORIZED_SECTION = 'gntc-login-section-unauthorized';

const [emitLoginMenuToggle, addListenerOnOtherHeaderOptionToggled] =
  generateHeaderMenuToggleTriggers(HeaderMenus.LoginSection);

@customElement('gntc-login-section')
export class LoginSectionBase extends LitElement {
  static styles = unsafeCSS(useStyles.toString());

  @property({ type: Boolean, reflect: true })
  protected pageLabels = {};

  @property({ type: Boolean, reflect: true })
  protected pageConfig = {};

  @property({ type: Boolean, reflect: true })
  protected isExternal = false;

  @property({ type: String, reflect: true })
  protected iconUrl = '';

  @property({ type: String, reflect: true })
  protected iconActiveUrl = '';

  @property({ type: String, reflect: true })
  protected label = '';

  @property({ type: Array, reflect: true })
  protected loginSection?: MenuGroup[];

  @property({ type: Array, reflect: true })
  protected accountSection?: MenuGroup;

  @property({ type: Number, reflect: true })
  protected headerTopDistance = 0;

  @property({ type: Boolean, reflect: true })
  protected isLoginMobile = false;

  @property({ type: String, reflect: false })
  datalayerVariant?: string = '';

  @property({ type: Object, reflect: true })
  protected sessionInfo: any = null;

  @property({ type: Boolean, reflect: true })
  protected showCustomAccountSlotData? = null;

  @property({ type: Function, reflect: true })
  protected urlParser: (u: string) => string = (url: string) => url;

  @property({ type: Boolean, reflect: true })
  protected isLogged = false;

  @property({ type: Boolean, reflect: true })
  protected isUserBetaParticipant = false;

  @query('gntc-menu-dropdown-icon')
  loginMenuDropdownIconReference?: HTMLElement;

  @query('gntc-menu-dropdown-content')
  menuDropdownContentRef?: HTMLElement;

  @query(`slot[name="${SLOT_NAME_UNAUTHORIZED_SECTION}"]`)
  slotLoginSection?: HTMLSlotElement;

  @state()
  isExpanded: boolean | undefined = false;

  protected handleButtonClick(event: MouseEvent) {
    event.stopPropagation();
    this.toggleContent();
  }

  protected handleDocumentClick() {
    setTimeout(() => this.closeSection());
  }

  protected handleDocumentKeyup(evt: KeyboardEvent) {
    if (evt.key === 'Escape') Promise.resolve().then(() => this.closeSection());
  }

  protected handlerContainerClick(event: MouseEvent) {
    // TODO: review/fix secondary navigation close on container click
    event.stopPropagation();
  }

  protected handleContainerKeyup(evt: KeyboardEvent) {
    if (evt.key === 'Escape') Promise.resolve().then(() => this.closeSection());
  }

  protected loginSectionHandlers = {
    onButtonClick: this.handleButtonClick.bind(this),
    onDocumentClick: this.handleDocumentClick.bind(this),
    onDocumentKeyup: this.handleDocumentKeyup.bind(this),
  };

  public closeSection() {
    this.isExpanded = false;
  }

  protected toggleContent() {
    this.isExpanded = !this.isExpanded;

    const onToggleMenuEvent = new CustomEvent('onToggleMenu', {
      isExpanded: this.isExpanded,
    } as CustomEventInit);
    this.dispatchEvent(onToggleMenuEvent);

    emitLoginMenuToggle(this.isExpanded);
  }

  protected getSlottedLoginSection() {
    const [slottedElement] = [...(this.slotLoginSection?.assignedNodes() || [])] as HTMLElement[];
    return slottedElement;
  }

  //flow events
  protected firstUpdated(_changedProperties: PropertyValues) {
    super.firstUpdated(_changedProperties);

    addListenerOnOtherHeaderOptionToggled(({ toggleValue }) => {
      if (toggleValue) {
        this.closeSection();
      }
    });

    const slottedLoginSection = this.getSlottedLoginSection();

    if (slottedLoginSection) {
      captureClicks({ context: slottedLoginSection, isExternal: this.isExternal });
    }

    if (this.shadowRoot) {
      captureClicks({ context: this.shadowRoot, isExternal: this.isExternal });
    }

    this.shadowRoot?.addEventListener('navigate', () => this.closeSection());
  }

  protected updated(_changedProperties: PropertyValues) {
    super.update(_changedProperties);

    if (_changedProperties.has('loginSection')) {
      if (this.loginSection) {
        document.addEventListener('click', this.loginSectionHandlers.onDocumentClick);
        document.addEventListener('keyup', this.loginSectionHandlers.onDocumentKeyup);
        this.menuDropdownContentRef?.addEventListener('click', (event: MouseEvent) =>
          this.handlerContainerClick(event),
        );
      } else {
        document.removeEventListener('click', this.loginSectionHandlers.onDocumentClick);
        document.removeEventListener('keyup', this.loginSectionHandlers.onDocumentKeyup);
        this.menuDropdownContentRef?.removeEventListener('click', (event: MouseEvent) =>
          this.handlerContainerClick(event),
        );
      }
    }
  }

  get navigationSectionsParsed(): NavigationSection[] {
    const parseNavigationItems = (menuGroupSection: MenuGroupSection): NavigationItem[] => {
      return (menuGroupSection.menuItem as MenuItem[]).map((menu: any) => {
        return {
          label: menu?.link?.displayText,
          href: menu?.link?.href,
          openInNewWindow: menu?.link?.openInNewWindow,
        } as NavigationItem;
      });
    };
    const parseNavigationSection = (menuGroupSection: MenuGroupSection): NavigationSection => ({
      title: menuGroupSection.primaryNavigationColumnTitle?.displayText,
      navigationList: parseNavigationItems(menuGroupSection),
    });
    return this.accountSection?.menuGroupList.map(parseNavigationSection) || [];
  }

  protected renderProfileContent = () => {
    const { classes } = useStyles;
    const profileContentComponent = html`
      <gntc-profile-content
        .optionLabel="${this.label}"
        .logoutLabel="${prop('logout_label', this.pageLabels)}"
        .goPortalLabel="${prop('go_to_portal_label', this.pageLabels)}"
        .goPortalIconUrl="${this.urlParser(propOr('', 'go_to_portal_icon', this.pageConfig))}"
        .backButtonLabel="${prop('secondary_back_button_label', this.pageLabels)}"
        .goPortalLink="${propOr('', 'go_to_portal_link', this.pageConfig)}"
        .isMobile="${this.isLoginMobile}"
        @onLogoutClick="${(e: CustomEvent) =>
          this.dispatchEvent(new CustomEvent('onLogoutClick', e))}"
        @closeClicked="${this.loginSectionHandlers.onButtonClick}"
      >
        ${when(this.isUserBetaParticipant, () =>
          this.navigationSectionsParsed.map(
            (navigationList, index) => html`
              <gntc-navigation-list
                .blockTitle="${navigationList.title}"
                .navigationList="${navigationList.navigationList}"
                .spaceBottom="${index !== this.navigationSectionsParsed.length - 1}"
              ></gntc-navigation-list>
            `,
          ),
        )}
      </gntc-profile-content>
    `;

    const customUnAuthorizedMessageComponent = html`
      <div>
        <slot name="${SLOT_NAME_UNAUTHORIZED_SECTION}"></slot>
        <div class="${classes.logoutWrapper}">
          <gntc-logout-button
            .logoutLabel="${prop('logout_label', this.pageLabels)}"
            @onLogoutClick="${(e: CustomEvent) =>
              this.dispatchEvent(new CustomEvent('onLogoutClick', e))}"
          ></gntc-logout-button>
        </div>
      </div>
    `;
    return this.showCustomAccountSlotData
      ? customUnAuthorizedMessageComponent
      : profileContentComponent;
  };

  protected render() {
    const { classes } = useStyles;

    const loginContentWidth = this.isExternal ? '440px' : '640px';
    const loginContentLoggedWidth = '350px';

    return html`
      <div class="${classes.loginSectionContainer}">
        <gntc-menu-dropdown-icon
          label="${this.label}"
          iconUrl="${this.iconUrl}"
          .isActive="${this.isLogged}"
          @click="${this.loginSectionHandlers.onButtonClick}"
        >
          ${this.isLogged
            ? html`
                <gntc-avatar .sessionName=${this.sessionInfo.fullName} size="small"></gntc-avatar>
              `
            : html` <img src="${this.iconUrl}" alt="" /> `}
        </gntc-menu-dropdown-icon>
        <gntc-menu-dropdown-content
          .isExpanded="${this.isExpanded}"
          .isMobile="${this.isLoginMobile}"
          .widthContent="${this.isLogged ? loginContentLoggedWidth : loginContentWidth}"
          .headerTopDistance="${this.headerTopDistance}"
          .dropdownIconRef="${this.loginMenuDropdownIconReference}"
        >
          ${this.isLogged
            ? this.renderProfileContent()
            : html` <gntc-login-content
                datalayerVariant="${this.datalayerVariant}"
                .isLoginMobile="${this.isLoginMobile}"
                .menuGroup="${this.loginSection || []}"
                .loginSectionLabels="${{
                  login_section_title_label: prop('login_section_title_label', this.pageLabels),
                  login_label: prop('login_label', this.pageLabels),
                  not_account_label: prop('not_account_label', this.pageLabels),
                  register_action_label: prop('register_action_label', this.pageLabels),
                }}"
                .registrationUrl="${propOr('', 'genetec_registration_url', this.pageConfig)}"
                .isExternal="${this.isExternal}"
                @onLoginClick="${(e: CustomEvent) =>
                  this.dispatchEvent(new CustomEvent('onLoginClick', e))}"
              ></gntc-login-content>`}
        </gntc-menu-dropdown-content>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'gntc-login-section': LoginSectionBase;
  }
}
