import { makeStyles2 } from '../../../core';

const appsAppMainItemStyles = ({
  spacing,
  palette,
  responsiveMaxWidth,
  mobileBreakpoint,
}: any) => ({
  appMainItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: spacing(3, 2),
    color: palette.primary.dark.text,
    backgroundColor: palette.primary.light.color,
    borderRadius: '4px',
    textDecoration: 'none',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: '500',
    transition: 'background 150ms ease-in-out',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.15)',
    },
    [responsiveMaxWidth(mobileBreakpoint)]: {
      padding: spacing(1.5, 2),
    },
    '&:not(:hover), &:hover': {
      color: 'inherit',
    },
    '& > img': {
      width: '24px',
      height: '24px',
      margin: spacing(0, 1, 0, 0),
    },
    '& > span, & > span > * ': {
      margin: '0',
      padding: '0',
      fontsize: '16px',
      lineHeight: '18px',
    },
  },
});

export default makeStyles2(appsAppMainItemStyles);
