import { html, LitElement, PropertyValues } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { captureClicks } from '../../../../core/utils';
import { generateHeaderMenuToggleTriggers, HeaderMenus } from '../../../gntc-global-header.events';
import useNavigationItemStyles from './gntc-navigation-item.styles';
import { NavigationItem } from './interfaces';

const [emitLoginMenuToggle] = generateHeaderMenuToggleTriggers(HeaderMenus.LoginSection);

@customElement('gntc-navigation-item')
export class GntcNavigationItem extends LitElement {
  static styles = useNavigationItemStyles().css;

  @property({ type: Array, reflect: true })
  navigationItem: NavigationItem = {};

  protected firstUpdated(_changedProperties: PropertyValues) {
    super.firstUpdated(_changedProperties);

    Promise.resolve().then(() => {
      if (this.shadowRoot) {
        captureClicks({
          context: this.shadowRoot,
          onClickCallback: () => emitLoginMenuToggle(true),
        });
      }
    });
  }

  protected render(): unknown {
    const { classes } = useNavigationItemStyles();
    return html`
      <a
        class="${classes.gntcNavigationItem}"
        href=${this.navigationItem.href}
        target="${this.navigationItem.openInNewWindow ? '_blank' : '_self'}"
      >
        <span>${this.navigationItem.label}</span>
        <svg
          width="7"
          height="10"
          viewBox="0 0 7 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1 1L5 5L1 9" stroke="#EDF0F4" stroke-width="1.5" />
        </svg>
      </a>
    `;
  }
}
