import { LitElement, PropertyValues, html } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { propOr } from '../../libs/custom-ramda';
import { generateHeaderMenuToggleTriggers, HeaderMenus } from '../gntc-global-header.events';
import { ContentGroup } from '../gntc-global-header.interface';
import '../shared/gntc-menu-dropdown-content';
import '../shared/gntc-menu-dropdown-icon';
import { HeaderOptionMixin } from '../shared/mixins/HeaderOptionMixin';
import './components/gntc-apps-content';
import useAppsOptionStyles from './gntc-apps-option.styles';

const [emitAppsOptionToggle, addListenerOnOtherHeaderOptionToggle] =
  generateHeaderMenuToggleTriggers(HeaderMenus.AppsSection);

@customElement('gntc-apps-option')
export class GntcAppsOption extends HeaderOptionMixin(LitElement) {
  static styles = useAppsOptionStyles().css;

  @property({ type: Boolean, reflect: true })
  protected isMobile = false;

  @property({ type: Boolean, reflect: true })
  protected pageLabels = {};

  @property({ type: Boolean, reflect: true })
  protected pageConfig = {};

  @property({ type: Number, reflect: true })
  protected headerTopDistance = 0;

  @property({ type: Array, reflect: true })
  protected appsSection?: ContentGroup;

  @query('gntc-menu-dropdown-icon')
  appsMenuDropdownIconReference?: HTMLElement;

  @query('gntc-menu-dropdown-content')
  dropdownContentReference?: HTMLElement;

  @property({ type: Function, reflect: true })
  protected urlParser: (u: string) => string = (url: string) => url;

  @state()
  isExpanded: boolean | undefined = false;

  get appMainItems() {
    if ((this.appsSection?.contentList || []).length < 1) return [];
    return (this.appsSection?.contentList || [])[0].menuItems || [];
  }

  get groupedAppItems() {
    if (!(this.appsSection?.contentList || [])?.[1]) return [];

    const [, ...groupedMenuItems] = this.appsSection?.contentList || [];
    return groupedMenuItems?.map((entry) => entry.menuItems);
  }

  protected firstUpdated(_changedProperties: PropertyValues) {
    super.firstUpdated(_changedProperties);
    addListenerOnOtherHeaderOptionToggle(({ toggleValue }) => {
      if (toggleValue) {
        this.closeDropdownContent();
      }
    });
  }

  protected onDocumentClick() {
    setTimeout(() => (this.isExpanded = false));
  }

  protected onDropdownContentClick(event: MouseEvent) {
    event.stopPropagation();
  }

  protected onScapeKeyup() {
    this.closeDropdownContent();
  }

  protected onOptionOutSideClick() {
    this.closeDropdownContent();
  }

  protected iconButtonClick(event: MouseEvent) {
    event.stopPropagation();
    this.toggleVisibility();
  }

  public closeDropdownContent() {
    this.isExpanded = false;
  }

  protected toggleVisibility() {
    this.isExpanded = !this.isExpanded;
    const onToggleMenuEvent = new CustomEvent('onToggleMenu', {
      isExpanded: this.isExpanded,
    } as CustomEventInit);
    this.dispatchEvent(onToggleMenuEvent);
    emitAppsOptionToggle(this.isExpanded);
  }

  protected render(): unknown {
    const { classes } = useAppsOptionStyles();
    return html`
      <div class="${classes.appOptionContainer}">
        <gntc-menu-dropdown-icon @click="${(event: MouseEvent) => this.iconButtonClick(event)}">
          <img src="${this.urlParser(propOr('', 'apps_icon', this.pageConfig))}" alt="" />
        </gntc-menu-dropdown-icon>
        <gntc-menu-dropdown-content
          .isExpanded="${this.isExpanded}"
          .isMobile="${this.isMobile}"
          .headerTopDistance="${this.headerTopDistance}"
          .dropdownIconRef="${this.appsMenuDropdownIconReference}"
          widthContent="550px"
        >
          <gntc-apps-content
            .partnerOnlyLabel="${propOr('', 'partner_only_label', this.pageLabels)}"
            .partnerOnlyIconUrl="${this.urlParser(
              propOr('', 'partner_only_icon', this.pageConfig),
            )}"
            .partnerOnlyLinkUrl=""
            .mainLogoUrl="${this.urlParser(propOr('', 'apps_main_logo', this.pageConfig))}"
            .appMainItems="${this.appMainItems}"
            .groupedAppItems="${this.groupedAppItems}"
            .isMobile="${this.isMobile}"
            @closeClicked="${() => this.toggleVisibility()}"
          ></gntc-apps-content>
        </gntc-menu-dropdown-content>
      </div>
    `;
  }
}
