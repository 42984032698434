import urllite from 'urllite/lib/core';
import { isMaxWidth } from '../shared/theme/responsive';

const LANG_PATH_PATTERN = /\/[a-z]{2}$/;

export const isPathAbsolute = (path: string) => /^(?:[a-z]+:\/\/)/.test(path);
export const getPageUrl = ({ url, base }: { url: string; base: string }) => {
  if (!url) return url;

  const desireUrl = urllite(url);
  const desireUrlPathname = desireUrl.pathname.replace(/^\/site/, '');

  // If it's an absolute path returns as it comes.
  if (isPathAbsolute(url)) {
    return url;
  }

  const fullUrlPath = desireUrlPathname + (desireUrl.search.length > 1 ? desireUrl.search : '');
  return `${base.replace(/\/$/, '')}/${fullUrlPath.replace(/^\//, '')}`;
};

export const formatText = (text: string, values: string[]) => {
  return text.replace(/%(\d+)/g, (_, m) => values[m - 1]);
};

export enum TextOpacities {
  light1 = 0.95,
  light2 = 0.75,
  light3 = 0.49,
  light4 = 0.15,
  dark1 = 0.8,
  dark2 = 0.6,
  dark3 = 0.38,
  dark4 = 0.18,
}

export const opacityLevelsMap = {
  light: {
    0: 1,
    1: TextOpacities.light1,
    2: TextOpacities.light2,
    3: TextOpacities.light3,
    4: TextOpacities.light4,
  },
  dark: {
    0: 1,
    1: TextOpacities.dark1,
    2: TextOpacities.dark2,
    3: TextOpacities.dark3,
    4: TextOpacities.dark4,
  },
};

export const snakeCaseToTitle = (key: string) =>
  key
    .split('_')
    .map((text) => text.slice(0, 1).toUpperCase() + text.slice(1))
    .join(' ');

export const isMobile = (): boolean => isMaxWidth('md');

interface CaptureClicksProps {
  context: ShadowRoot | HTMLElement;
  isExternal?: boolean;
  onClickCallback?: () => void;
}

export const captureClicks = ({
  context,
  isExternal = false,
  onClickCallback,
}: CaptureClicksProps) => {
  const handleClick = (event: MouseEvent) => {
    if (onClickCallback) {
      onClickCallback();
    }

    const target = event.target as HTMLElement;

    // Get the <a> element.
    const el = target?.closest && (target?.closest('a') as HTMLAnchorElement);
    const datalayerEventsBlacklist = el?.getAttribute('data-datalayer-events-blacklist') || '';

    // Dispatch GTM Click
    const newClickEvent = new CustomEvent('gtm:click:link', {
      bubbles: true,
      composed: true,
      detail: { target },
    });
    if (!datalayerEventsBlacklist?.split(',')?.includes('gtm:click:link'))
      context.dispatchEvent(newClickEvent);

    // Ignore canceled events, modified clicks, and right clicks.
    if (event.defaultPrevented) return;

    if (event.metaKey || event.ctrlKey || event.shiftKey) return;

    if (event.button !== 0) return;

    // Ignore clicks from non-a elements.
    if (!el) return;

    // Ignore the click if the element has a target.
    if (el.target && el.target !== '_self') return;

    // Ignore the click if it's a download link.
    if (el.download) return;

    // Ignore hash (used often instead of javascript:void(0) in strict CSP envs)
    if (el.getAttribute('href') === '#') return;

    // Use a regular expression to parse URLs instead of relying on the browser
    // to do it for us (because IE).
    const url = urllite(el.href);
    const windowURL = urllite(window.location.href);

    // Ignore links that don't share a protocol and host with ours.
    if (url.protocol !== windowURL.protocol || url.host !== windowURL.host) return;

    // Ignore 'rel="external"' links.
    if (el.rel && /(?:^|\s+)external(?:\s+|$)/.test(el.rel)) return;

    // Absolute URL that is referencing itself. E.g.: Uberflip w/ self-URL.
    // ".getAttribute('href')" is not the same as ".href" b/c it the later always returns an absolute URL.
    const href = el.getAttribute('href') || '';
    if (isPathAbsolute(href) && url.host === windowURL.host) return;

    // Prevent :focus from sticking; preventDefault() stops blur in some browsers
    el.blur();
    if (!isExternal) event.preventDefault();

    // Dispatch Navigate
    const newEvent = new CustomEvent('navigate', {
      bubbles: true,
      composed: true,
      detail: {
        href: url.pathname + (url.search ? url.search : '') + (url.hash.length > 1 ? url.hash : ''),
      },
    });

    context.dispatchEvent(newEvent); // External management (ex: React Router)
  };

  context.addEventListener('click', handleClick as EventListenerOrEventListenerObject);
};

export const safeJSONParse = (nextValue: any) => {
  let value: string | undefined;

  if (typeof nextValue !== 'string') {
    return nextValue;
  }

  try {
    value = JSON.parse(nextValue);
  } catch (error) {
    value = undefined;
  }

  return value;
};

export const isFocusWithin = (container?: HTMLElement | null) => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(container && container.querySelector('*:focus-within')), 0);
  });
};

export const getImageFileName = (value: string) => value.replace(/.*\/(\w+.\w+)$/, '$1');

export const hexToRGBA = (hex: string, alpha = 1) => {
  const hexcode = hex.length === 4 ? hex + hex.slice(1, 4) : hex;
  const [r, g, b] = hexcode.match(/\w\w/g)?.map((x) => parseInt(x, 16)) || [];

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const opacityColor = (
  theme: 'light' | 'dark',
  color: string,
  opacityLevel: 1 | 2 | 3 | 4,
) => {
  const alpha = opacityLevelsMap[theme][opacityLevel];

  return hexToRGBA(color, alpha);
};

export const getUrl = (baseUrl: string) => {
  return urllite(baseUrl);
};

// TODO: Validate BR and KR language codes (non-standardized).
export const getAlternateBaseURL = ({ language = 'en', baseUrl = '' }) => {
  const thisPath = LANG_PATH_PATTERN.exec(baseUrl)?.[0] || '';
  const newPath = language && language !== 'en' ? `/${language}` : '';
  const altBaseURL = Boolean(thisPath !== newPath) && baseUrl.replace(LANG_PATH_PATTERN, newPath);
  const cleanUrl = altBaseURL ? altBaseURL.replace(/\/$/, '') : baseUrl.replace(/\/$/, '');
  return cleanUrl;
};

export const matchExternalLink = (url: string) => /^https?:\/\//.test(url);

interface EmitCustomEventProps<T> {
  context: HTMLElement;
  eventName: string;
  event: T;
}
export const emitCustomEvent: <T>(p: EmitCustomEventProps<T>) => void = ({
  context,
  eventName,
  event,
}: EmitCustomEventProps<any>) => {
  const newCustomEvent = new CustomEvent(eventName, event);
  context.dispatchEvent(newCustomEvent);
};
