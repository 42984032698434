import { html, LitElement, PropertyValues } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { getOr } from 'lodash/fp';
import { generateHeaderMenuToggleTriggers, HeaderMenus } from '../gntc-global-header.events';
import '../shared/gntc-close-icon';
import '../shared/gntc-menu-dropdown-icon';
import { HeaderOptionMixin } from '../shared/mixins/HeaderOptionMixin';
import './components/gntc-notification-badge';
import useNotificationOptionStyles from './gntc-notifications-option.styles';

const [emitNotificationsOptionToggle, addListenerOnOtherHeaderOptionToggled] =
  generateHeaderMenuToggleTriggers(HeaderMenus.NotificationsSection);

@customElement('gntc-notifications-option')
export class GntcNotificationsOption extends HeaderOptionMixin(LitElement) {
  static styles = useNotificationOptionStyles().css;

  @property({ type: Boolean, reflect: true })
  protected isMobile = false;

  @property({ type: Boolean, reflect: true })
  protected pageConfig = {};

  @property({ type: Function, reflect: true })
  protected urlParser: (u: string) => string = (url: string) => url;

  @property({ type: Boolean, reflect: true })
  protected hasUnreadNotifications = false;

  @query('gntc-menu-dropdown-icon')
  notificationsIconReference?: HTMLElement;

  @state()
  isExpanded: boolean | undefined = false;

  protected firstUpdated(_changedProperties: PropertyValues) {
    this.isSlottedOption = true;
    super.firstUpdated(_changedProperties);
    addListenerOnOtherHeaderOptionToggled(
      ({ toggleValue }) => {
        if (toggleValue) {
          this.closeDropdownContent();
        }
      },
      () => {
        this.toggleVisibility();
      },
    );
  }

  protected onScapeKeyup() {
    this.closeDropdownContent();
  }

  protected onOptionOutSideClick() {
    this.closeDropdownContent();
  }

  public closeDropdownContent() {
    this.isExpanded = false;
  }

  protected onNotificationOptionIconClick(event: MouseEvent) {
    event.stopPropagation();
    this.toggleVisibility();
  }

  protected toggleVisibility() {
    this.isExpanded = !this.isExpanded;
    emitNotificationsOptionToggle(this.isExpanded);
    if (this.isExpanded) {
      const newCustomEvent = new CustomEvent('notifications:on-opening-click', {
        bubbles: true,
        composed: true,
      });
      this.dispatchEvent(newCustomEvent);
    }
  }

  protected render(): unknown {
    const { classes } = useNotificationOptionStyles();
    return html`
      <div class="${classes.notificationsOptionContainer}">
        ${this.hasUnreadNotifications
          ? html`<gntc-notification-badge
              class="${classes.notificationBadge}"
            ></gntc-notification-badge>`
          : null}

        <gntc-menu-dropdown-icon
          @click="${(event: MouseEvent) => this.onNotificationOptionIconClick(event)}"
        >
          <img
            class="${classes.icon}"
            src="${this.urlParser(getOr('', 'notification_bell_filled_icon', this.pageConfig))}"
            alt=""
          />
        </gntc-menu-dropdown-icon>
        <gntc-menu-dropdown-content
          .isExpanded="${this.isExpanded}"
          .isMobile="${this.isMobile}"
          .dropdownIconRef="${this.notificationsIconReference}"
          .hasPadding="${false}"
          .contentDesktopStyles="${{ maxHeight: '80vh', overflow: 'auto' }}"
          widthContent="350px"
        >
          <div class="${classes.contentWrapper}">
            ${this.isMobile
              ? html`<gntc-close-icon
                  class="${classes.mobileCloseIcon}"
                  @closeClick="${() => this.closeDropdownContent()}"
                ></gntc-close-icon>`
              : null}
            <slot></slot>
          </div>
        </gntc-menu-dropdown-content>
      </div>
    `;
  }
}
