import { makeStyles2 } from '../../../core';
import { Styles } from 'jss';

const useGntcPartnerOnlyFilterStyles = makeStyles2(
  ({ spacing, palette, responsiveMaxWidth }): Styles => ({
    root: {
      '& .filter-label': {
        margin: spacing(0, 0, 1),
      },
      '& .partner-only': {
        fontSize: '12px',
        display: 'flex',
        alignItems: 'center',
        margin: spacing(0, 0, 3),
        '& img': {
          width: '12px',
          marginRight: spacing(1),
        },
      },
      '& .filter-category-wrap': {
        [responsiveMaxWidth('md')]: {
          overflowX: 'auto',
          whiteSpace: 'nowrap',
        },
      },
    },
    filterOption: {
      display: 'inline-flex',
      padding: spacing(0.5, 2),
      borderRadius: '4px',
      backgroundColor: palette.primary.light.color,
      color: palette.primary.text,
      textDecoration: 'none',
      marginRight: spacing(2),
      marginBottom: spacing(2),
      alignItems: 'center',
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
      textTransform: 'capitalize',
      '& img': {
        marginLeft: spacing(1),
        width: '12px',
      },
      '&:last-child': {
        marginRight: 0,
      },
    },
    filterOptionCount: {
      display: 'inline-block',
      margin: spacing(0, 1),
    },
  }),
);

export default useGntcPartnerOnlyFilterStyles;
