import qs from 'query-string';
import {
  getPageUrl,
  snakeCaseToTitle,
  formatText,
  getAlternateBaseURL,
  isPathAbsolute,
} from '../../core/utils';
import { SearchResult } from '../search-navigation/gntc-search-navigation.interface';

interface AdaptSearchResultsProps {
  results: any[];
  spaBaseUrl: string;
  cmsBaseUrl: string;
  config: Record<string, string>;
  language: string;
  isExternal?: boolean;
}

export const adaptSearchResults = ({
  results,
  spaBaseUrl,
  cmsBaseUrl,
  config = {},
  language = 'en',
  isExternal = false,
}: AdaptSearchResultsProps): SearchResult[] => {
  const sdl_url = config?.sdl_detail_url || '/supported-device-list?id=%1';
  const MAX_TITLE_LEN_L = 25;
  const MAX_TITLE_LEN_R = 150;
  const MOBILE_VIEWPORT = 480;
  const baseUrl = isExternal ? config?.spa_base_url || cmsBaseUrl : cmsBaseUrl;

  return results.map((item) => {
    let url = item?.url?.raw || '';
    const rawTitle = item?.title?.raw || '';
    const itemLang = item?.language?.raw || 'en';
    const title =
      window.innerWidth < MOBILE_VIEWPORT && rawTitle.length > MAX_TITLE_LEN_R
        ? `${rawTitle.substring(0, MAX_TITLE_LEN_R)}...`
        : rawTitle;
    const snippet =
      rawTitle.length > MAX_TITLE_LEN_L ? `${rawTitle.substring(0, MAX_TITLE_LEN_L)}...` : rawTitle;

    // NOTE: "Supported devices" is a hard-coded value, it used to exist a hard-coded value before too.
    const isSDL =
      !!item?.tax_content_category?.raw?.length &&
      item?.tax_content_category?.raw?.includes('Supported devices');
    if (isSDL) {
      const rawUrl = formatText(sdl_url, [item?.id?.raw || '']);
      url = qs.stringifyUrl({ url: rawUrl, query: {} });
      if (!config?.sdl_detail_url && itemLang !== language)
        url = `${getAlternateBaseURL({ language: itemLang, baseUrl })}${url}`;
    } else if (itemLang !== language && !isPathAbsolute(url)) {
      url = `${getAlternateBaseURL({ language: itemLang, baseUrl })}${url}`;
    }

    url = encodeURI(decodeURI(url));

    return {
      title,
      snippet,
      description: item?.description?.snippet || item?.body?.snippet || '',
      url: getPageUrl({ base: spaBaseUrl, url }),
      icon: getPageUrl({ base: spaBaseUrl, url: item?.icon?.raw || '' }),
      taxContentCategory: item?.tax_content_category?.raw?.length
        ? snakeCaseToTitle(item?.tax_content_category?.raw?.[0])
        : '',
      document_id: item?.id?.raw,
    };
  });
};

export const adaptSearchSuggestions = (results: any, query = ''): string[] => {
  return results.documents.map(
    (item: { suggestion: string }) => `${query}${item.suggestion.substr(query.length)}`,
  );
};
