import { html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import useVerticalDividerStyles from './gntc-vertical-divider.styles';

@customElement('gntc-vertical-divider')
export class GntcVerticalDivider extends LitElement {
  static styles = useVerticalDividerStyles().css;

  protected render(): unknown {
    const classes = useVerticalDividerStyles().classes;
    const secNavMenuGroupListVerticalDividerClasses = classMap({
      [classes.secNavMenuGroupListVerticalDivider]: true,
      secNavMenuGroupListVerticalDivider: true,
    });
    return html`
      <div class="${secNavMenuGroupListVerticalDividerClasses}">
        <div class="verticalDivider"></div>
      </div>
    `;
  }
}
