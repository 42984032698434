import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import './gntc-navigation-item';
import useNavigationListStyles from './gntc-navigation-list.styles';
import { NavigationItem } from './interfaces';

@customElement('gntc-navigation-list')
export class GntcNavigationList extends LitElement {
  static styles = useNavigationListStyles().css;

  @property({ type: String, reflect: true })
  blockTitle = '';

  @property({ type: Array, reflect: true })
  navigationList: Array<NavigationItem> = [];

  @property({ type: Boolean, reflect: true })
  spaceBottom = false;

  protected render(): unknown {
    const { classes } = useNavigationListStyles();
    return html`
      <div
        class="${classMap({
          [classes.gntcNavigationList]: true,
          [classes.gntcNavigationListSpaceBottom]: this.spaceBottom,
        })}"
      >
        <span class="${classes.gntcNavigationListTitle}">${this.blockTitle}</span>
        <div>
          ${this.navigationList.map(
            (navigationItem) => html`
              <div>
                <gntc-navigation-item .navigationItem="${navigationItem}"></gntc-navigation-item>
              </div>
            `,
          )}
        </div>
      </div>
    `;
  }
}
