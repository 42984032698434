import { makeStyles2 } from '../../../core';
import { opacityColor } from '../../../core/utils';

const appsDropdownContentStyles = ({
  spacing,
  palette,
  responsiveMaxWidth,
  mobileBreakpoint,
}: any) => ({
  appsContent: {
    padding: spacing(4, 1),
    [responsiveMaxWidth(mobileBreakpoint)]: {
      padding: spacing(2, 4),
    },
    '& .close-row': {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '9px 0 24px',
    },
  },
  appsContentHeader: {
    display: 'flex',
    margin: spacing(0, 0, 3),
  },
  appsContentHeaderLogo: {
    flex: '1',
    [responsiveMaxWidth(mobileBreakpoint)]: {
      display: 'none',
    },
  },
  appsContentHeaderPartner: {
    flex: '1',
    display: 'flex',
    justifyContent: 'flex-end',
    [responsiveMaxWidth(mobileBreakpoint)]: {
      justifyContent: 'flex-start',
    },
    color: palette?.secondary?.color,
  },
  horizontalDivider: {
    height: '1px',
    width: '100%',
    backgroundColor: opacityColor('dark', palette.primary.dark.text, 4),
    margin: spacing(3, 0),
  },
  appsMainItems: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: spacing(0, -2),
    '& .mainItemContainer': {
      width: '50%',
      padding: spacing(0, 2),
      boxSizing: 'border-box',
      [responsiveMaxWidth(mobileBreakpoint)]: {
        width: '100%',
        margin: spacing(0, 0, 2),
      },
    },
    '& .mainItemContainer:last-child': {
      margin: '0 !important',
    },
  },
  appsItems: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: spacing(0, -1),
    '& .itemContainer': {
      width: '33.3333333%',
      padding: spacing(0, 1),
      boxSizing: 'border-box',
      [responsiveMaxWidth(mobileBreakpoint)]: {
        width: '100%',
      },
    },
  },
  mainItemContainerMargin: {
    margin: spacing(0, 0, 2),
  },
  appsCloseIcon: {
    position: 'relative',
  },
});

export default makeStyles2(appsDropdownContentStyles);
