import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import useMobileBackButtonStyles from './gntc-mobile-back-button.styles';

@customElement('gntc-mobile-back-button')
export class GntcMobileBackButton extends LitElement {
  static styles = useMobileBackButtonStyles().css;

  @property({ type: String, reflect: true })
  icon = null;

  @property({ type: String, reflect: true })
  href = null;

  @property({ type: Boolean, reflect: true })
  openInNewWindow = false;

  @property({ type: String, reflect: true })
  datalayerScenario = 'menu-link';

  protected render(): unknown {
    const { classes } = useMobileBackButtonStyles();

    if (!this.href) {
      return html`
        <span
          class="${classes.mobileBackButton}"
          @click=${(e: MouseEvent) => this.dispatchEvent(new CustomEvent('onClick', e))}
        >
          ${this.icon}
          <span class="text">
            <slot></slot>
          </span>
        </span>
      `;
    }

    return html`
      <a
        class="${classes.mobileBackButton}"
        data-datalayer-scenario=${ifDefined(
          this.datalayerScenario ? this.datalayerScenario : undefined,
        )}
        tabindex="0"
        href="${this.href}"
        target="${this.openInNewWindow ? '_blank' : '_self'}"
        @click=${(e: MouseEvent) => this.dispatchEvent(new CustomEvent('onClick', e))}
      >
        ${this.icon}
        <span class="text">
          <slot></slot>
        </span>
      </a>
    `;
  }
}
