const offset = '1244px';
const inset = '1044px';

const container = (theme: Theme) => ({
  width: '100%',
  display: 'flex',
  padding: theme.spacing(0, 4),
  margin: '0 auto',
});

const offsetWidth = (theme: Theme) => ({
  ...container(theme),
  maxWidth: offset,
});

const insetWidth = (theme: Theme) => ({
  ...container(theme),
  maxWidth: inset,
});

export default (theme: Theme) => ({
  standard: container(theme),
  offset: offsetWidth(theme),
  inset: insetWidth(theme),
});
