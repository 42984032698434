import { LitElement, PropertyValues, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { captureClicks } from '../../../../core/utils';
import { generateHeaderMenuToggleTriggers, HeaderMenus } from '../../../gntc-global-header.events';
import '../../../shared/gntc-logout-button';
import '../../../shared/gntc-menu-link';
import '../../../shared/gntc-mobile-back-button';
import useProfileContentStyles from './gntc-profile-content.styles';

const [emitLoginMenuToggle] = generateHeaderMenuToggleTriggers(HeaderMenus.GlobalNavigation);

@customElement('gntc-profile-content')
export class GntcProfileContent extends LitElement {
  static styles = useProfileContentStyles().css;

  @property({ type: String, reflect: true })
  protected optionLabel = '';

  @property({ type: String, reflect: true })
  protected logoutLabel = '';

  @property({ type: String, reflect: true })
  protected backButtonLabel = '';

  @property({ type: String, reflect: true })
  protected goPortalLabel = '';

  @property({ type: String, reflect: true })
  protected goPortalIconUrl = '';

  @property({ type: String, reflect: true })
  protected goPortalLink = '';

  @property({ type: Boolean, reflect: true })
  protected isMobile = false;

  onLogoutClicked(e: Event): void {
    e.preventDefault();
    this.dispatchEvent(new CustomEvent('onLogoutClick'));
  }

  protected firstUpdated(_changedProperties: PropertyValues) {
    super.firstUpdated(_changedProperties);

    Promise.resolve().then(() => {
      if (this.shadowRoot) {
        captureClicks({
          context: this.shadowRoot,
          onClickCallback: () => emitLoginMenuToggle(true),
        });
      }
    });
  }

  protected render(): unknown {
    const { classes } = useProfileContentStyles();

    const goToPortalComponent = () => html`
      <gntc-menu-link
        color="blue"
        .href="${this.goPortalLink}"
        .iconUrl="${this.goPortalIconUrl}"
        .label="${this.goPortalLabel}"
      >
      </gntc-menu-link>
    `;

    return html`
      <div class="${classes.gntcProfileContent}">
        ${this.isMobile
          ? html`
              <div class="close-row">
                ${goToPortalComponent()}
                <div>
                  <gntc-close-icon
                    class="${classes.profileCloseIcon}"
                    @closeClick="${() => this.dispatchEvent(new CustomEvent('closeClicked'))}"
                  ></gntc-close-icon>
                </div>
              </div>
            `
          : undefined}
        <div class="${`${classes.gntcProfileHeader} profile-row`}">
          <div class="${`${classes.gntcProfileAccountWrapper} profile-column`}">
            <span class="${classes.gntcProfileAccount}">${this.optionLabel}</span>
          </div>
          <div class="${`${classes.gntcProfileGoPortalWrapper} profile-column`}">
            ${!this.isMobile ? goToPortalComponent() : undefined}
          </div>
        </div>
        <div class="${classes.gntcProfileBody}">
          <slot></slot>
        </div>
        <div class="${classes.gntcProfileFooter}">
          <gntc-logout-button
            .logoutLabel="${this.logoutLabel}"
            @onLogoutClick="${(e: CustomEvent) =>
              this.dispatchEvent(new CustomEvent('onLogoutClick', e))}"
          ></gntc-logout-button>
        </div>
      </div>
    `;
  }
}
