import { html } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { getHref } from '../core/resolvers';
import { GntcGlobalHeaderLinkMixin } from './gntc-global-header-link-mixin';
import useStyles from './gntc-global-header.styles';

// Using a mixin concept to move the logic in different file to make it easy to understand
export class GntcGlobalHeaderBaseMixin extends GntcGlobalHeaderLinkMixin {
  constructor(private $globalHeaderRef: any) {
    super($globalHeaderRef);
  }

  getLogo(imageUrl: string, label: string) {
    const { logo: logoClass, logoWrapper } = useStyles.classes;

    const spaBaseUrl = this.$globalHeaderRef.spaBaseUrl;
    const datalayerVariant = this.$globalHeaderRef.datalayerVariant;
    const url = spaBaseUrl || '/';

    return html`<a
      href="${url}"
      data-datalayer-variant=${ifDefined(datalayerVariant ? datalayerVariant : undefined)}
      arial-label="${label}"
      class="${logoWrapper}"
    >
      <img class="${logoClass}" src="${imageUrl}" alt="" />
    </a>`;
  }

  getHamburgerMenuButton({ onClick }: { onClick: () => void }) {
    const config = this.$globalHeaderRef.config;
    const menuIsOpen = this.$globalHeaderRef.menuIsOpen;

    const { menuButtonLabel } = config || {};
    const { menuButton: menuButtonClass } = useStyles.classes;

    const menuButtonClasses = classMap({
      [menuButtonClass]: true,
      'is-open': menuIsOpen,
      'is-campaign': this.$globalHeaderRef.campaign,
    });

    return html`<button
      class="${menuButtonClasses}"
      aria-label="${menuButtonLabel || 'Open the menu'}"
      @click=${() => onClick()}
    >
      <span></span>
      <span></span>
    </button>`;
  }

  getContactUsButtonLink() {
    const { contact_label: contactButtonLabel } = this.$globalHeaderRef.labels || {};
    const { contact_link: contactButtonUrl, contact_marketo_id: contactButtonMarketoId } =
      this.$globalHeaderRef.config || {};

    const { buttonLink: buttonLinkClass } = useStyles.classes;
    const buttonLinkClasses = classMap({
      [buttonLinkClass]: true,
    });
    const isInternalAction = !this.$globalHeaderRef.isExternal && contactButtonMarketoId;
    const linkProps = {
      displayText: contactButtonLabel || '',
      openInNewWindow: this.$globalHeaderRef.isExternal, // TODO(luis.sardon): validate this line
      href: getHref({ url: contactButtonUrl, spaBaseUrl: this.$globalHeaderRef.spaBaseUrl }),
    };

    const openInternalContactModalForm = (e: MouseEvent) => {
      const target = (e?.target as HTMLSpanElement)?.closest('a');

      const openContactFormEvent = new CustomEvent('opencontactform', {
        bubbles: true,
        composed: true,
        detail: { formId: contactButtonMarketoId, linkText: target?.textContent?.trim() },
      });
      this.$globalHeaderRef.dispatchEvent(openContactFormEvent);

      const ctaEvent = new CustomEvent('gtm:click:cta', {
        bubbles: true,
        composed: true,
        detail: { target },
      });
      this.$globalHeaderRef.dispatchEvent(ctaEvent);
    };

    const handleContactButtonClick = (event: MouseEvent) => {
      if (!isInternalAction) return; // if is out of react context, continue with the anchor event
      event.preventDefault();
      openInternalContactModalForm(event);
    };

    return html`${this.getLink({
      linkProps,
      classes: buttonLinkClasses,
      onClick: handleContactButtonClick,
      datalayerScenario: 'contact-us',
      datalayerType: 'popup_form',
      datalayerIgnoreLink: true,
    })}`;
  }
}
