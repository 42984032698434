//Login

const getLoginSectionStyles = ({ spacing, responsiveMaxWidth, mobileBreakpoint, palette }: any) => {
  return {
    loginSectionContainer: {
      position: 'relative',
      '& .secNavMain': {
        display: 'block',
        width: 'auto',
        padding: spacing(6, 1.5, 6.25),
        [responsiveMaxWidth(mobileBreakpoint)]: {
          top: '160px',
          left: 0,
          padding: spacing(0, 0, 5),
          overflow: 'auto',
        },
      },
      '& .secNavContainer': {
        display: 'block',
        padding: 0,
      },
      '& .secNavMenuGroupList': {
        padding: spacing(0, 5.5),
      },
      '& .secNavMenuGroupListVerticalDivider': {
        padding: 0,
      },
      '& .loginButton': {
        outline: 'none',
      },
    },
    LoginSectionContent: {
      '&.LoginSectionContentMobile': {
        width: 'auto',
        top: spacing(10),
        right: 0,
        left: 0,
      },
      '& .loginSecNavMainMobile': {
        top: '0px',
        position: 'absolute',
        overflow: 'auto',

        '& .secNavContainer': {
          paddingBottom: spacing(4),
        },

        '& .secNavMenuGroupList': {
          padding: spacing(0, 4.25),
        },

        '& .secNavMenuGroupSectionTitle': {
          marginBottom: spacing(1),
        },

        '& .secNavMenuGroupSectionTitleLink': {
          marginBottom: 0,
        },

        '& .secNavMenuGroupListVerticalDivider': {
          height: '1px',
          width: 'auto',
          background: palette.background.dark.text,
          opacity: 0.38,
          margin: spacing(2, 4.25, 0, 4.25),
        },
      },
    },
    logoutWrapper: {
      padding: spacing(0, 1, 3),
      marginTop: spacing(-2),
    },
  };
};

export default getLoginSectionStyles;
