import { makeStyles2 } from '../../../../core';
import { linkHoverStyle } from '../../../gntc-global-header.styles';

const navigationItemStyles = ({ spacing, palette }: any) => ({
  gntcNavigationItem: {
    display: 'inline-flex',
    alignItems: 'center',
    textDecoration: 'none',
    cursor: 'pointer',
    fontWeight: '600',
    fontSize: '13px',
    lineHeight: '100%',
    color: palette.primary.dark.text, // opacityColor('dark', palette.primary.dark.text, 3),
    padding: spacing(1.5, 2),
    margin: spacing(0.2, -2),
    '& span': {
      display: 'inline-block',
      margin: spacing(0, 1, 0, 0),
    },
    ...linkHoverStyle(),
  },
});

export default makeStyles2(navigationItemStyles);
