import clsx from 'clsx';
import { html, TemplateResult } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { when } from '../../libs/custom-lit-directives/when';
import { GlobalHeaderBase } from '../gntc-global-header-base';
import { GntcGlobalHeaderLinkMixin } from '../gntc-global-header-link-mixin';
import { MenuGroup, MenuGroupSection, MenuItem } from '../gntc-global-header.interface';
import useStyles from '../gntc-global-header.styles';
import { GntcMainNavigationElementsMixin } from './gntc-main-navigation-elements.mixin';
import { GntcMainNavigationUtilsMixin, LinkStyles } from './gntc-main-navigation-utils.mixin';

export class GlobalMainNavigation {
  protected navigationUtilsMixin = new GntcMainNavigationUtilsMixin(this.$globalHeaderRef);
  protected globalHeaderLinkMixin = new GntcGlobalHeaderLinkMixin(this.$globalHeaderRef);
  protected navigationElementsMixin = new GntcMainNavigationElementsMixin(this.$globalHeaderRef);

  constructor(protected $globalHeaderRef: GlobalHeaderBase) {}

  toggleExpanded(mainNavigationRef: HTMLElement) {
    this.navigationElementsMixin.updatePositionColorDivider(mainNavigationRef);
  }

  isComponentTitleStandard() {
    return (
      this.$globalHeaderRef.linkListTitleStyle.toLowerCase() == 'standard' ||
      this.$globalHeaderRef.linkListTitleStyle.trim().length == 0
    );
  }

  isComponentTitleSmallCaps() {
    return this.$globalHeaderRef.linkListTitleStyle.toLowerCase() == 'small-caps';
  }

  /*
   *
   * @templates desktop
   */

  getColumnTitleLink(menuLink: LinkType) {
    const { secNavMenuGroupSectionTitleLink } = useStyles.classes;

    const secNavMenuGroupSectionTitleLinkClasses = clsx(
      secNavMenuGroupSectionTitleLink,
      'main-navigation-group-title',
      {
        standard: this.isComponentTitleStandard(),
        smallCaps: this.isComponentTitleSmallCaps(),
      },
    );

    return this.globalHeaderLinkMixin.getLink({
      linkProps: menuLink,
      classes: secNavMenuGroupSectionTitleLinkClasses,
      datalayerVariant: this.$globalHeaderRef.datalayerVariant,
    });
  }

  renderMenuGroups(menuGroupList: MenuGroupSection[] = [], fullWidth?: boolean) {
    const { secNavMenuGroupList } = useStyles.classes;
    const hasPromoOrIconLinks = this.navigationUtilsMixin.hasPromoOrIconLink(menuGroupList);
    const hasSectionWithTitle = this.navigationUtilsMixin.hasSectionWithTitle(menuGroupList);

    return html`
      <div
        class="${clsx(
          'menu-group-list',
          secNavMenuGroupList,
          hasPromoOrIconLinks ? 'promo-column' : '',
          hasSectionWithTitle ? '' : 'empty-title-column',
          fullWidth ? 'full-width-column' : '',
        )}"
      >
        ${menuGroupList?.map((menuGroupSection) => this.renderMenuGroupOrVariant(menuGroupSection))}
      </div>
    `;
  }

  renderMenuGroupOrVariant(menuGroupSection: MenuGroupSection) {
    const { variant } = menuGroupSection;
    const variants: any = {
      promo: () => this.navigationElementsMixin.getMenuGroupSectionVariantPromo(menuGroupSection),
      iconLink: () =>
        this.navigationElementsMixin.getMenuGroupSectionVariantIconLink(menuGroupSection),
      image: () => this.navigationElementsMixin.getMenuGroupSectionVariantImage(menuGroupSection),
    };
    return !variant ? this.renderMenuGroupSection(menuGroupSection) : variants[variant]();
  }

  renderMenuGroupSection(menuGroupSection: MenuGroupSection) {
    const { primaryNavigationColumnTitle, menuItem } = menuGroupSection;
    const { secNavMenuGroupSection, secNavMenuGroupSectionTitle } = useStyles.classes;

    return html`
      <div class="${clsx(secNavMenuGroupSection, 'menu-group-section')}">
        ${when(
          primaryNavigationColumnTitle?.displayText,
          () => html`
            <div class="${clsx(secNavMenuGroupSectionTitle, 'menu-group-title')}">
              ${this.getColumnTitleLink(primaryNavigationColumnTitle!)}
            </div>
          `,
        )}
        ${this.renderMenuGroupSectionItems(Array.isArray(menuItem) ? menuItem : [menuItem])}
      </div>
    `;
  }

  renderMenuGroupSectionItems(menuItems: MenuItem[] = []) {
    const { secNavMenuGroupSectionItems } = useStyles.classes;

    return html`
      <ul class="${clsx(secNavMenuGroupSectionItems, 'menu-items')}" role="menu">
        ${menuItems?.map((menuItem) => this.getMenuItem(menuItem))}
      </ul>
    `;
  }

  getMenuItem(menuItem: MenuItem) {
    const { link, linkStyle, menuDescription, image } = menuItem;
    const linkClass = this.navigationUtilsMixin.getMenuItemStyleClass(linkStyle as LinkStyles);
    const isSmallCaps = this.isComponentTitleSmallCaps();

    const iconContent = image?.original
      ? html`<img src="${image.original.url}" alt="" />`
      : undefined;

    const navLinkClasses = clsx('menu-item-link', linkClass, {
      standard: this.isComponentTitleStandard(),
      smallCaps: isSmallCaps,
      'has-icon': Boolean(iconContent),
    });

    const isCTA = linkStyle === LinkStyles.button || linkStyle === LinkStyles.filled;
    const onClick = isCTA ? this.navigationElementsMixin.handleCTAClick : undefined;

    return html`
      <li
        role="none"
        class="${linkStyle || 'standard'} secNavItem menu-item ${isSmallCaps ? 'is-smallCaps' : ''}"
      >
        ${link
          ? this.globalHeaderLinkMixin.getLink({
              linkProps: link,
              classes: navLinkClasses,
              datalayerVariant: this.$globalHeaderRef.datalayerVariant,
              icon: iconContent,
              ...(onClick ? { onClick } : {}),
            })
          : undefined}
        ${this.isComponentTitleSmallCaps() && menuDescription
          ? this.getMenuDescription(menuDescription)
          : ''}
      </li>
    `;
  }

  getMenuDescription(menuDescription: string) {
    const { secNavMenuItemDescription: secNavMenuItemDescriptionClass } = useStyles.classes;
    const secNavMenuItemDescriptionClasses = classMap({
      [secNavMenuItemDescriptionClass]: true,
    });
    return html`
      <div class="${secNavMenuItemDescriptionClasses}">${unsafeHTML(menuDescription)}</div>
    `;
  }

  // TODO: adding support to multiple location of divider. we can work with the background direction to use it in left or right
  renderMenuGroupsWithDivider(menuGroups: MenuGroup[] = []) {
    if (!menuGroups) return undefined;

    return html`
      ${menuGroups?.map((menuGroup, index) => {
        const isFirst = index === 0;
        const isLast = index + 1 === menuGroups.length;
        const hasDivider = menuGroup.containerBackground && !menuGroup.fullWidth;
        const renderDivider = () => html`
          <div
            class="color-divider no-column"
            data-color="${menuGroup.containerBackground}"
            data-position="${isFirst ? 'left' : isLast ? 'right' : ''}"
          >
            <span></span>
          </div>
        `;

        return html`
          ${when(hasDivider && isLast, () => renderDivider())}
          ${this.renderMenuGroups(menuGroup.menuGroupList, menuGroup.fullWidth)}
          ${when(hasDivider && isFirst, () => renderDivider())}
        `;
      })}
    `;
  }

  /* templates */

  renderDesktopDropdownContent(rawMenuGroups: MenuGroup[]) {
    const [columnMenuGroups = [], fullWidthMenuGroups] =
      this.navigationUtilsMixin.extractFullWidthFormGroups(rawMenuGroups);

    const secNavMenuGroupClasses = classMap({
      [useStyles.classes.secNavMenuGroup]: true,
      [`columns-${columnMenuGroups.length}`]: columnMenuGroups.length > 0,
    });

    return this.renderDropdownContainer(
      () => html`
        <div class="${secNavMenuGroupClasses}">
          ${this.renderMenuGroupsWithDivider(columnMenuGroups)}
        </div>
      `,
      () =>
        this.navigationElementsMixin.renderFullWidthFormGroupRows(
          fullWidthMenuGroups,
          (menuGroup) => this.renderMenuGroups(menuGroup.menuGroupList),
        ),
    );
  }

  renderMobileDropdownContent(
    menuGroups: MenuGroup[],
    onBackButtonClicked: (event: MouseEvent) => void,
  ) {
    const { secondary_back_button_label: secondaryBackButtonLabel } = this.$globalHeaderRef
      .labels || {
      secondary_back_button_label: 'Back',
    };
    const { secNavMenuGroup, navMobileBackButton } = useStyles.classes;

    const backButtonClicked = (event: MouseEvent) => {
      event.stopPropagation();
      onBackButtonClicked(event);
    };

    return this.renderDropdownContainer(
      () => html`
        <div class="${secNavMenuGroup}">
          ${this.globalHeaderLinkMixin.getLink({
            linkProps: {
              displayText: secondaryBackButtonLabel,
              openInNewWindow: false,
            },
            onClick: backButtonClicked,
            classes: clsx(navMobileBackButton, 'mobile-back-button'),
            datalayerVariant: this.$globalHeaderRef.datalayerVariant,
          })}
          ${this.renderMenuGroupsWithDivider(menuGroups)}
          ${this.globalHeaderLinkMixin.getLink({
            linkProps: {
              displayText: secondaryBackButtonLabel,
              openInNewWindow: false,
            },
            onClick: backButtonClicked,
            classes: clsx(navMobileBackButton, 'mobile-back-button', 'is-end'),
            datalayerVariant: this.$globalHeaderRef.datalayerVariant,
          })}
        </div>
      `,
    );
  }

  renderDropdownContainer(
    content: () => TemplateResult | TemplateResult[],
    footerContent?: () => TemplateResult | TemplateResult[],
  ) {
    const { secNav, secNavContainer } = useStyles.classes;

    return html`
      <div id="dropdown-container" class="${clsx(secNav, 'secNavMain')}">
        <div class="${clsx(secNavContainer, 'secNavContainer')}">${content()}</div>
        ${when(Boolean(footerContent), () => footerContent!())}
      </div>
    `;
  }
}
