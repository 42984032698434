import { isPathAbsolute } from './utils';

export type PropsWithPage<P> = P & { page?: Br.Page };

export const resolveBool = ({ rawVal }: any) => {
  return /boolean/.test(typeof rawVal) ? rawVal : /^on$/i.test(rawVal);
};

export type ReferenceProps = { rawVal?: Br.Reference };
export const resolveReference = <T = unknown>({
  rawVal,
  page,
}: PropsWithPage<ReferenceProps>): T | undefined => {
  const content = rawVal?.$ref ? page?.getContent(rawVal) : undefined;
  return content
    ? ({
        ...content.getData(),
        url: content.getUrl(),
      } as T)
    : undefined;
};

export type CompoundProps<P> = { rawVal?: P | P[] };
export const resolveCompound = <T = unknown>({ rawVal }: CompoundProps<T>) => {
  if (!rawVal) return {} as T;

  const [val] = Array.isArray(rawVal) ? rawVal : [rawVal];
  return val || ({} as T);
};

export type RTCProps = { rawVal?: { value: string } | { value: string }[] };
export const resolveRTC = ({ rawVal }: RTCProps) => resolveCompound({ rawVal })?.value || '';

export const getHref = ({ url = '', spaBaseUrl = '' }: { url?: string; spaBaseUrl?: string }) => {
  if (!url) return '';
  if (isPathAbsolute(url) || !spaBaseUrl) return url;

  return `${spaBaseUrl.replace(/\/$/, '')}/${url.replace(/^\//, '')}`;
};

export type LinkProps = { rawVal?: LinkCompound; spaBaseUrl?: string };

export const resolveLink = (props: PropsWithPage<LinkProps>) => {
  const { rawVal, page, spaBaseUrl = '' } = props;
  const {
    displayText,
    openInNewWindow,
    link: ref,
    url,
    buttonStyle,
  } = resolveCompound<LinkCompound>({
    rawVal,
  });
  const content = ref && page?.getContent(ref);
  const data = content?.getData();
  const rawUrl = url || content?.getUrl() || '';
  const href = getHref({ url: rawUrl, spaBaseUrl });
  let marketoLink: MarketoFormLink = {};

  if (data?.hasOwnProperty('marketoFormId')) {
    const component = page?.getComponent();
    const { 'marketoForms.config': marketoFormsConfig } = component?.getModels() || {};
    const { baseUrl, munchkinId } = marketoFormsConfig || {};

    marketoLink = {
      baseUrl,
      formId: data.marketoFormId,
      munchkinId,
    };
  }

  return { displayText, href, openInNewWindow, buttonStyle, marketoLink } as LinkType;
};

export type ImageReferenceProps = { rawVal?: Br.Reference };
export const resolveImageReference = ({
  rawVal,
  page,
  spaBaseUrl,
}: PropsWithPage<ImageReferenceProps & { spaBaseUrl: string }>) => {
  if (!rawVal) return undefined;

  const document = resolveReference<ImageDocType>({ rawVal, page });

  const resolveImageVariant = (img: Br.Image) => {
    const { width, size, height, _links: links } = img;
    return { width, size, height, url: getHref({ url: links.site.href, spaBaseUrl }) };
  };

  return {
    original: document && resolveImageVariant(document.original),
    thumbnail: document && resolveImageVariant(document.thumbnail),
  } as ImageType;
};

export type ImageProps = { rawVal?: ImageCompound };
export const resolveImage = ({
  rawVal,
  page,
  spaBaseUrl,
}: PropsWithPage<ImageProps & { spaBaseUrl: string }>) => {
  const { image: ref, ...props } = resolveCompound<ImageCompound>({ rawVal });
  const image = resolveImageReference({ rawVal: ref, page, spaBaseUrl });
  return image ? ({ ...props, ...image } as ImageType) : undefined;
};
