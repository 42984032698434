import { makeStyles2 } from '../../core';
import { dynamicArrow } from '../../shared/icons/arrow';
import { COLORS } from '../../shared/theme/palette';

const mobileBackButtonStyles = ({ typography, spacing, palette }: any) => ({
  mobileBackButton: {
    ...typography.subtitle2,
    fontSize: '14px',
    color: palette.secondary.color + ' !important',
    textDecoration: 'none',
    cursor: 'pointer',
    border: 'none',
    '&.is-end': {
      marginTop: spacing(3),
    },

    '& span:before': {
      backgroundImage: `url(${dynamicArrow(COLORS.blue1)})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      content: '""',
      color: palette.secondary.color,
      display: 'inline-block',
      height: '9px',
      lineHeight: '100%',
      marginTop: '-4px',
      marginLeft: '-4px',
      paddingRight: spacing(1),
      transform: 'rotate(-270deg)',
      verticalAlign: 'middle',
      width: '13px',
    },
  },
});

export default makeStyles2(mobileBackButtonStyles);
