import { LitElement } from 'lit';
import { query, state } from 'lit/decorators.js';

type Constructor<T = unknown> = new (...args: any[]) => T;

export declare class HeaderOptionMixinInterface {
  protected isSlottedOption: boolean; // use this in true if your content will be filled via slot in the gntc-global-header
  protected onScapeKeyup(): unknown;
  protected onOptionOutSideClick(): unknown;
}

export const HeaderOptionMixin = <T extends Constructor<LitElement>>(superClass: T) => {
  class HeaderOptionClass extends superClass {
    @query('gntc-menu-dropdown-content')
    dropdownContentReference?: HTMLElement;

    @state()
    isSlottedOption = false;

    private documentOutsideClick(event: any) {
      // [data-header-dropdown-content] for identify the dropdown content when we use a slot in gntc-global-header tag
      // use as ref slot="gnct-notifications-content-slot" example
      const isInDropdownContentClick = event?.target.closest('[data-header-dropdown-content]');
      if (!isInDropdownContentClick) {
        setTimeout(() => {
          this.onOptionOutSideClick();
        });
      }
    }

    private containerClick(event: any) {
      if (this.isSlottedOption) return;
      event.stopPropagation();
    }

    connectedCallback() {
      super.connectedCallback();
      document.addEventListener('keyup', (event) => {
        if (event.key === 'Escape') Promise.resolve().then(() => this.onScapeKeyup());
      });
      document.addEventListener('click', (event: any) => this.documentOutsideClick(event));

      // when the dropdown content is clicked we validate if the isSlottedOption is true to avoid the stopPropagation
      // This avoids native event outside the gntc-global-header elements
      setTimeout(() => {
        this.dropdownContentReference?.addEventListener('click', (event) =>
          this.containerClick(event),
        );
      });
    }

    disconnectedCallback() {
      super.disconnectedCallback();
      document.removeEventListener('keyup', (event) => {
        if (event.key === 'Escape') Promise.resolve().then(() => this.onScapeKeyup());
      });
      document.removeEventListener('click', () => {
        setTimeout(() => this.onOptionOutSideClick());
      });
      this.dropdownContentReference?.removeEventListener('click', (event) =>
        this.containerClick(event),
      );
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    protected onScapeKeyup() {}

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    protected onOptionOutSideClick() {}
  }
  // Cast return type to the superClass type passed in
  return HeaderOptionClass as unknown as T & Constructor<HeaderOptionMixinInterface>;
};
