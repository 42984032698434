const Z_INDEXES = {
  'mobile-stepper': 1000,
  'speed-dial': 1050,
  'app-bar': 1100,
  'secondary-nav-mobile': 1200,
  drawer: 1200,
  modal: 1300,
  snackbar: 1400,
  tooltip: 1500,
};

export default (
  elevation:
    | 'mobile-stepper'
    | 'speed-dial'
    | 'app-bar'
    | 'secondary-nav-mobile'
    | 'drawer'
    | 'modal'
    | 'snackbar'
    | 'tooltip',
) => Z_INDEXES[elevation];
