import { COLORS } from '../../shared/theme/palette';
import zIndex from '../../shared/theme/z-index';

export const getMainNavigationStyles = ({
  secondaryNavLinkStyles,
  typography,
  responsiveMaxWidth,
  mobileBreakpoint,
  spacing,
  responsive,
  palette,
  elevation,
  components,
}: any) => ({
  secNav: {
    '& .main-navigation-group-title.smallCaps': {
      fontWeight: '400',
      fontSize: '11px',
      lineHeight: '13.91px',
      color: '#C9CACB',
    },

    '& .menu-group-list': {
      zIndex: '1',
    },

    '& .full-width-menu-group': {
      width: '100%',
      padding: '20px',
      display: 'flex',
      justifyContent: 'center',

      '& .menu-group-section': {
        display: 'flex',
        justifyContent: 'center',
      },
    },

    '& .menu-items': {
      '& .filled': {
        marginBottom: spacing(2),
      },
      '& .filled:last-child': {
        marginBottom: 0,
      },

      '& .menu-item': {
        '&.filled': {
          width: '100%',
        },

        '& .menu-item-link': {
          '&.has-icon': {
            display: 'inline-flex',
            gap: spacing(2),
            alignItems: 'self-start',
            '& > img': {
              width: '24px',
              height: '24px',
              objectFit: 'contain',
            },
          },
        },
      },
    },

    [responsiveMaxWidth(mobileBreakpoint)]: {
      backgroundColor: palette.background.dark.color,
      left: '100%',
      listStyle: 'none',
      padding: spacing(1, 0, 3),
      width: '100%',
      position: 'fixed',
      top: '58px',
      transition: 'left 250ms ease-out, padding 250ms ease-out',
      zIndex: zIndex('secondary-nav-mobile'),
      '&.search-navigation-results': {
        left: 0,
        padding: 0,
        position: 'static',
      },
      '&.is-expanded': {
        height: 'calc(100% - 58px)',
        left: 0,
        overflow: 'auto',
      },
    },

    [responsive(mobileBreakpoint)]: {
      display: 'none',
      left: '0px',
      width: '100%',
      position: 'absolute',
      backgroundColor: palette.background.dark.color,
      listStyle: 'none',
      marginTop: 0,
      top: '58px',

      '&:before': {
        content: '""',
        height: '10%',
        left: 0,
        position: 'absolute',
        bottom: 0,
        width: '100%',
        zIndex: -1,
        boxShadow: elevation(4),
      },
    },
  },
  secNavContainer: {
    ...components.container.offset,
    boxSizing: 'border-box',
    paddingTop: spacing(5),
    paddingBottom: spacing(5),

    [responsiveMaxWidth(mobileBreakpoint)]: {
      padding: spacing(0, 4),
    },
  },
  secNavMenuGroupSectionIconLink: {
    ...secondaryNavLinkStyles,
    ...typography.headline6,
    fontWeight: 400,

    [responsiveMaxWidth(mobileBreakpoint)]: {
      fontSize: '18px',
    },
  },
  secNavMenuGroupSectionWrapIconLink: {
    display: 'flex',
    padding: spacing(0.75, 0),

    '& a': {
      alignItems: 'flex-start',
      alignContent: 'flex-start',
      minHeight: spacing(5.25),
      padding: spacing(1, 2),
      height: 'auto',
      textDecoration: 'none',
    },

    '& .text': {
      lineHeight: spacing(4),
    },

    '& img': {
      position: 'relative',
      marginLeft: spacing(-0.625),
      marginRight: spacing(1.625),
      width: spacing(4),
      height: spacing(4),
    },

    [responsive(mobileBreakpoint)]: {
      // margin: spacing(0, -2.125),
    },
  },
  secNavMenuGroup: {
    flex: '1 0 100%',
    display: 'flex',
    flexDirection: 'column',

    '& .color-divider': {
      '& span': {
        content: '""',
        position: 'absolute',
        right: 0,
        top: 0,
        bottom: 0,
      },
    },

    '& > *:not(:first-child):not(.empty-title-column), & > .empty-title-column.full-width-column': {
      [responsiveMaxWidth(mobileBreakpoint)]: {
        marginTop: spacing(2),
      },
    },

    [responsive(mobileBreakpoint)]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      margin: spacing(0, -1.5),

      '&.columns-1 > .column': {
        width: '100%',
        flexShrink: '0',
      },

      '&.columns-2 > .column': {
        width: '50%',
        flexShrink: '0',
      },

      '&.columns-3 > *:not(.no-column)': {
        width: '33.333333333%',
        flexShrink: '0',
      },

      '&.columns-4 > *:not(.no-column)': {
        width: 'calc(25% - 6.25px)',
        flexShrink: '0',
      },
    },
  },
  secNavMenuGroupList: {
    display: 'flex',
    flexDirection: 'column',

    '&.quick-links': {
      '& .variant-icon-link': {
        marginBottom: '20px',
      },
      '& .variant-icon-link:last-child': {
        marginBottom: 0,
      },
    },

    [responsive(mobileBreakpoint)]: {
      flexDirection: 'column',
      flexWrap: 'wrap',
      position: 'relative',
      padding: spacing(0, 1.5),
      maxWidth: '349px',
      flexGrow: '1',

      '&.promo-column': {
        maxWidth: '384px',
      },
    },
  },
  filledLink: {
    padding: '24px 20px',
    borderRadius: '4px',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '20.24px',
    color: COLORS.white,
    textDecoration: 'none',
    background: COLORS.charcol4,
    display: 'flex !important',
  },
});
