/**
 *  These function manage the logic to know when a menu is open to do something when it happens
 */

const GLOBAL_HEADER_MENU_TOGGLE = 'global-header:menu-toggle';
const CLOSE_GLOBAL_HEADER_MENU = 'global-header:close-menu';

type OnOtherMenuToggledCallback = ({
  menuToggled,
  toggleValue,
}: {
  menuToggled: HeaderMenus;
  toggleValue: boolean;
}) => void;
type onTargetMenuToggledCallback = ({ toggleValue }: { toggleValue: boolean }) => void;
type MenuToggle = (toggleValue: boolean) => void;
type ToggleTriggersOutput = [
  MenuToggle,
  (
    onOtherMenuToggled?: OnOtherMenuToggledCallback,
    onTriggerMenuToggled?: onTargetMenuToggledCallback,
  ) => void,
];

interface HeaderMenuToggleDetailEvent {
  triggerMenu: HeaderMenus; // Menu that triggers the toggle event
  toggleVisibilityValue: boolean; // The toggle event value
  externalTrigger: boolean; // If the toggle event was triggered by other menu to force to modify the current menu
}

/**
 * Menus identifiers
 */
export enum HeaderMenus {
  SearchNavigation = 'SearchNavigation',
  AppsSection = 'AppsSection',
  LoginSection = 'LoginSection',
  LanguageSection = 'LanguageSection',
  NotificationsSection = 'NotificationsSection',
  GlobalNavigation = 'GlobalNavigation',
}

export const emitCloseHeaderMenuEvent = () => {
  const event = new CustomEvent(CLOSE_GLOBAL_HEADER_MENU);
  document.dispatchEvent(event);
};

export const onCloseHeaderMenuEvent = (onCloseHeaderMenu: () => void) => {
  document.addEventListener(CLOSE_GLOBAL_HEADER_MENU, () => {
    if (onCloseHeaderMenu) {
      onCloseHeaderMenu();
    }
  });
};

const emitToggleEvent = (toggleEventDetail: HeaderMenuToggleDetailEvent) => {
  const event: CustomEvent<HeaderMenuToggleDetailEvent> = new CustomEvent(
    GLOBAL_HEADER_MENU_TOGGLE,
    { detail: toggleEventDetail },
  );
  document.dispatchEvent(event);
};

/**
 * @description To manipulate the toggle visibility of other menu
 */

export const toggleMenuVisibility = (targetMenu: HeaderMenus, toggleValue: boolean) => {
  emitToggleEvent({
    triggerMenu: targetMenu,
    toggleVisibilityValue: toggleValue,
    externalTrigger: true,
  });
};

/**
 * @description This function emit a event when the selected menu is being opened o closed
 */

const emitToggleVisibility = (triggerMenu: HeaderMenus) => (toggleValue: boolean) => {
  emitToggleEvent({ triggerMenu, toggleVisibilityValue: toggleValue, externalTrigger: false });
};

/**
 * @description This function listen when other menu is open to do something by the callback
 */
const addListenerOnMenuOptionToggle =
  (triggerMenu: HeaderMenus) =>
  (
    onOtherMenuToggleCallback?: OnOtherMenuToggledCallback,
    onMenuToggleCallback?: onTargetMenuToggledCallback,
  ) => {
    const onMenuToggle = ({ detail }: CustomEventInit<HeaderMenuToggleDetailEvent>) => {
      const isOtherMenuEvent = detail?.triggerMenu !== triggerMenu;
      // emit if other menu is toggled
      if (isOtherMenuEvent && onOtherMenuToggleCallback) {
        onOtherMenuToggleCallback({
          menuToggled: detail!.triggerMenu,
          toggleValue: detail!.toggleVisibilityValue,
        });
      }
      // emit if the registered menu is toggled
      if (onMenuToggleCallback && detail!.externalTrigger) {
        onMenuToggleCallback({ toggleValue: detail!.toggleVisibilityValue });
      }
    };

    document.addEventListener(GLOBAL_HEADER_MENU_TOGGLE, onMenuToggle);
  };

/**
 * @description This function can generate the 2 above functions with a default menu identifier
 * @param triggerMenu: Menu Identifier to generate the previous function but with default identifier
 */
export const generateHeaderMenuToggleTriggers = (
  triggerMenu: HeaderMenus,
): ToggleTriggersOutput => {
  const emitHeaderOptionToggle = emitToggleVisibility(triggerMenu);
  const addListenerOptionMenuToggle = addListenerOnMenuOptionToggle(triggerMenu);
  return [emitHeaderOptionToggle, addListenerOptionMenuToggle];
};
