import { curry } from './curry';
import { defaultTo } from './defaultTo';

function propOrFn(defaultValue: any, property: string, obj: any) {
  if (!obj) return defaultValue;

  return defaultTo(defaultValue, obj[property]);
}

export const propOr = curry(propOrFn);
