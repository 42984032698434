import { html, LitElement, PropertyValues } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { captureClicks } from '../../../core/utils';
import { SearchResultCategory } from '../gntc-search-navigation.interface';
import useGntcPartnerOnlyFilterStyles from './gntc-partner-only-filter.styles';

@customElement('gntc-partner-only-filter')
export class GntcPartnerOnlyFilter extends LitElement {
  static styles = useGntcPartnerOnlyFilterStyles().css;

  @property({ type: String, reflect: true })
  protected filterWildcardLabel = '';

  @property({ type: String, reflect: true })
  protected searchValue = '';

  @property({ type: String, reflect: true })
  protected customClasses = '';

  @property({ type: String, reflect: true })
  protected partnerOnlyIconUrl = '';

  @property({ type: String, reflect: true })
  protected baseSearchURL = '';

  @property({ type: Array, reflect: true })
  protected filtersOptions: SearchResultCategory[] = [];

  @property({ type: Boolean, reflect: true })
  protected isExternal = false;

  constructor() {
    super();
  }

  protected firstUpdated(_changedProperties: PropertyValues) {
    super.firstUpdated(_changedProperties);

    if (this.shadowRoot) {
      captureClicks({ context: this.shadowRoot, isExternal: this.isExternal });
    }
  }

  get filterLabel() {
    return this.filterWildcardLabel.replace('%1', this.searchValue);
  }

  protected onCategoryClick() {
    this.dispatchEvent(new CustomEvent('onCategoryClick'));
  }

  protected render(): unknown {
    const { classes } = useGntcPartnerOnlyFilterStyles();

    return html`
      <div class="${classes.root}">
        <p class="filter-label">${unsafeHTML(this.filterLabel)}</p>
        <p class="partner-only">
          <img src="${this.partnerOnlyIconUrl}" alt="" />
          <span>Partner Only</span>
        </p>
        <div class="filter-category-wrap">
          ${this.filtersOptions.map(
            (filterOption: SearchResultCategory) => html`
              <a
                href="${`${this.baseSearchURL}&category=${filterOption.name}`}"
                class="${classes.filterOption}"
                @click="${() => this.onCategoryClick()}"
              >
                <span>${filterOption.name}</span>
                ${filterOption.count &&
                html`<span class="${classes.filterOptionCount}">( ${filterOption.count} )</span>`}
              </a>
            `,
          )}
        </div>
      </div>
    `;
  }
}
