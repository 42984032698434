import typography from './typography';
import palette from './palette';
import responsive, { maxWidth } from './responsive';
import spacing from './spacing';
import elevation from './elevation';
import zIndex from './z-index';
import { components } from './components';

export type Theme = {
  typography: typeof typography;
  palette: typeof palette;
  responsive: typeof responsive;
  responsiveMaxWidth: typeof maxWidth;
  spacing: typeof spacing;
  elevation: typeof elevation;
  zIndex: typeof zIndex;
  mobileBreakpoint: Breakpoints;
  mode: Mode;
  components: any;
};

/**
 *
 * @deprecated since version 2.0
 */
export const createTheme = (
  _componetName: string,
  mobileBreakpoint: Breakpoints = 'sm',
  type: 'light' | 'dark' = 'dark',
) => {
  return {
    ...createTheme2(mobileBreakpoint, type),
    type,
  };
};

export const createTheme2 = (
  mobileBreakpoint: Breakpoints = 'sm',
  mode: 'light' | 'dark' = 'dark',
): Theme => {
  const theme = {
    typography,
    palette,
    responsive,
    responsiveMaxWidth: maxWidth,
    spacing,
    elevation,
    zIndex,
    mobileBreakpoint,
    mode,
  };

  return {
    ...theme,
    components: components(theme),
  };
};

const defaultTheme = createTheme2('md', 'dark');

export default defaultTheme;
