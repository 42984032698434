import { PropertyValues } from 'lit';
import { customElement } from 'lit/decorators.js';
import { captureClicks } from '../core/utils';
import { GlobalFooterBase } from './gntc-global-footer-base';

@customElement('gntc-global-footer')
class GlobalFooter extends GlobalFooterBase {
  protected firstUpdated(_changedProperties: PropertyValues) {
    super.firstUpdated(_changedProperties);

    if (this.shadowRoot) {
      captureClicks({
        context: this.shadowRoot,
        isExternal: this.isExternal,
      });

      document.addEventListener('click', () => this.rootElement?.classList.remove('a11y'));
      document.addEventListener('keydown', (e: KeyboardEvent) => {
        const isTab = /^tab$/i.test(e.key);

        if (isTab) {
          this.rootElement?.classList.add('a11y');
        }
      });
    }
  }
}

export default GlobalFooter;

declare global {
  interface HTMLElementTagNameMap {
    'gntc-global-footer': GlobalFooter;
  }
}
