import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { styleMap } from 'lit/directives/style-map.js';
import { COLORS } from '../../shared/theme/palette';
import useHorizontalDividerStyles from './gntc-horizontal-divider.styles';

@customElement('gntc-horizontal-divider')
export class GntcVerticalDivider extends LitElement {
  static styles = useHorizontalDividerStyles().css;

  @property({ type: String, reflect: true })
  protected color = COLORS.eggshell;

  @property({ type: String, reflect: true })
  protected spacingLevel = '1';

  @property({ type: Boolean, reflect: true })
  protected includeOpacity = true;

  protected render(): unknown {
    const classes = useHorizontalDividerStyles().classes;
    return html`
      <div
        class="${classMap({
          [classes.horizontalDividerWrapper]: true,
          [classes.spacing1]: this.spacingLevel === '1',
          [classes.spacing2]: this.spacingLevel === '2',
          [classes.spacing3]: this.spacingLevel === '3',
          [classes.spacing4]: this.spacingLevel === '4',
        })}"
      >
        <div
          class="${classMap({
            horizontalDivider: true,
            [classes.includeOpacity]: this.includeOpacity,
          })}"
          style="${styleMap({
            background: this.color,
          })}"
        ></div>
      </div>
    `;
  }
}
