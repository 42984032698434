const BASE = {
  fontStyle: 'normal',
  fontVariant: 'normal',
  fontWeight: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
};

const PRIMARY_FONT_FAMILY = window.primaryFontFamily || 'Circular, helvetica, arial, sans-serif';

const SECONDARY_FONT_FAMILY =
  window.secondaryFontFamily || 'Garamond Premier Pro, Times New Roman, serif';

const PRIMARY = {
  ...BASE,
  fontFamily: PRIMARY_FONT_FAMILY,
};

const SECONDARY = {
  ...BASE,
  fontFamily: SECONDARY_FONT_FAMILY,
};

const headline1 = {
  ...PRIMARY,
  fontSize: '60px',
  fontWeight: 'bold',
  lineHeight: '0.98',
  letterSpacing: '-0.5px',
};

const headline2 = {
  ...SECONDARY,
  fontSize: '56px',
};

const headline3 = {
  ...PRIMARY,
  fontSize: '42px',
  fontWeight: '500',
  lineHeight: '1.1',
};

const headline4 = {
  ...SECONDARY,
  fontSize: '36px',
  fontWeight: '500',
  lineHeight: '1.28',
};

const headline5 = {
  ...PRIMARY,
  fontSize: '22px',
  fontWeight: 'bold',
  lineHeight: '1.55',
};

const headline6 = {
  ...PRIMARY,
  fontSize: '22px',
  fontWeight: '500',
  lineHeight: '1.55',
};

const subtitle1 = {
  ...PRIMARY,
  fontSize: '15px',
  lineHeight: '2.27',
  letterSpacing: '0.2px',
};

const subtitle2 = {
  ...PRIMARY,
  fontSize: '15px',
  fontWeight: '500',
  lineHeight: '2.27',
  letterSpacing: '0.2px',
};

const body1 = {
  ...PRIMARY,
  fontSize: '15px',
  lineHeight: '1.73',
};

const body2 = {
  ...PRIMARY,
  fontSize: '13px',
  lineHeight: '1.69',
  letterSpacing: '0.17px',
};

const button = {
  ...PRIMARY,
  fontSize: '15px',
  fontWeight: '500',
  letterSpacing: '0.2px',
};

const overline = {
  ...PRIMARY,
  fontSize: '12px',
  fontWeight: '500',
  letterSpacing: '1px',
};

const caption = {
  ...PRIMARY,
  fontSize: '12px',
};

const TYPES: any = {
  headline1,
  headline2,
  headline3,
  headline4,
  headline5,
  headline6,
  subtitle1,
  subtitle2,
  body1,
  body2,
  button,
  overline,
  caption,
};

const getTypeProps = (type: string) => ({
  [type]: {
    ...Object.keys(TYPES[type])
      .map((prop) => ({
        [prop]: TYPES[type][prop],
      }))
      .reduce((a: any, b: any) => ({ ...a, ...b }), {}),
  },
});

const typography = {
  ...Object.keys(TYPES)
    .map((type) => getTypeProps(type))
    .reduce((a: any, b: any) => ({ ...a, ...b }), {}),
  fontWeightBook: 400,
  fontWeightMedium: 500,
  fontWeightBold: 'bold',
  fontWeightBlack: 900,
};

export default typography;

declare global {
  interface Window {
    primaryFontFamily: string;
    secondaryFontFamily: string;
  }
}
