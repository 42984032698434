import jss, { Styles as StyleRules } from 'jss';
import preset from 'jss-preset-default';
import { unsafeCSS } from 'lit';
import theme, { Theme as DefaultTheme } from '../shared/theme';

jss.setup(preset());

/**
 * @deprecated since version 2.0
 */
export const makeStyles = (styles: any) => jss.createStyleSheet(styles);

export type StyleRulesCallback<Theme> = (theme: Theme) => StyleRules;

export type Styles = StyleRules | StyleRulesCallback<DefaultTheme>;

export const createStyles = (styles: any) => styles as StyleRules;

export const makeStyles2 = (styles: Styles) => {
  const stylesheet = jss.createStyleSheet(styles instanceof Function ? styles(theme) : styles);
  return () => ({
    css: unsafeCSS(stylesheet.toString()),
    classes: stylesheet.classes,
  });
};
