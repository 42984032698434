import { makeStyles } from '../core';
import { createTheme } from '../shared';
import zIndex from '../shared/theme/z-index';
import { dynamicArrow } from '../shared/icons/arrow';
import { COLORS } from '../shared/theme/palette';

import getLoginContentStyles from './login-section/components/gntc-login-content.styles';
import getLoginSectionStyles from './login-section/gntc-login-section.styles';
import { animateStyles } from '../shared/animate';
import { getMainNavigationStyles } from './main-navigation/gntc-main-navigation.styles';

export const getStyles = (themeType: 'light' | 'dark') => {
  const {
    typography,
    palette,
    responsive,
    responsiveMaxWidth,
    spacing,
    elevation,
    components,
    mobileBreakpoint,
  }: any = createTheme('globalHeader', 'md', themeType);

  const globalStyles = {
    ':host': {
      display: 'block',
      position: 'relative',
      zIndex: zIndex('app-bar'),

      '& *': { boxSizing: 'border-box' },

      '& .rte': {
        ...components.rte,
      },
    },
  };

  const rootSyles = {
    ...typography.body1,
    fontSmoothing: 'antialiased',

    '&.is-open': {
      display: 'block',
    },
    ...animateStyles(),

    '&:not(.a11y) *:focus': {
      outline: 'none !important',
    },

    '&.is-height-auto': {
      height: '58px',
    },

    '&.is-active': {
      [responsive(mobileBreakpoint)]: {
        transition: 'height 250ms ease-in-out, margin-top 250ms ease-in-out',
      },
    },

    '&.is-transparent': {
      [responsive(mobileBreakpoint)]: {
        '&.is-height-auto': {
          height: '100px',
        },
      },
    },
  };

  const ribbonStyles = {
    transition: 'height 250ms ease-in-out',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'flex-end',
    alignContent: 'flex-end',

    '& ::slotted(*)': {
      width: '100%',
    },
  };

  const headerContentStyles = {
    position: 'relative',
    '&.is-sticky': {
      position: 'fixed',
      left: 0,
      top: 0,
      width: '100%',
    },
  };

  const headerStyles = {
    alignContent: 'center',
    alignItems: 'center',
    color: palette.background.dark.text,
    display: 'flex',
    justifyContent: 'space-between',
    zIndex: 2,
    height: '58px',

    '&.is-active': {
      [responsive(mobileBreakpoint)]: {
        transition: 'height 250ms ease-in-out',
      },

      '&:not(.is-transparent)': {
        transition:
          'background-color 250ms ease-in-out, height 250ms ease-in-out, box-shadow 250ms ease-in-out',
      },

      '&.is-open, &.is-expanded': {
        transition: 'height 250ms ease-in-out, box-shadow 250ms ease-in-out',
      },
    },

    '&.is-transparent': {
      [responsive(mobileBreakpoint)]: {
        height: '100px',
      },
    },

    '&:not(.is-transparent)': {
      backgroundColor: palette.background.dark.color,

      '&:not(.has-progress)': {
        boxShadow: elevation(4),
        [responsive('md')]: {
          boxShadow: 'unset',
          '&:before': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: '10px',
            zIndex: 2,
            boxShadow:
              'rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 6px 10px 0px',
          },
        },
      },
    },

    '&.is-open, &.is-expanded': {
      backgroundColor: palette.background.dark.color,
    },
  };

  const headerProgressStyles = {
    position: 'absolute',
    bottom: '0',
    width: '100%',
    height: '4px',
    left: 0,
    transition: 'bottom 250ms ease-out',
    // boxShadow: elevation(4),

    '&.is-visible': {
      bottom: '-4px',

      '& .header-progress-background': {
        '&:not(.is-transparent)': {
          backgroundColor: palette.background.dark.color,
        },
      },
    },

    '& .header-progress-background': {
      opacity: 0.8,
      height: '100%',
      width: '100%',
      transition: 'bottom 250ms ease-out, background-color 250ms ease-out',
    },

    '& .header-progress-bar': {
      // background: `linear-gradient(to right, ${palette.secondary.color} var(--scroll), transparent 0)`,
      backgroundColor: palette.secondary.color,
      transition: 'width 150ms linear',
      // backgroundRepeat: 'no-repeat',
      position: 'absolute',
      width: '0',
      height: '100%',
      top: 0,
      zIndex: 1,

      '&.is-loading': {
        animationName: '$loading',
        animationDuration: '1.5s',
        animationFillMode: 'backwards',
        animationTimingFunction: 'ease-in-out',
        animationIterationCount: 'infinite',
      },
    },
  };

  const headerContainerStyles = {
    ...components.container.offset,
    alignItems: 'center',
    alignContent: 'center',
    zIndex: 1,

    '& > *': {
      flexShrink: 0,
    },
  };

  const spacerStyles = {
    flexGrow: 1,
  };

  // Desktop

  const navStyles = {
    display: 'none',

    [responsive(mobileBreakpoint)]: {
      display: 'flex',
    },
  };

  const navListStyles = {
    listStyle: 'none',
    paddingLeft: 0,
    display: 'flex',
    margin: 0,

    '& > li': {
      '&:not(:first-child)': {
        [responsive(mobileBreakpoint)]: {
          marginLeft: '3px',
        },
      },

      '& .is-expanded, & .secNavMain:focus-within, & .secNavMain:hover': {
        [responsive(mobileBreakpoint)]: {
          display: 'block',
        },
      },

      '& .is-expanded .secNavContainer': {
        [responsive(mobileBreakpoint)]: {
          animationName: '$enter',
          animationDuration: '250ms',
          animationFillMode: 'backwards',
          animationTimingFunction: 'ease-out',
        },
      },

      '&:focus-within .actionButton, &:hover .actionButton': {
        '&:after': {
          opacity: 1,
          width: `calc(100% - ${spacing(3.5)})`,
        },
      },
    },
  };

  const linkStyles = {
    ...typography.body2,
    fontWeight: typography.fontWeightMedium,
    alignContent: 'center',
    alignItems: 'center',
    background: 'transparent',
    border: 'none',
    display: 'inline-flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: spacing(0, 1.75, 0.125),
    textDecoration: 'none',
    cursor: 'pointer',
    borderRadius: '4px',
    position: 'relative',
    minHeight: '36px',

    '& span': {
      position: 'relative',
      marginBottom: '-1px',
    },

    '&:before': {
      backgroundColor: '#000000',
      borderRadius: '4px',
      content: '""',
      height: '100%',
      left: 0,
      opacity: 0,
      position: 'absolute',
      top: 0,
      transition: 'opacity 150ms ease-in-out',
      width: '100%',
    },

    '&:not(:hover), &:hover': {
      color: 'inherit',
    },

    '&:hover, &:focus': {
      '&:before': {
        opacity: 0.15,
      },
    },
  };

  const navLinkStyles = {
    ...typography.body2,
    ...linkStyles,
    zIndex: 1,

    '&:after': {
      content: '""',
      backgroundColor: palette.secondary.color,
      opacity: 0,
      transition: 'opacity 150ms ease-in-out, width 250ms ease-in-out',
      height: '2px',
      width: '0%',
      bottom: '-10px',
      left: '50%',
      position: 'absolute',
      transform: 'translateX(-50%)',
    },
  };

  const buttonLinkStyles = {
    ...components.button.small.outlined,
    [responsive(mobileBreakpoint)]: {
      ...components.button.small.outlined[responsive(mobileBreakpoint)],
      margin: spacing(0, 1.75, 0, 0.75),
    },
  };

  const campaignButtonsStyles = {
    order: '1',
  };

  const iconButtonStyles = {
    ...linkStyles,
    justifyContent: 'center',
    padding: 0,
    width: '36px',

    '& > img': {
      position: 'relative',
      maxWidth: '24px',
    },

    '&:before': {
      ...linkStyles['&:before'],
      borderRadius: '18px',
    },
  };

  const hamburgerMenuButtonStyles = {
    ...iconButtonStyles,
    marginRight: spacing(2.625),
    marginLeft: spacing(-0.5),
    cursor: 'pointer',
    height: '36px',
    position: 'relative',
    margin: '0 auto',
    transition: '250ms ease-in-out',
    width: '36px',

    '&:before': linkStyles['&:before'],

    '& > span': {
      display: 'block',
      position: 'absolute',
      height: '1px',
      width: '28px',
      left: '4px',
      background: 'currentColor',
      borderRadius: '9px',
      opacity: 1,
      margin: spacing(0.5, 0),
      transition: '.20s ease-in-out',
      transformOrigin: 'left center',

      '&:nth-child(1)': {
        top: '10px',
      },

      '&:nth-child(2)': {
        top: '18px',
      },
    },

    '&.is-campaign': {
      marginRight: spacing(1),
    },

    '&.is-open > span': {
      '&:nth-child(1)': {
        transform: 'rotate(45deg)',
        top: '3px',
        left: '8px',
      },

      '&:nth-child(2)': {
        transform: 'rotate(-45deg)',
        top: '23px',
        left: '8px',
      },
    },

    [responsive(mobileBreakpoint)]: {
      display: 'none',
    },
  };

  const selectorButtonStyles = {
    ...linkStyles,
    textTransform: 'capitalize',
    flexDirection: 'row',
    marginRight: spacing(-1.75),

    '&:not(.a11y):focus': {
      outline: 'none !important',
    },

    '&[aria-expanded="true"]': {
      color: palette.secondary.color,

      '& > svg': {
        transform: 'rotate(180deg)',
      },
    },

    '& > svg': {
      transition: 'transform 50ms ease-in-out',
      marginLeft: spacing(0.875),
    },
  };

  const altNavStyles = {
    // position: 'relative',
    alignItems: 'center',
    alignContent: 'center',
    display: 'none',
    order: '2',

    [responsive(mobileBreakpoint)]: {
      display: 'flex',
    },
  };

  const logoStyles = {
    width: '76px',

    [responsive(mobileBreakpoint)]: {
      alignSelf: 'flex-start',
      marginTop: spacing(0.875),
      width: 'auto',
    },
  };

  const logoWrapper = {
    display: 'flex',
    alignItems: 'center',

    [responsive(mobileBreakpoint)]: {
      display: 'initial',
    },
  };

  const secNavMenuGroupListVerticalDividerStyles = {
    [responsive(mobileBreakpoint)]: {
      flexGrow: 1,
      width: 'auto !important',
      padding: spacing(0, 1.5),

      '& div.verticalDivider': {
        margin: '0 36%',
        height: '100%',
        width: '1px',
        background: palette.background.dark.text,
        opacity: 0.38,
      },
    },
  };

  const secondaryNavigationMenuGroupSectionStyles = {};

  const secondaryNavLinkStyles = {
    ...linkStyles,
    alignItems: 'center',
    alignContent: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    padding: spacing(0.5, 1.75),
    margin: spacing(0, -1.75),
    position: 'relative',

    '&:not(a)': {
      cursor: 'default',

      '&:after, &:before': {
        display: 'none',
      },
    },

    '& span': {
      marginBottom: 0,
      position: 'relative',
    },

    '&:hover': {
      '&:after': {
        opacity: 1,
        height: `calc(100% - ${spacing(2)})`,
      },
    },

    '&:after': {
      content: '""',
      backgroundColor: palette.secondary.color,
      opacity: 0,
      transition: 'opacity 150ms ease-in-out, height 250ms ease-in-out',
      width: '1px',
      height: '0%',
      top: '50%',
      left: '0',
      position: 'absolute',
      transform: 'translateY(-50%)',
    },
  };

  const secondaryNavigationMenuGroupSectionTitleStyles = {
    '&:not(.is-smallCaps):not(.full-width-menu-group .menu-group-title)': {
      [responsive(mobileBreakpoint)]: {
        paddingBottom: 0,
        // marginTop: '-45px',
      },

      [responsiveMaxWidth(mobileBreakpoint)]: {
        marginBottom: '12px',
      },
    },

    [responsive(mobileBreakpoint)]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      paddingBottom: spacing(1),
    },
  };

  const secNavMenuGroupSectionImageStyles = {
    padding: spacing(4),
    width: '100%',
    height: 'auto',
    marginBottom: spacing(2.75),
    borderRadius: '4px',
    textDecoration: 'none',
    backgroundSize: 'cover',

    [responsive(mobileBreakpoint)]: {
      padding: spacing(5),
    },
  };

  const secNavMenuGroupSectionPromoStyles = {
    padding: spacing(4),
    width: '100%',
    height: 'auto',
    marginBottom: spacing(2.75),
    borderRadius: '4px',
    textDecoration: 'none',
    backgroundSize: 'cover',

    [responsive(mobileBreakpoint)]: {
      padding: spacing(5),
    },
  };

  //Language
  const languageButtonContainerStyles = {
    position: 'relative',
    minWidth: '60px',
    marginRight: '-14px',
  };

  const languageContainerStyles = {
    position: 'absolute',
    display: 'none',
    top: '47px',
    backgroundColor: palette.background.dark.color,
    color: palette.background.dark.text,
    right: 0,
    marginRight: '14px',
    width: '175px',
    padding: spacing(3, 2, 2.25),
    boxShadow: elevation(4),

    '&.is-expanded': {
      display: 'block',
    },
    ...animateStyles(),
  };

  const languageTitleStyles = {
    ...typography.overline,
    padding: spacing(0, 2),
    marginTop: 0,
    textTransform: 'uppercase',
  };

  const languageListStyles = {
    listStyle: 'none',
    padding: 0,
    margin: 0,
  };

  const languageItemLinkStyles = {
    ...linkStyles,
    ...typography.body1,
    fontSize: '14px',
    padding: spacing(0, 2),
    alignItems: 'flex-start',
    color: palette.background.dark.text,
    opacity: 0.8,

    '&:hover, &:focus, &.selected': {
      opacity: 1,
    },

    '&.selected': {
      fontWeight: 500,
    },
  };

  // Mobile
  const navMobileStyles = {
    ...components.container.offset,
    display: 'block',
    color: palette.background.dark.text,
    left: 0,
    height: 'auto',
    maxHeight: 'calc(100% - 58px)',
    padding: spacing(2.5, 2.5, 4),
    width: '100%',
    zIndex: 2,
    transition: 'all 250ms ease-in-out',

    '&:before': {
      boxShadow: elevation(4),
      width: '100%',
      height: '50%',
      bottom: 0,
      content: '""',
      display: 'block',
      left: 0,
      zIndex: -1,
      position: 'absolute',
    },

    '&:after': {
      background: palette.background.dark.color,
      width: '100%',
      height: '100%',
      top: 0,
      content: '""',
      display: 'block',
      left: 0,
      zIndex: 0,
      position: 'absolute',
    },

    '&:not(.is-open)': {
      display: 'none',
    },

    [responsive(mobileBreakpoint)]: {
      display: 'none',
    },
  };

  const navMobileListStyles = {
    listStyle: 'none',
    margin: 0,
    padding: 0,
  };

  const navMobileLinkStyles = {
    ...typography.body2,
    ...linkStyles,
    fontSize: '18px',
    margin: spacing(1.5, 0),
    zIndex: 1,

    '& span:after': {
      backgroundImage: `url(${dynamicArrow(COLORS.white)})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      content: '""',
      color: palette.secondary.color,
      display: 'inline-block',
      height: '7px',
      lineHeight: '100%',
      paddingLeft: spacing(1),
      transform: 'rotate(-90deg)',
      verticalAlign: 'middle',
      width: '10px',
    },

    '&:after': {
      content: '""',
      backgroundColor: palette.secondary.color,
      opacity: 0,
      transition: 'opacity 150ms ease-in-out, width 250ms ease-in-out',
      height: '1px',
      width: '0%',
      bottom: 0,
      left: '50%',
      position: 'absolute',
      transform: 'translateX(-50%)',
    },
  };

  const navMobileBackButtonStyles = {
    ...secondaryNavLinkStyles,
    ...typography.subtitle2,
    fontSize: '14px',
    color: palette.secondary.color + ' !important',

    '&.is-end': {
      marginTop: spacing(3),
    },

    '& span:before': {
      backgroundImage: `url(${dynamicArrow(COLORS.blue1)})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      content: '""',
      color: palette.secondary.color,
      display: 'inline-block',
      height: '9px',
      lineHeight: '100%',
      marginTop: '-4px',
      marginLeft: '-4px',
      paddingRight: spacing(1),
      transform: 'rotate(-270deg)',
      verticalAlign: 'middle',
      width: '13px',
    },
  };

  const campaignMobileStyles = {
    display: 'flex',
    flexDirection: 'row',
    padding: '0 10px',

    '& .spacer': {
      flexGrow: '1',
    },
  };

  const navMobileFormStyles = {
    padding: spacing(0, 1.5),
    position: 'relative',
    zIndex: 1,
  };

  const navMobileActionsStyles = {
    display: 'flex',
    position: 'relative',
    zIndex: 1,
    width: '100%',
    justifyContent: 'space-between',
    padding: '0 14px 20px',
  };

  return {
    '@global': globalStyles,
    '@keyframes enter': {
      '0%': { opacity: 0 },
      '100%': { opacity: 1 },
    },
    '@keyframes loading': {
      '0%': { width: '0%', left: '0%' },
      '50%': { left: '0%', width: '100%' },
      '100%': { left: '100%', width: '0%' },
    },
    root: rootSyles,
    header: headerStyles,
    ribbon: ribbonStyles,
    headerContent: headerContentStyles,
    headerProgress: headerProgressStyles,
    headerContainer: headerContainerStyles,
    navMobile: navMobileStyles,
    navMobileList: navMobileListStyles,
    navMobileLink: navMobileLinkStyles,
    navMobileBackButton: navMobileBackButtonStyles,
    navMobileForm: navMobileFormStyles,
    navMobileActions: navMobileActionsStyles,
    nav: navStyles,
    navList: navListStyles,
    navLink: navLinkStyles,
    buttonLink: buttonLinkStyles,
    selectorButton: selectorButtonStyles,
    iconButton: iconButtonStyles,
    menuButton: hamburgerMenuButtonStyles,
    spacer: spacerStyles,
    altNav: altNavStyles,
    logo: logoStyles,
    logoWrapper,
    secNavMenuGroupSection: secondaryNavigationMenuGroupSectionStyles,
    secNavMenuGroupSectionTitle: secondaryNavigationMenuGroupSectionTitleStyles,
    secNavMenuGroupSectionPromo: secNavMenuGroupSectionPromoStyles,
    secNavMenuGroupSectionImage: secNavMenuGroupSectionImageStyles,
    secNavMenuGroupListVerticalDivider: secNavMenuGroupListVerticalDividerStyles,
    languageContainer: languageContainerStyles,
    languageButtonContainer: languageButtonContainerStyles,
    languageTitle: languageTitleStyles,
    languageList: languageListStyles,
    languageItemLink: languageItemLinkStyles,
    campaignButtons: campaignButtonsStyles,
    campaignMobile: campaignMobileStyles,
    ...getMainNavigationStyles({
      secondaryNavLinkStyles,
      typography,
      responsiveMaxWidth,
      mobileBreakpoint,
      spacing,
      responsive,
      palette,
      elevation,
      components,
    }),
    ...getLoginContentStyles({
      responsive,
      mobileBreakpoint,
      spacing,
      palette,
      typography,
      responsiveMaxWidth,
      secondaryNavLinkStyles,
      components,
    }),
    ...getLoginSectionStyles({ spacing, responsiveMaxWidth, mobileBreakpoint, palette, elevation }),
  };
};

export const linkHoverStyle = () => ({
  position: 'relative',
  '& > * ': {
    zIndex: 1,
  },
  '&:after': {
    backgroundColor: '#000000',
    borderRadius: '4px',
    content: '""',
    height: '100%',
    left: 0,
    opacity: 0,
    position: 'absolute',
    top: 0,
    transition: 'opacity 150ms ease-in-out',
    width: '100%',
  },
  '&:hover, &:focus': {
    '&:after': {
      opacity: 0.15,
    },
  },
});

const useStyles = makeStyles(getStyles('dark'));

export const useGlobalHeaderStyles = () => {
  return useStyles;
};

export default useStyles;
