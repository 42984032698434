import { LitElement, PropertyValues, TemplateResult, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { captureClicks } from '../../../../core/utils';
import { MenuGroupSection, MenuItem } from '../../../gntc-global-header.interface';
import useAppServicesStyles from './gntc-app-services.styles';
@customElement('gntc-app-services')
export class GntcAppServices extends LitElement {
  static styles = useAppServicesStyles().css;

  protected firstUpdated(_changedProperties: PropertyValues) {
    super.firstUpdated(_changedProperties);

    Promise.resolve().then(() => {
      if (this.shadowRoot) {
        captureClicks({ context: this.shadowRoot });
      }
    });
  }

  @property({ type: Array, reflect: true })
  protected appServices: any[] = [];

  @property({ type: String, reflect: false })
  datalayerVariant?: string = '';

  protected getMenuGroupList({
    menuGroupList = [],
  }: {
    menuGroupList: MenuGroupSection[];
    verticalDivider: boolean;
    isLastItem: boolean;
  }) {
    const { secNavMenuGroupList: secNavMenuGroupListClass } = useAppServicesStyles().classes;
    const secNavMenuGroupListClasses = classMap({
      [secNavMenuGroupListClass]: true,
      secNavMenuGroupList: true,
    });
    return html`
      <div class="${secNavMenuGroupListClasses}">
        ${menuGroupList
          ? menuGroupList?.map((menuGroupSection) => this.getMenuGroupSection(menuGroupSection))
          : undefined}
      </div>
    `;
  }

  protected getMenuItemStyleClass(style = 'standard') {
    const {
      secondaryNavStandardLink: secondaryNavStandardLinkClass,
      secondaryNavHighlightLink: secondaryNavHighlightLinkClass,
      secondaryNavButtonLink: secondaryNavButtonLinkClass,
    } = useAppServicesStyles().classes;
    const linkClasses: any = {
      standard: secondaryNavStandardLinkClass,
      highlighted: secondaryNavHighlightLinkClass,
      button: secondaryNavButtonLinkClass,
    };

    return style && Object.keys(linkClasses).includes(style)
      ? linkClasses[style]
      : secondaryNavStandardLinkClass;
  }

  protected getMenuItem(menuItem: MenuItem) {
    const { link, linkStyle } = menuItem;
    const linkClass = this.getMenuItemStyleClass(linkStyle);
    const navLinkClasses = classMap({
      [linkClass]: true,
      standard: true,
    });
    const isRegisterLink = linkStyle?.includes('highlighted');
    const datalayerScenario = isRegisterLink ? 'register' : '';

    const handleGTM = (e: MouseEvent) => {
      const target = e.target as HTMLAnchorElement;
      const ctaEvent = new CustomEvent('gtm:click:cta', {
        bubbles: true,
        composed: true,
        detail: { target },
      });
      this.dispatchEvent(ctaEvent);
    };

    return html`
      <li role="none" class="${linkStyle || 'standard'} secNavItem ">
        ${link
          ? this.getLink({
              linkProps: link,
              classes: navLinkClasses,
              datalayerScenario,
              onClick: handleGTM,
            })
          : undefined}
      </li>
    `;
  }

  protected getMenuItems(menuItems: MenuItem[]) {
    const { secNavMenuGroupSectionItems: secNavMenuGroupSectionItemsClass } =
      useAppServicesStyles().classes;
    const secNavMenuGroupSectionItemsClasses = classMap({
      [secNavMenuGroupSectionItemsClass]: true,
    });
    return html`
      <ul class="${secNavMenuGroupSectionItemsClasses}" role="menu">
        ${menuItems ? menuItems?.map((menuItem) => this.getMenuItem(menuItem)) : undefined}
      </ul>
    `;
  }

  protected getColumnTitleDescription(menuDescription: string) {
    const { secNavMenuItemDescription: secNavMenuItemDescriptionClass } =
      useAppServicesStyles().classes;
    const secNavMenuItemDescriptionClasses = classMap({
      [secNavMenuItemDescriptionClass]: true,
    });
    return html`
      <div class="${secNavMenuItemDescriptionClasses}">${unsafeHTML(menuDescription)}</div>
    `;
  }

  protected getMenuGroupSection(menuGroupSection: MenuGroupSection) {
    const { primaryNavigationColumnTitle, menuItem, columnTitleDescription } = menuGroupSection;
    const { secNavMenuGroupSectionTitle: secNavMenuGroupSectionTitleClass } =
      useAppServicesStyles().classes;

    const secNavMenuGroupSectionTitleClasses = classMap({
      [secNavMenuGroupSectionTitleClass]: true,
      secNavMenuGroupSectionTitle: true,
    });
    return html`
      <div class="">
        <div class="${secNavMenuGroupSectionTitleClasses}">
          ${primaryNavigationColumnTitle
            ? this.getColumnTitleLink(primaryNavigationColumnTitle)
            : undefined}
        </div>
        ${primaryNavigationColumnTitle
          ? this.getColumnTitleDescription(columnTitleDescription || '')
          : undefined}
        ${this.getMenuItems(Array.isArray(menuItem) ? menuItem : [menuItem])}
      </div>
    `;
  }

  protected getLink({
    linkProps,
    classes,
    icon,
    onClick,
    datalayerScenario,
  }: {
    linkProps: LinkType;
    classes: ReturnType<typeof classMap>;
    icon?: TemplateResult;
    onClick?: (e: MouseEvent) => void;
    datalayerScenario?: string;
  }) {
    const { displayText, openInNewWindow, href } = linkProps;

    if (!displayText || displayText.trim().length === 0) return;

    if (!href) {
      return html`
        <span class="${classes}" @click=${(e: MouseEvent) => onClick && onClick(e)}>
          ${icon}
          <span class="text">${displayText}</span>
        </span>
      `;
    }

    // The datalayer scenario only finds the text "Register" then through navigation gets its title (portal/stratocast).
    return html`
      <a
        data-datalayer-scenario=${ifDefined(datalayerScenario ? datalayerScenario : undefined)}
        data-datalayer-variant=${ifDefined(
          this.datalayerVariant ? this.datalayerVariant : undefined,
        )}
        data-datalayer-link-type="cta"
        class="${classes}"
        tabindex="0"
        href="${href}"
        target="${openInNewWindow ? '_blank' : '_self'}"
        @click=${(e: MouseEvent) => onClick && onClick(e)}
      >
        ${icon}
        <span class="text">${displayText}</span>
      </a>
    `;
  }

  protected getColumnTitleLink(menuLink: LinkType) {
    const { secNavMenuGroupSectionTitleLink: secNavMenuGroupSectionTitleLinkClass } =
      useAppServicesStyles().classes;
    const secNavMenuGroupSectionTitleLinkClasses = classMap({
      [secNavMenuGroupSectionTitleLinkClass]: true,
      secNavMenuGroupSectionTitleLink: true,
      standard: true,
    });
    return this.getLink({ linkProps: menuLink, classes: secNavMenuGroupSectionTitleLinkClasses });
  }

  protected render(): unknown {
    const classes = useAppServicesStyles().classes;
    return html`
      <div class="${classes.appServicesWrapper}">
        ${this.appServices
          ? this.appServices?.map((menuGroup, index) =>
              this.getMenuGroupList({
                menuGroupList: menuGroup.menuGroupList,
                verticalDivider: menuGroup.verticalDivider,
                isLastItem: this.appServices.length - 1 === index,
              }),
            )
          : undefined}
      </div>
    `;
  }
}
