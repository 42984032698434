import { makeStyles2 } from '../../../../core';
import { avatarStyles, sizeStyles } from '../gntc-avatar.styles';

const gntcAvatarInitialsStyles = () => ({
  initialsAvatar: {
    backgroundColor: 'black !important',
    position: 'relative',
  },
  initialsAvatarBackground: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0.7,
  },
  initials: {
    zIndex: 1,
    position: 'relative',
  },
  ...avatarStyles,
  ...sizeStyles,
});

export default makeStyles2(gntcAvatarInitialsStyles);
