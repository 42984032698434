import { makeStyles2 } from '../../core';
import { Styles } from 'jss';

const useHorizontalDividerStyles = ({ spacing }: any): Styles => ({
  spacing1: {
    padding: spacing(1, 0),
  },
  spacing2: {
    padding: spacing(2, 0),
  },
  spacing3: {
    padding: spacing(3, 0),
  },
  spacing4: {
    padding: spacing(4, 0),
  },
  includeOpacity: {
    opacity: 0.38,
  },
  horizontalDividerWrapper: {
    '& div.horizontalDivider': {
      height: '1px',
      width: '100%',
      margin: 0,
    },
  },
});

export default makeStyles2(useHorizontalDividerStyles);
