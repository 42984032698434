const stringToColor = (str: string) => {
  let hash = 0;
  if (str.length === 0) return hash;
  // eslint-disable-next-line
  for (let i = 0; i < str.length; i++) {
    // eslint-disable-next-line
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
    // eslint-disable-next-line
    hash = hash & hash;
  }
  let color = '#';
  // eslint-disable-next-line
  for (let i = 0; i < 3; i++) {
    // eslint-disable-next-line
    let value = (hash >> (i * 8)) & 255;
    color += `00${value.toString(16)}`.substr(-2);
  }
  return color;
};

const getInitials = (name: string, initialLength: number) => {
  const [firstName, lastName] = name.split(' ');
  if (firstName && lastName && initialLength === 2) {
    return `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase();
  }
  return firstName.charAt(0).toUpperCase();
};

export const useInitialsColors = (name: string, initialLength: number) => {
  return {
    color: stringToColor(name),
    initials: getInitials(name, initialLength),
  };
};
