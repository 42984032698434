import { svg } from 'lit';
export const OnetrustCheckIcon = svg`
  <svg
    width="36"
    height="18"
    viewBox="0 0 15.875 7.9375"
    version="1.1"
    id="svg5"
    inkscape:version="1.2 (dc2aeda, 2022-05-15)"
    sodipodi:docname="ontrust-icon.svg"
    xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
    xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg">
    <sodipodi:namedview
      id="namedview7"
      pagecolor="#ffffff"
      bordercolor="#000000"
      borderopacity="0.25"
      inkscape:showpageshadow="2"
      inkscape:pageopacity="0.0"
      inkscape:pagecheckerboard="0"
      inkscape:deskcolor="#d1d1d1"
      inkscape:document-units="mm"
      showgrid="false"
      inkscape:zoom="6.0353733"
      inkscape:cx="12.840962"
      inkscape:cy="34.54633"
      inkscape:window-width="1390"
      inkscape:window-height="1027"
      inkscape:window-x="0"
      inkscape:window-y="25"
      inkscape:window-maximized="0"
      inkscape:current-layer="layer1"
      showguides="true">
      <inkscape:grid
        type="xygrid"
        id="grid275"
        originx="0"
        originy="0" />
      <sodipodi:guide
        position="60.935888,0.66026918"
        orientation="1,0"
        id="guide4704"
        inkscape:locked="false" />
      <sodipodi:guide
        position="49.09942,1.3616892"
        orientation="1,0"
        id="guide4706"
        inkscape:locked="false" />
    </sodipodi:namedview>
    <defs
      id="defs2" />
    <g
      inkscape:label="Layer 1"
      inkscape:groupmode="layer"
      id="layer1">
      <g
        id="g12758"
        transform="matrix(0.18392665,0,0,0.18392665,-1.915911,-0.25030939)">
        <rect
          style="fill:#0765fe;fill-opacity:1;stroke-width:0.264583"
          id="rect115"
          width="81.277077"
          height="33.526794"
          x="14.305218"
          y="6.5632834"
          ry="16.763397" />
        <path
          id="rect3203"
          style="fill:#ffffff;stroke-width:0.25289"
          d="m 31.630547,7.2647047 h 29.393019 c 0.04601,0.060171 -11.74879,31.6855663 -11.74879,31.6855663 H 31.630547 c -8.776902,0 -15.842783,-7.065882 -15.842783,-15.842783 0,-8.776902 7.065881,-15.8427833 15.842783,-15.8427833 z"
          sodipodi:nodetypes="sccsss" />
        <g
          id="path9282">
          <path
            style="color:#000000;fill:#ffffff;stroke-width:2.065;stroke-linecap:round;stroke-linejoin:round"
            d="m 25.075775,23.672935 5.172974,7.189557 17.535509,-16.483376"
            id="path10426" />
          <path
            style="color:#000000;fill:#0765fe;stroke-linecap:round;stroke-linejoin:round"
            d="M 47.076172,13.626953 30.398437,29.302734 25.914062,23.070312 a 1.0325,1.0325 0 0 0 -1.441406,-0.236328 1.0325,1.0325 0 0 0 -0.234375,1.441407 l 5.171875,7.189453 a 1.0326033,1.0326033 0 0 0 1.544922,0.15039 L 48.492187,15.130859 a 1.0325,1.0325 0 0 0 0.04492,-1.458984 1.0325,1.0325 0 0 0 -1.460937,-0.04492 z"
            id="path10428" />
        </g>
        <g
          id="path11210">
          <path
            style="color:#000000;fill:#ffffff;stroke-width:2.065;stroke-linecap:round;stroke-linejoin:round"
            d="M 64.969053,14.466793 81.627786,30.687137"
            id="path11285" />
          <path
            style="color:#000000;fill:#ffffff;stroke-linecap:round;stroke-linejoin:round"
            d="m 65.689453,13.726563 a 1.0325,1.0325 0 0 0 -1.460937,0.01953 1.0325,1.0325 0 0 0 0.01953,1.460937 l 16.660156,16.220703 a 1.0325,1.0325 0 0 0 1.458984,-0.01953 1.0325,1.0325 0 0 0 -0.01953,-1.460937 z"
            id="path11287" />
        </g>
        <g
          id="g11293"
          transform="matrix(-1,0,0,1,146.5957,0)">
          <path
            style="color:#000000;fill:#ffffff;stroke-width:2.065;stroke-linecap:round;stroke-linejoin:round"
            d="M 64.969053,14.466793 81.627786,30.687137"
            id="path11289" />
          <path
            style="color:#000000;fill:#ffffff;stroke-linecap:round;stroke-linejoin:round"
            d="m 65.689453,13.726563 a 1.0325,1.0325 0 0 0 -1.460937,0.01953 1.0325,1.0325 0 0 0 0.01953,1.460937 l 16.660156,16.220703 a 1.0325,1.0325 0 0 0 1.458984,-0.01953 1.0325,1.0325 0 0 0 -0.01953,-1.460937 z"
            id="path11291" />
        </g>
      </g>
    </g>
  </svg>

`;
