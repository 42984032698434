export const applyBackgroundOverlay = () => {
  const body = window.document.querySelector('body');
  if (!body) return;

  body.style.height = '100vh';
  body.style.overflow = 'hidden';
};

export const removeBackgroundOverlay = () => {
  const body = window.document.querySelector('body');
  if (!body) return;
  body.style.height = '';
  body.style.overflow = '';
};

export const toggleBackgroundOverlay = (isExpanded: boolean) => {
  if (isExpanded) {
    applyBackgroundOverlay();
    return;
  }
  removeBackgroundOverlay();
};
