import { makeStyles2 } from '../../../../core';

const linkStyle = {
  textDecoration: 'none',
  cursor: 'pointer',
  '&:not(:hover), &:hover': {
    color: 'inherit',
  },
};

const getProfileStyles = ({ responsiveMaxWidth, mobileBreakpoint, palette, spacing }: any) => {
  return {
    [responsiveMaxWidth(mobileBreakpoint)]: {
      profileHiddenMobile: {
        display: 'none !important',
      },
    },
    ['@media (min-width: 959px)']: {
      profileHiddenDesktop: {
        display: 'none !important',
      },
    },
    gntcProfileContent: {
      padding: spacing(0, 1),
      [responsiveMaxWidth(mobileBreakpoint)]: {
        padding: spacing(1, 4),
      },
      '& .profile-row': {
        display: 'flex',
      },
      '& .profile-column': {
        flex: '1',
      },
      '& .close-row': {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: spacing(2),
        paddingBottom: spacing(3),
      },
    },
    gntcProfileHeader: {
      padding: spacing(4, 0, 3),
      [responsiveMaxWidth(mobileBreakpoint)]: {
        padding: spacing(2, 0, 3),
      },
    },
    gntcProfileBody: {
      padding: spacing(1, 0, 3),
      [responsiveMaxWidth(mobileBreakpoint)]: {
        padding: spacing(1, 0, 3),
      },
    },
    gntcProfileFooter: {
      textAlign: 'left',
      padding: spacing(0, 0, 4),
      [responsiveMaxWidth(mobileBreakpoint)]: {
        backgroundColor: palette.background.dark.color,
      },
    },
    gntcProfileAccountWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    gntcProfileAccount: {
      textAlign: 'left',
      fontSize: '18px',
      fontWeight: '600',
      lineHeight: '20px',
    },
    gntcProfileGoPortalWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    mobileBackButtonWrapper: {
      marginLeft: spacing(-0.5),
    },
    profileCloseIcon: {
      position: 'relative',
    },
  };
};

export default makeStyles2(getProfileStyles);
