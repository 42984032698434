export const COLORS = {
  charcol1: '#22282f',
  charcol2: '#363c43',
  charcol3: '#0e141b',
  charcol4: '#2c3239',
  charcol5: '#181e25',
  charcol6: '#262B31',
  eggshell: '#f0f0f0',
  blue1: '#00aeef',
  blue2: '#3dc1f3',
  blue3: '#007fbc',
  white: '#ffffff',
  black: '#000000',
};

const primary = {
  color: COLORS.charcol1,
  text: COLORS.eggshell,
  light: {
    color: COLORS.charcol2,
    text: COLORS.eggshell,
  },
  dark: {
    color: COLORS.charcol3,
    text: COLORS.eggshell,
  },
};

const secondary = {
  color: COLORS.blue1,
  text: COLORS.charcol1,
  light: {
    color: COLORS.blue2,
    text: COLORS.charcol1,
  },
  dark: {
    color: COLORS.blue3,
    text: COLORS.eggshell,
  },
};

const background = {
  color: COLORS.eggshell,
  text: COLORS.charcol1,
  light: {
    color: COLORS.eggshell,
    text: COLORS.charcol1,
  },
  dark: {
    color: COLORS.charcol4,
    text: COLORS.eggshell,
  },
};

const surface = {
  color: COLORS.white,
  text: COLORS.charcol1,
  light: {
    color: COLORS.white,
    text: COLORS.charcol1,
  },
  dark: {
    color: COLORS.charcol5,
    text: COLORS.eggshell,
  },
};

const TYPES: any = {
  primary,
  secondary,
  background,
  surface,
};

const getTypeProps = (type: string) => ({
  [type]: {
    color: getProp(TYPES[type], 'color'),
    text: getProp(TYPES[type], 'text'),
    light: {
      color: getProp(TYPES[type], 'light-color'),
      text: getProp(TYPES[type], 'light-text'),
    },
    dark: {
      color: getProp(TYPES[type], 'dark-color'),
      text: getProp(TYPES[type], 'dark-text'),
    },
  },
});

const getProp = (color: Color, prop: string) => {
  const props = prop.split('-');

  switch (props.length) {
    case 2:
      return color[props[0] as 'light' | 'dark'][props[1] as 'color' | 'text'];
    default:
      return color[props[0] as 'color' | 'text'];
  }
};

const palette = {
  ...Object.keys(TYPES)
    .map((type) => getTypeProps(type))
    .reduce((a: any, b: any) => ({ ...a, ...b }), {}),
};

export enum ColorNames {
  charcoal = 'charcoal',
  darkGray = 'dark-gray',
}

export const COLOR_MAP = {
  [ColorNames.charcoal]: COLORS.charcol1,
  [ColorNames.darkGray]: COLORS.charcol5,
};

export default palette;
