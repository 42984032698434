import { html, TemplateResult } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { ColorNames, COLOR_MAP } from '../../shared/theme/palette';
import { GntcGlobalHeaderLinkMixin } from '../gntc-global-header-link-mixin';
import { MenuGroup, MenuGroupSection, MenuItem } from '../gntc-global-header.interface';
import useStyles from '../gntc-global-header.styles';

export class GntcMainNavigationElementsMixin {
  protected globalHeaderLinkMixin = new GntcGlobalHeaderLinkMixin(this.$globalHeaderRef);
  constructor(protected $globalHeaderRef: any) {}

  public handleCTAClick(e: MouseEvent) {
    const target = (e?.target as HTMLSpanElement)?.closest('a');
    const ctaEvent = new CustomEvent('gtm:click:cta', {
      bubbles: true,
      composed: true,
      detail: { target },
    });

    e?.target?.dispatchEvent(ctaEvent);
  }

  getMenuGroupSectionVariantPromo(menuGroupSection: MenuGroupSection) {
    const { menuItem } = Array.isArray(menuGroupSection) ? menuGroupSection[0] : menuGroupSection;
    const { image, link, menuDescription } = menuItem as MenuItem;

    if (!image || !link) {
      return null;
    }

    const { secNavMenuGroupSectionPromo: secNavMenuGroupSectionPromoClass } = useStyles.classes;
    const secNavMenuGroupSectionPromoClasses = classMap({
      [secNavMenuGroupSectionPromoClass]: true,
    });
    return html`
      <a
        class="${secNavMenuGroupSectionPromoClasses}"
        style="background-image:url('${image.original?.url}')"
        href="${link.href || ''}"
        target="${link.openInNewWindow ? '_blank' : '_self'}"
        @click="${this.handleCTAClick}"
        data-datalayer-variant=${ifDefined(
          this.$globalHeaderRef.datalayerVariant
            ? this.$globalHeaderRef.datalayerVariant
            : undefined,
        )}
      >
        <span class="rte">${menuDescription ? unsafeHTML(menuDescription) : undefined}</span>
      </a>
    `;
  }

  getMenuGroupSectionVariantImage(menuGroupSection: MenuGroupSection) {
    const { menuItem } = Array.isArray(menuGroupSection) ? menuGroupSection[0] : menuGroupSection;
    const { image } = menuItem as MenuItem;

    if (!image) {
      return null;
    }

    const { secNavMenuGroupSectionPromo: secNavMenuGroupSectionImageClass } = useStyles.classes;
    const secNavMenuGroupSectionImageClasses = classMap({
      [secNavMenuGroupSectionImageClass]: true,
    });
    return html`
      <a
        class="${secNavMenuGroupSectionImageClasses}"
        style="background-image:url('${image.original?.url}')"
        aria-label="${image.altText || ''}"
        href="${image.url || ''}"
        @click="${this.handleCTAClick}"
      ></a>
    `;
  }

  updatePositionColorDivider(mainNavigationRef: HTMLElement) {
    const dividerWrapperRefs = mainNavigationRef.querySelectorAll('.color-divider');

    dividerWrapperRefs.forEach((dividerWrapperRef) => {
      const { left, top } = dividerWrapperRef.getBoundingClientRect();
      const dividerRef: HTMLElement | null = dividerWrapperRef?.querySelector('span');
      const containerRef = dividerWrapperRef.closest('#dropdown-container');
      const colorValue = dividerWrapperRef.getAttribute('data-color');
      const dividerColor = COLOR_MAP[colorValue as ColorNames] || 'unset';
      const positionValue = dividerWrapperRef.getAttribute('data-position');

      if (!dividerRef || !containerRef) return;
      const { top: containerTop = 0 } = containerRef?.getBoundingClientRect() ?? {};
      const dividerTop = top - (containerTop + containerRef?.scrollTop);

      dividerRef.style.backgroundColor = dividerColor;

      if (this.$globalHeaderRef.isMobile) {
        dividerRef.style.zIndex = '-1';
        dividerRef.style.width = '100%';

        if (positionValue === 'left') {
          dividerRef.style.top = '0px';
          dividerRef.style.height = top - 58 + 'px';
        } else {
          dividerRef.style.height = `${containerRef.scrollHeight - dividerTop}px`;
          dividerRef.style.top = `${dividerTop}px`;
        }
      } else {
        if (positionValue === 'left') {
          dividerRef.style.left = '0';
          dividerRef.style.width = left + 'px';
        } else {
          dividerRef.style.left = left + 'px';
        }
      }
    });
  }

  getMenuGroupSectionVariantIconLink(menuGroupSection: MenuGroupSection) {
    const { menuItem } = Array.isArray(menuGroupSection) ? menuGroupSection[0] : menuGroupSection;
    const { image, link } = menuItem as MenuItem;

    if (!image || !link) {
      return null;
    }

    const { secNavMenuGroupSectionIconLink, secNavMenuGroupSectionWrapIconLink } =
      useStyles.classes;

    return html`
      <div class="${secNavMenuGroupSectionWrapIconLink}">
        ${this.globalHeaderLinkMixin.getLink({
          linkProps: link,
          classes: secNavMenuGroupSectionIconLink,
          icon: image.original ? html`<img src="${image.original.url}" alt="" />` : undefined,
          datalayerVariant: this.$globalHeaderRef.datalayerVariant,
        })}
      </div>
    `;
  }

  renderFullWidthFormGroupRows(
    menuGroups: MenuGroup[],
    renderFormGroupFunction: (menuGroup: MenuGroup) => TemplateResult,
  ) {
    const getBackgroundColor = (menuGroup: MenuGroup) =>
      menuGroup?.containerBackground
        ? COLOR_MAP[menuGroup.containerBackground as ColorNames]
        : 'unset';

    return menuGroups.map(
      (menuGroup) => html`
        <div
          class="full-width-menu-group"
          style="${`background-color: ${getBackgroundColor(menuGroup)}`}"
        >
          ${renderFormGroupFunction(menuGroup)}
        </div>
      `,
    );
  }
}
