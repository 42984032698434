import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { ContentMenuItem } from '../../gntc-global-header.interface';
import '../../shared/gntc-menu-link';
import './gntc-apps-app-item';
import './gntc-apps-app-main-item';
import useAppsDropdownContentStyles from './gntc-apps-content.styles';

@customElement('gntc-apps-content')
export class GntcAppsContent extends LitElement {
  static styles = useAppsDropdownContentStyles().css;

  @property({ type: String, reflect: true })
  protected partnerOnlyLabel = '';

  @property({ type: String, reflect: true })
  protected partnerOnlyIconUrl = '';

  @property({ type: String, reflect: true })
  protected partnerOnlyLinkUrl = '';

  @property({ type: String, reflect: true })
  protected mainLogoUrl = '';

  @property({ type: Array, reflect: true })
  protected appMainItems: ContentMenuItem[] = [];

  @property({ type: Array, reflect: true })
  protected groupedAppItems: ContentMenuItem[][] = [];

  @property({ type: Boolean, reflect: true })
  protected isMobile = false;

  protected render(): unknown {
    const { classes } = useAppsDropdownContentStyles();
    return html`
      <div class="${classes.appsContent}">
        ${this.isMobile
          ? html`
              <div class="${`close-row`}">
                <div>
                  <img src="${this.mainLogoUrl}" />
                </div>
                <gntc-close-icon
                  class="${classes.appsCloseIcon}"
                  @closeClick="${() => this.dispatchEvent(new CustomEvent('closeClicked'))}"
                ></gntc-close-icon>
              </div>
            `
          : undefined}
        <div class="${classes.appsContentHeader}">
          <div class="${classes.appsContentHeaderLogo}">
            <img src="${this.mainLogoUrl}" />
          </div>
          <div class="${classes.appsContentHeaderPartner}">
            <gntc-menu-link
              .href="${this.partnerOnlyLinkUrl}"
              .iconUrl="${this.partnerOnlyIconUrl}"
              .label="${this.partnerOnlyLabel}"
            >
            </gntc-menu-link>
          </div>
        </div>
        <div class="${classes.appsMainItems}">
          ${this.appMainItems.map(
            (item: ContentMenuItem) => html`
              <div
                class="${classMap({
                  mainItemContainer: true,
                  [classes.mainItemContainerMargin]: this.appMainItems.length > 2,
                })}"
              >
                <gntc-apps-app-main-item .appItem="${item}"></gntc-apps-app-main-item>
              </div>
            `,
          )}
        </div>
        ${this.groupedAppItems.map(
          (groupedAppItem: ContentMenuItem[]) => html`
            <div class="${classes.horizontalDivider}"></div>
            <div class="${classes.appsItems}">
              ${groupedAppItem.map(
                (item: ContentMenuItem) => html`
                  <div class="itemContainer">
                    <gntc-apps-app-item .appItem="${item}"></gntc-apps-app-item>
                  </div>
                `,
              )}
            </div>
          `,
        )}
      </div>
    `;
  }
}
