import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import gntcAvatarImageStyles from './gntc-avatar-image.styles';

@customElement('gntc-avatar-image')
export class GntcAvatarInitials extends LitElement {
  static styles = gntcAvatarImageStyles().css;

  @property({ type: String })
  src = '';

  @property({ type: String })
  size = 'base';

  protected render(): unknown {
    const { classes } = gntcAvatarImageStyles();

    return html`
      <div
        class="${classMap({
          [classes.avatar]: true,
          [classes.initialsAvatar]: true,
          [classes[this.size]]: true,
        })}"
      >
        <img class="${classes.avatatImg}" src="${this.src}" alt="" />
      </div>
    `;
  }
}
