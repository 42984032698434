export const generateSearchParams = (query: string, size: number, language: string, none: any) => ({
  query,
  page: {
    size,
  },
  filters: {
    all: [{ published: 'true' }, ...(language ? [{ none }] : [])],
  },
  result_fields: {
    title: {
      raw: {},
    },
    description: {
      snippet: {
        size: 200,
        fallback: true,
      },
    },
    body: {
      snippet: {
        size: 200,
        fallback: true,
      },
    },
    icon: {
      raw: {},
    },
    url: {
      raw: {},
    },
    tax_content_category: {
      raw: {},
    },
    language: {
      raw: {},
    },
  },
});

export const cleanDashesInUrl = (url: string): string => url.replace(/([^:]\/)\/+/g, '$1');
