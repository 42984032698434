import { makeStyles2 } from '../../../core';
import { linkHoverStyle } from '../../gntc-global-header.styles';

const appsAppItemStyles = ({ palette, spacing, responsiveMaxWidth, mobileBreakpoint }: any) => ({
  appItem: {
    color: palette.primary.dark.text,
    textDecoration: 'none',
    cursor: 'pointer',
    fontsize: '14px',
    padding: spacing(1, 1),
    margin: spacing(0, -1),
    display: 'flex',
    alignItems: 'center',
    '&:not(:hover), &:hover': {
      color: 'inherit',
    },
    '& > span, & > span > * ': {
      margin: '0',
      padding: '0',
      fontsize: '14px',
      lineHeight: '16px',
    },
    '& img': {
      width: '24px',
      height: '24px',
      margin: spacing(0, 1, 0, 0),
      [responsiveMaxWidth(mobileBreakpoint)]: {
        margin: spacing(0, 2, 0, 0),
      },
    },
    '& svg': {
      display: 'none',
      margin: spacing(0, 0, 0, 1),
      [responsiveMaxWidth(mobileBreakpoint)]: {
        display: 'inline-block',
      },
    },
    ...linkHoverStyle(),
  },
});

export default makeStyles2(appsAppItemStyles);
