import { LitElement, PropertyValues, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { captureClicks } from '../../../core/utils';
import { ContentMenuItem } from '../../gntc-global-header.interface';
import useAppsAppMainItemStyles from './gntc-apps-app-main-item.style';

@customElement('gntc-apps-app-main-item')
export class GntcAppsAppMainItem extends LitElement {
  static styles = useAppsAppMainItemStyles().css;

  protected firstUpdated(_changedProperties: PropertyValues) {
    super.firstUpdated(_changedProperties);

    Promise.resolve().then(() => {
      if (this.shadowRoot) {
        captureClicks({ context: this.shadowRoot });
      }
    });
  }

  @property({ type: Object, reflect: true })
  protected appItem: ContentMenuItem | null = null;

  protected render(): unknown {
    const { classes } = useAppsAppMainItemStyles();
    return html`
      <a
        href="${this.appItem?.link?.href}"
        class="${classes.appMainItem}"
        target="${this.appItem?.link?.openInNewWindow ? '_blank' : '_self'}"
      >
        <img src="${this.appItem?.icon?.url}" alt="" />
        <span>${unsafeHTML(this.appItem?.icon?.text)}</span>
      </a>
    `;
  }
}
