const breakpoints: Record<Breakpoints, number> = {
  xs: 375,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
};

export default (breakpoint: Breakpoints) => `@media (min-width: ${breakpoints[breakpoint]}px)`;

export const maxWidth = (breakpoint: Breakpoints) =>
  `@media (max-width: ${breakpoints[breakpoint] - 1}px)`;

export const isMaxWidth = (breakpoint: Breakpoints): boolean => {
  const viewportWidth = window.innerWidth || document.documentElement.clientWidth;

  return viewportWidth < breakpoints[breakpoint];
};
