export const animate = (ref: HTMLElement, toggle: boolean, openClass = 'is-open') => {
  if (toggle) {
    ref.classList.remove('animate-exit');
    ref.classList.add('animate-enter');
    setTimeout(() => {
      ref.classList.add(openClass);
    }, 100);
  } else {
    ref.classList.remove('animate-enter');
    ref.classList.add('animate-exit');
    setTimeout(() => {
      ref.classList.remove(openClass);
    }, 100);
  }
};

export const animateStyles = () => ({
  '@global': {
    '@keyframes fadeIn': {
      from: { opacity: 0 },
      to: { opacity: 1 },
    },
    '@keyframes fadeOut': {
      from: { opacity: 1 },
      to: { opacity: 0 },
    },
  },
  '&.animate-enter, & .animate-enter': {
    animationName: '$fadeIn',
    animationDuration: '150ms',
    animationFillMode: 'both',
  },
  '&.animate-exit, & .animate-exit': {
    animationName: '$fadeOut',
    animationDuration: '150ms',
    animationFillMode: 'both',
  },
});
