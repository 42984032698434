import { html, LitElement, PropertyValues, TemplateResult, unsafeCSS } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import urllite from 'urllite/lib/core';
import { getHref } from '../../core/resolvers';
import {
  captureClicks,
  formatText,
  getPageUrl,
  isFocusWithin,
  isPathAbsolute,
  matchExternalLink,
  safeJSONParse,
} from '../../core/utils';
import { COLORS } from '../../shared/theme/palette';
import {
  emitCloseHeaderMenuEvent,
  generateHeaderMenuToggleTriggers,
  HeaderMenus,
} from '../gntc-global-header.events';
import { adaptSearchResults, adaptSearchSuggestions } from '../search-adapter/search-adapter';
import { getResults, getSuggestion, trackSearchClick } from '../services/SearchService';
import '../shared/gntc-horizontal-divider';
import './gntc-search-navigation-input';
import { SearchResult, SearchResultCategory } from './gntc-search-navigation.interface';
import useStyles from './gntc-search-navigation.styles';
import { getSearchResultCategories } from './gntc-search-navigation.utils';
import './partner-only/gntc-partner-only-filter';

const [emitSearchOptionToggle, addListenerOnOtherHeaderOptionToggled] =
  generateHeaderMenuToggleTriggers(HeaderMenus.SearchNavigation);

@customElement('gntc-search-navigation')
export class SearchNavigationBase extends LitElement {
  static styles = unsafeCSS(useStyles.toString());

  @query('.search-navigation')
  rootElement?: HTMLElement;

  @query('gntc-search-input')
  searchInputElement?: HTMLElement;

  @property({ type: String, reflect: true })
  protected spaBaseUrl = '';

  @property({ type: String, reflect: true })
  protected cmsBaseUrl = '';

  @property({ type: String, reflect: true })
  protected searchBaseUrl = '';

  @property({ type: String, reflect: true })
  protected searchToken = '';

  @property({ type: Boolean, reflect: true })
  protected isLogged = false;

  @property({ type: Boolean, reflect: true })
  protected isDesktop = false;

  @property({ type: String, reflect: true })
  protected searchEngine = '';

  @property({ type: String, reflect: true })
  protected searchAuthEngine = '';

  @property({ type: Object, reflect: true })
  protected searchIcon = { iconUrl: '', label: '' };

  @property({ reflect: false })
  protected labels?: Record<string, string>;

  @property({ reflect: false })
  protected config: Record<string, string> = {};

  @property({ type: Number, reflect: false })
  protected debounceDelay = 1;

  @property({ type: Number, reflect: false })
  protected debounceSuggestionDelay = 1;

  @property({ type: String, reflect: true })
  protected searchArialLabel = '';

  @property({ type: String, reflect: true })
  protected searchInputArialLabel = '';

  @property({ type: Boolean, reflect: true })
  protected isExternal = false;

  @state()
  protected searchValue = '';

  @state()
  protected suggestion = '';

  @state()
  protected isTyping = false;

  @state()
  protected debounceTimeout: any;

  @state()
  protected debounceSuggestionTimeout: any;

  @state()
  protected results: SearchResult[] = [];

  @state()
  protected resultCategories: SearchResultCategory[] = [];

  @state()
  protected status: 'NONE' | 'LOADING' | 'READY' | 'NO RESULTS' = 'NONE';

  @state()
  protected meta: any;

  @state()
  protected language = '';

  @property({ type: String, reflect: false })
  datalayerVariant?: string = '';

  @state()
  protected customShadowRootClicked = false;

  protected getIconButton(icon: string, label: string) {
    const { iconButton: iconButtonClass } = useStyles.classes;
    const iconButtonClasses = classMap({
      [iconButtonClass]: true,
    });

    return html`<button class="${iconButtonClasses}" aria-label="${label}" tabindex="0">
      <img src="${this.getUrl(icon)}" alt="" />
    </button>`;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected handleGTM(): void {
    const newEvent = new CustomEvent('gtm:custom:search', {
      bubbles: true,
      composed: true,
      detail: { target: { searchQuery: this.searchValue } },
    });

    this.dispatchEvent(newEvent);
  }

  protected handleSearchInputKeydown(event: KeyboardEvent) {
    const isArrowRight = /^(arrow)?right$/i.test(event.key);

    if (isArrowRight) {
      this.searchValue = this.suggestion;
      this.executeQuery();
    }
  }

  protected onSearchSubmit() {
    // If the form is submitted then manual navigation is triggered (internal/external cases).
    const el = this.shadowRoot!.getElementById('search-input-button') as HTMLAnchorElement;
    if (!el) return;
    const url = urllite(el.href);
    const href = el.getAttribute('href') || '';
    if (isPathAbsolute(href)) return (window.location.href = href);
    const newEvent = new CustomEvent('navigate', {
      bubbles: true,
      composed: true,
      detail: {
        href: url.pathname + (url.search ? url.search : '') + (url.hash.length > 1 ? url.hash : ''),
      },
    });

    this.handleGTM();
    this.dispatchEvent(newEvent);

    clearTimeout(this.debounceSuggestionTimeout);
    clearTimeout(this.debounceTimeout);

    this.status = 'NONE';
    this.triggerSearchContentCollapsedEvent();
    emitCloseHeaderMenuEvent();
  }

  protected onInputSearch(value: string) {
    this.searchValue = value;
    this.status = 'LOADING';
    this.triggerSearchContentExpandedEvent();

    this.suggestion = '';
    clearTimeout(this.debounceSuggestionTimeout);
    this.debounceSuggestionTimeout = setTimeout(() => {
      this.getSuggestions();
    }, this.debounceSuggestionDelay * 500);

    clearTimeout(this.debounceTimeout);
    this.debounceTimeout = setTimeout(() => {
      // this.getSuggestions();
      this.executeQuery();
    }, this.debounceDelay * 1000);
  }

  protected onEnableNavigationSearchButton() {
    this.language = document.querySelector('gntc-global-header')?.getAttribute('language') || '';
    this.triggerSearchContentExpandedEvent();
  }

  protected onDisableNavigationSearchButton() {
    this.triggerSearchContentCollapsedEvent();
  }

  getUrl(path: string) {
    return getHref({ url: path, spaBaseUrl: this.spaBaseUrl });
  }

  getFormHeader(): TemplateResult {
    const { inputHeader } = useStyles.classes;

    return html`<gntc-search-input
      class="${inputHeader}"
      icon="${this.searchIcon.iconUrl && this.getUrl(this.searchIcon.iconUrl)}"
      value="${this.searchValue}"
      suggestion="${this.suggestion}"
      .onEnable="${this.onEnableNavigationSearchButton.bind(this)}"
      .onDisable="${this.onDisableNavigationSearchButton.bind(this)}"
      .isCollapsed="${!this.isTyping && this.searchValue.length === 0}"
      searchArialLabel="${this.searchArialLabel}"
      searchInputArialLabel="${this.searchInputArialLabel}"
      .onInputChange="${this.onInputSearch.bind(this)}"
      @onSubmit="${() => this.onSearchSubmit()}"
      @onKeyDown="${(e: KeyboardEvent) => this.handleSearchInputKeydown(e)}"
      @searchButtonClick="${() => this.onSearchButtonClick()}"
    ></gntc-search-input>`;
  }

  getTemplateResults(): TemplateResult {
    if (this.isTyping) {
      emitSearchOptionToggle(true);
    }

    const {
      searchNavigationContent,
      secNavContainer: secNavContainerClass,
      secNavMenuGroup: secNavMenuGroupClass,
    } = useStyles.classes;

    const searchNavigationContentClasses = classMap({
      [searchNavigationContent]: true,
      'search-navigation-content': true,
      'search-navigation-results': true,
      'is-open': this.isTyping,
    });

    const secNavContainerClasses = classMap({
      [secNavContainerClass]: true,
      'search-navigation-results': true,
      'no-results': this.status !== 'LOADING' && this.results.length === 0,
    });

    const secNavMenuGroupClasses = classMap({
      [secNavMenuGroupClass]: true,
    });

    return html`
      <div class="${searchNavigationContentClasses}">
        <div class="${secNavContainerClasses}">
          <div class="${secNavMenuGroupClasses}">
            ${this.getResultsLeft()} ${this.getResultsContent()}
          </div>
        </div>
      </div>
    `;
  }

  getResultsLeft(): TemplateResult {
    const { resultsSidebar, resultsSidebarHightlightLink } = useStyles.classes;
    const sidebarResults = this.results.slice(2);
    const { search_results_more } = this.labels || {};
    const base = `${this.spaBaseUrl.replace(/\/$/, '')}/${this.searchBaseUrl.replace(/^\//, '')}`;

    return html`<div class="${resultsSidebar}">
      ${this.status === 'LOADING'
        ? html`${[0, 1, 2, 3].map(() => this.getResultsLeftItemSkeleton())}`
        : html`
          ${sidebarResults.map((item) => this.getResultsLeftItem(item))}
          ${
            this.results.length > 2
              ? html`<div>
                  <a
                    data-datalayer-position="search_result"
                    data-datalayer-variant=${ifDefined(
                      this.datalayerVariant ? this.datalayerVariant : undefined,
                    )}
                    class="${resultsSidebarHightlightLink}"
                    tabindex="0"
                    href="${getPageUrl({
                      base,
                      url: `?q=${this.searchValue}`,
                    })}"
                    @click=${() => this.handleGTM()}
                  >
                    <span> ${search_results_more} </span>
                  </a>
                </div>`
              : ''
          }
        </div>`}
    </div>`;
  }

  getResultsLeftItemSkeleton(): TemplateResult {
    const { resultsSidebarItem, skeleton } = useStyles.classes;
    return html`<div class="${resultsSidebarItem}">
      <div class="title"><div class="${skeleton}"></div></div>
      <div class="category"><div class="${skeleton}"></div></div>
    </div>`;
  }

  getResultsLeftItem(result: SearchResult): TemplateResult {
    const { resultsSidebarItem } = useStyles.classes;
    const { search_results_in } = this.labels || {};
    const docID = result?.document_id;
    const { value } = this.searchInputElement as HTMLInputElement;
    const isOpenedInNewWindow = Boolean(result.url && matchExternalLink(result.url));

    return html`<a
      href="${result.url}"
      class="${resultsSidebarItem}"
      data-resultid="${ifDefined(docID)}"
      data-datalayer-scenario="result"
      data-datalayer-position="search_result"
      data-datalayer-variant=${ifDefined(this.datalayerVariant ? this.datalayerVariant : undefined)}
      data-query="${value}"
      target=${ifDefined(isOpenedInNewWindow ? '_blank' : undefined)}
    >
      <div class="title">${unsafeHTML(result.snippet)}</div>
      ${result.taxContentCategory
        ? html`<div class="category">
            ${formatText(search_results_in || '', [result.taxContentCategory])}
          </div>`
        : html``}
    </a>`;
  }

  getResultsRight(): TemplateResult {
    return html`test`;
  }

  renderResultsContent(results: SearchResult[] = []) {
    const classes = useStyles.classes;
    const { query_by_category } = this.labels || {};
    const { partner_only_small_icon } = this.config || {};
    const base = `${this.spaBaseUrl.replace(/\/$/, '')}/${this.searchBaseUrl.replace(/^\//, '')}`;

    return html`
      <div>
        <div class="${classes.partnerFilterWrapper}">
          ${this.isLogged && this.resultCategories.length > 0
            ? html`
                <div class="partner-only-filter">
                  <gntc-partner-only-filter
                    .filterWildcardLabel="${query_by_category}"
                    .searchValue=${this.searchValue}
                    .partnerOnlyIconUrl="${partner_only_small_icon}"
                    .filtersOptions="${this.resultCategories}"
                    .isExternal="${this.isExternal}"
                    .baseSearchURL="${getPageUrl({
                      base,
                      url: `?q=${this.searchValue}`,
                    })}"
                    @onCategoryClick="${() => (this.customShadowRootClicked = true)}"
                  ></gntc-partner-only-filter>
                </div>
                <gntc-horizontal-divider
                  .color="${COLORS.charcol3}"
                  .includeOpacity="${false}"
                  spacingLevel="3"
                ></gntc-horizontal-divider>
              `
            : html``}
        </div>
        ${results.slice(0, 2).map((item) => this.getResultsContentItem(item))}
      </div>
    `;
  }

  getResultsContent(): TemplateResult {
    const { resultsContent, resultsContentForm, resultsContentInput, resultsContentInputLink } =
      useStyles.classes;

    const { search_results_placeholder } = this.labels || {};
    const base = `${this.spaBaseUrl.replace(/\/$/, '')}/${this.searchBaseUrl.replace(/^\//, '')}`;

    return html`<div class="${resultsContent}">
      <div class="${resultsContentForm}">
        <gntc-search-input
          class="${resultsContentInput}"
          icon="${this.searchIcon.iconUrl && this.getUrl(this.searchIcon.iconUrl)}"
          value="${this.searchValue}"
          suggestion="${this.suggestion}"
          .onEnable="${this.onEnableNavigationSearchButton.bind(this)}"
          .onDisable="${this.onDisableNavigationSearchButton.bind(this)}"
          .onInputChange="${this.onInputSearch.bind(this)}"
          @onSubmit="${() => this.onSearchSubmit()}"
          @onKeyDown="${(e: KeyboardEvent) => this.handleSearchInputKeydown(e)}"
        >
          <a
            id="search-input-button"
            data-datalayer-variant=${ifDefined(
              this.datalayerVariant ? this.datalayerVariant : undefined,
            )}
            class="${resultsContentInputLink}"
            tabindex="0"
            href="${getPageUrl({
              base,
              url: `?q=${this.searchValue}`,
            })}"
            @click=${() => this.handleGTM()}
          >
            <span>${search_results_placeholder || ''}</span>
          </a>
        </gntc-search-input>
      </div>
      ${this.status === 'LOADING' ? this.getResultsSkeleton() : html``}
      ${this.status === 'READY' ? this.renderResultsContent(this.results) : html``}
    </div>`;
  }

  getResultsSkeleton(): TemplateResult {
    const { skeleton, resultsContentItem } = useStyles.classes;

    return html`<div>
      <div class="${resultsContentItem}">
        <div class="icon">
          <div class="${skeleton} is-image"></div>
        </div>
        <div>
          <div class="title"><div class="${skeleton}"></div></div>
          <div class="description"><div class="${skeleton}"></div></div>
          <div class="description"><div class="${skeleton}"></div></div>
        </div>
      </div>
      <div class="${resultsContentItem}">
        <div class="icon">
          <div class="${skeleton} is-image"></div>
        </div>
        <div>
          <div class="title"><div class="${skeleton}"></div></div>
          <div class="description"><div class="${skeleton}"></div></div>
          <div class="description"><div class="${skeleton}"></div></div>
        </div>
      </div>
    </div>`;
  }

  getResultsContentItem(result: SearchResult): TemplateResult {
    const { resultsContentItem } = useStyles.classes;
    const docID = result?.document_id;
    const { value } = this.searchInputElement as HTMLInputElement;
    const isOpenedInNewWindow = Boolean(result.url && matchExternalLink(result.url));

    return html`<a
      href="${result.url}"
      class="${resultsContentItem}"
      data-resultid="${ifDefined(docID)}"
      data-datalayer-scenario="result"
      data-datalayer-position="search_results"
      data-datalayer-variant=${ifDefined(this.datalayerVariant ? this.datalayerVariant : undefined)}
      data-query="${value}"
      target=${ifDefined(isOpenedInNewWindow ? '_blank' : undefined)}
    >
      <div class="icon">${result.icon ? html` <img src="${result.icon}" alt="" /> ` : html``}</div>
      <div>
        <div class="title">${unsafeHTML(result.title)}</div>
        <div class="description">${unsafeHTML(result?.description)}</div>
      </div>
    </a>`;
  }

  debounce(callback: any, wait: number) {
    let timeout: any;
    return (args?: any) => {
      const next = () => callback.apply(this, args);
      clearTimeout(timeout);
      timeout = setTimeout(next, wait);
    };
  }

  getSuggestions() {
    getSuggestion(
      this.searchToken,
      this.searchValue,
      1,
      this.isLogged,
      this.searchEngine,
      this.language,
    ).then((response) => {
      [this.suggestion] =
        adaptSearchSuggestions(response.results, this.searchValue).length > 0
          ? adaptSearchSuggestions(response.results, this.searchValue)
          : [''];
    });
  }

  executeQuery(query = '') {
    const search = this.searchValue || query;

    if (search.length > 1) {
      getResults(
        this.searchToken,
        this.isLogged,
        search,
        6,
        this.searchEngine,
        this.searchAuthEngine,
        this.language,
      )
        .then(({ results, meta, facets }) => {
          this.results = adaptSearchResults({
            results,
            spaBaseUrl: this.spaBaseUrl,
            cmsBaseUrl: this.cmsBaseUrl,
            config: this.config,
            language: this.language,
            isExternal: this.isExternal,
          });
          this.resultCategories = getSearchResultCategories(facets);
          this.meta = meta;
        })
        .finally(() => {
          this.status = 'READY';
          this.triggerSearchContentExpandedEvent();
        });
    } else {
      this.status = 'NO RESULTS';
      this.results = [];
      this.triggerSearchContentExpandedEvent();
    }
  }

  getRootComponentTemplate(): TemplateResult {
    const { navigationSearch, headerForm } = useStyles.classes;

    const rootClasses = classMap({
      [navigationSearch]: true,
      'is-open': this.isTyping || this.searchValue.length > 0,
      'search-navigation': true,
    });

    return html`
      <div class="${rootClasses}">
        <div class="${headerForm}">
          <slot name="search-buttons"></slot>
        </div>
        <div>${this.getFormHeader()}</div>
        ${this.status !== 'NONE' ? this.getTemplateResults() : ''}
      </div>
    `;
  }

  protected async handleSearchDocumentClick() {
    const isFocus = await isFocusWithin(this.rootElement);

    if (isFocus) {
      return;
    }

    this.rootElement?.classList.remove('a11y');

    setTimeout(() => {
      this.triggerSearchContentCollapsedEvent();
    });
  }

  protected onSearchButtonClick() {
    emitSearchOptionToggle(true);
    this.isTyping = true;
    if (this.searchValue.length > 0) this.triggerSearchContentExpandedEvent();
  }

  protected handleSearchElementClick(event: MouseEvent) {
    const elementTarget = event.target as HTMLElement;
    const el = elementTarget?.closest('a') as HTMLAnchorElement;
    const shadowRootLinkClick = elementTarget.shadowRoot && this.customShadowRootClicked;

    // For manual catch of shadow root anchor click
    // TODO: improve handleSearchElementClick method
    if (shadowRootLinkClick) {
      this.customShadowRootClicked = false;
      if (this.searchValue.length > 0) this.triggerSearchContentExpandedEvent();
      return;
    }

    if (!el?.closest('a')) {
      event.stopPropagation();
    }

    if (event.target === this.searchInputElement) {
      event.stopPropagation();
      return;
    }

    if (el?.dataset?.resultid) {
      trackSearchClick(
        this.searchToken,
        this.searchValue,
        this.searchEngine,
        el.dataset.resultid,
        this.language,
        this?.meta?.request_id,
      );
    }
  }

  protected handleEscapeKeydown(event: KeyboardEvent) {
    const isEscape = /^esc/i.test(event.key);
    const isArrowUp = /^(arrow)?up$/i.test(event.key);
    const isArrowDown = /^(arrow)?down$/i.test(event.key);
    const isTab = /^tab$/i.test(event.key);

    if (isArrowUp || isArrowDown) {
      event.preventDefault();
    }

    if (isEscape) {
      this.triggerSearchContentCollapsedEvent();
    }

    if (isTab) {
      this.rootElement?.classList.add('a11y');
    }
  }

  protected firstUpdated(_changedProperties: PropertyValues) {
    super.firstUpdated(_changedProperties);

    addListenerOnOtherHeaderOptionToggled(({ toggleValue }) => {
      if (toggleValue) {
        this.triggerSearchContentCollapsedEvent();
      }
    });

    if (this.shadowRoot) {
      captureClicks({ context: this.shadowRoot, isExternal: this.isExternal });
    }

    if (this.isDesktop) {
      document.addEventListener('click', this.handleSearchDocumentClick.bind(this));
      document.addEventListener('keydown', this.handleEscapeKeydown.bind(this));
    }
    this.rootElement?.addEventListener('click', this.handleSearchElementClick.bind(this));
  }

  protected shouldUpdate(_changedProperties: PropertyValues) {
    if (_changedProperties.has('labels')) {
      this.labels = safeJSONParse(this.labels);
    }

    if (_changedProperties.has('config')) {
      this.config = safeJSONParse(this.config);
    }

    return super.shouldUpdate(_changedProperties);
  }

  protected render() {
    return html`${this.getRootComponentTemplate()}`;
  }

  protected async triggerSearchContentExpandedEvent() {
    await this.updateComplete;
    const isFocus = await isFocusWithin(this.rootElement);

    if ((this.status === 'NONE' && this.results.length === 0) || !isFocus) return;

    this.isTyping = true;

    const newEvent = new CustomEvent('expand', {
      bubbles: true,
      composed: true,
      detail: {
        searchStatus: this.status,
      },
    });
    this.dispatchEvent(newEvent);
  }

  protected triggerSearchContentCollapsedEvent() {
    this.isTyping = false;
    if (this.searchValue?.trim().length < 1) {
      this.status = 'NONE';
    }
    emitSearchOptionToggle(false);
    const newEvent = new CustomEvent('collapse', {
      bubbles: true,
      composed: true,
      detail: {},
    });
    this.dispatchEvent(newEvent);
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'gntc-search-navigation': SearchNavigationBase;
  }
}
