import { makeStyles } from '../core';
import { createTheme } from '../shared';
import spacing from '../shared/theme/spacing';

const { typography, palette, responsive, components, mobileBreakpoint } = createTheme(
  'globalFooter',
  'md',
  'dark',
);

const globalStyles = {
  ':host': {
    display: 'block',
    position: 'relative',

    '& *': { boxSizing: 'border-box' },
  },
};

const rootSyles = {
  ...typography.body1,
  backgroundColor: palette.primary.dark.color,
  color: palette.primary.dark.text,
  fontSmoothing: 'antialiased',

  '&:not(.a11y) *:focus': {
    outline: 'none !important',
  },
};

const footerStyles = {
  ...components.container.offset,
  alignItems: 'center',
  alignContent: 'center',
  padding: spacing(6.5, 2),

  [responsive('lg')]: {
    padding: spacing(6.5, 2),
  },
};

const footerContainerStyles = {
  width: '100%',
};

const footerRowStyles = {
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',

  [responsive(mobileBreakpoint)]: {
    justifyContent: 'center',
  },
};

const footerColumnStyles = {
  width: '50%',
  padding: spacing(2),
  margin: 0,
  // boxSizing: 'content-box',

  [responsive('sm')]: {
    width: '33.333333333%',
  },

  [responsive(mobileBreakpoint)]: {
    width: '20%',
  },

  [responsive('lg')]: {
    // width: '14.285714286%',
    width: '16.666666667%',
  },
};

const footerLogoStyles = {
  width: '100%',
  padding: spacing(0, 2),
  marginTop: spacing(2),

  '& > img': {
    marginTop: spacing(1.25),
    marginBottom: spacing(1),
    display: 'block',
  },

  [responsive('lg')]: {
    // width: '14.285714286%',
    width: '16.666666667%',
  },
};

// TODO: livechat temporarily removed- check responsive widths

// const footerLiveChatStyles = {
//   width: '100%',
//   display: 'flex',
//   padding: spacing(0, 2),

//   '& > img': {
//     display: 'block',
//   },

//   [responsive('lg')]: {
//     justifyContent: 'flex-end',
//     marginTop: spacing(2),
//     width: '14.285714286%',
//   },
// };

const footerLegalsStyles = {
  display: 'flex',
  flexWrap: 'wrap',
  margin: spacing(4, 0, 0),
  padding: spacing(0, 2),
  alignItems: 'baseline',

  [responsive('lg')]: {
    margin: spacing(9, 0, 0),
  },
};

const cookieWrapperStyles = {
  '& > a': {
    display: 'inline-flex !important',
    gap: '5px',
    alignItems: 'center',
  },
  '& svg': {
    marginBottom: -3,
  },
};

const footerCopyrightStyles = {
  ...typography.caption,
  flex: '0 0 100%',
  opacity: 0.8,
  marginBottom: '10px',

  [responsive(mobileBreakpoint)]: {
    flex: '0 1 auto',
    marginRight: '10px',
    marginBottom: '0',
  },
};

const footerTermsStyles = {
  display: 'flex',
  alignItems: 'center',
  alignContent: 'center',
  flex: '0 0 100%',
  flexWrap: 'wrap',

  '& > *': {
    marginRight: spacing(1),

    '&.divider': {
      opacity: 0.8,
      lineHeight: 0.8,
    },
  },

  [responsive(mobileBreakpoint)]: {
    flex: 1,
  },
};

const footerListStyles = {
  padding: 0,
  marginTop: '1px',
  listStyle: 'none',
};

const footerLinkStyles = {
  ...typography.caption,
  color: 'inherit',
  textDecoration: 'none',
  transition: 'opacity 150ms ease-in-out',
  opacity: 0.8,
  // lineHeight: spacing(3.5),
  padding: spacing(1, 0),
  display: 'block',
  // whiteSpace: 'nowrap',

  '&.legals': {
    display: 'inline-block',
    whiteSpace: 'nowrap',
  },

  '&:hover': {
    '&:not(span)': {
      opacity: 1,
    },
  },
};

const footerCategoryLinkStyles = {
  fontWeight: 'bold',
  color: 'inherit',
  textDecoration: 'none',
  display: 'block',
  padding: spacing(1, 0),
};

const buttonLinkStyles = {
  ...components.button.normal.outlined,
  display: 'inline-flex',
};

const socialLinksStyles = {
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  marginTop: spacing(1),

  '& > *': {
    '&:first-child': {
      marginLeft: spacing(-1.75),
    },
  },
};

const socialLinkStyles = {
  display: 'flex',
  color: 'inherit',
  justifyContent: 'center',
  width: spacing(4.5),
  height: spacing(4.5),
  padding: spacing(1),
  opacity: 0.8,

  '& .icon': {
    width: '100%',
  },

  '& svg': {
    width: '100%',
    height: '100%',

    '& *': {
      fill: 'currentColor',
    },
  },

  '&:hover': {
    '&:not(span)': {
      opacity: 1,
    },
  },
};

const useStyles = makeStyles({
  '@global': globalStyles,
  root: rootSyles,
  footer: footerStyles,
  footerContainer: footerContainerStyles,
  footerRow: footerRowStyles,
  footerColumn: footerColumnStyles,
  footerList: footerListStyles,
  footerLink: footerLinkStyles,
  footerCategoryLink: footerCategoryLinkStyles,
  buttonLink: buttonLinkStyles,
  footerLogo: footerLogoStyles,
  // TODO: livechat temporarily removed
  // footerLiveChat: footerLiveChatStyles,
  footerLegals: footerLegalsStyles,
  footerCopyright: footerCopyrightStyles,
  footerTerms: footerTermsStyles,
  socialLinks: socialLinksStyles,
  socialLink: socialLinkStyles,
  cookieWrapper: cookieWrapperStyles,
});

export default useStyles;
