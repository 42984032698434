import { html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
import { makeStyles2 } from '../../../core';

const useBadgeNotificationStyles = makeStyles2(() => ({
  badge: {
    width: '5px',
    height: '5px',
    background: '#15D08D',
    display: 'block',
    borderRadius: '50%',
  },
}));

@customElement('gntc-notification-badge')
export class GntcNotificationBadge extends LitElement {
  static styles = useBadgeNotificationStyles().css;
  protected render(): unknown {
    const { classes } = useBadgeNotificationStyles();

    return html`<span class="${classes.badge}"></span>`;
  }
}
