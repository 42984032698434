import { set, pipe } from 'lodash/fp';
import axios from 'axios';
import { cleanDashesInUrl, generateSearchParams } from './SearchService.utils';

declare global {
  interface Window {
    gntcConfig: IGntcConfig;
    cmsBaseUrl: string;
  }
}

interface SearchEngineServiceProps {
  data: any;
  engine: string;
  authEngine?: string;
  isAuthenticated: boolean;
  config?: any;
  urlPath: string;
  language: string;
  token: string;
}

interface IGntcConfig {
  httpClient: any;
}

const DEFAULT_ENGINE = 'dev-resources';
const ALL_LANGS = ['en', 'fr', 'de', 'nl', 'pt', 'es', 'zh', 'ko', 'jp', 'it'];

const PUBLIC_SEARCH_API =
  'https://e9086f645f4348188a99892a36b61d30.ent-search.us-east-1.aws.cloud.es.io/api/as/v1/engines';
const AUTHENTICATED_SEARCH_API = cleanDashesInUrl(
  `${window.cmsBaseUrl}/restservices/portal/engines`,
);

const getGntcConfig = (): IGntcConfig => ({
  httpClient: window.gntcConfig?.httpClient || axios,
});

export const getUseAuthEngine = ({ language = '', isAuthenticated = false }) => {
  // TODO remove this when other languages are available
  const isEnglish = language === 'en';
  return isAuthenticated && isEnglish;
};

export const searchEngineService = async ({
  data,
  engine,
  authEngine = engine,
  isAuthenticated = false,
  config,
  urlPath = '',
  language = '',
  token = '',
}: SearchEngineServiceProps) => {
  const useAuthEngine = getUseAuthEngine({ language, isAuthenticated });
  const overrideConfig = !useAuthEngine
    ? {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    : undefined;

  const engineName = useAuthEngine ? authEngine : engine;
  const baseUrl = useAuthEngine ? AUTHENTICATED_SEARCH_API : PUBLIC_SEARCH_API;
  const { data: dataResponse } = await getGntcConfig().httpClient.post(
    `${baseUrl}/${engineName}${urlPath}`,
    data,
    {
      ...config,
      ...overrideConfig,
    },
  );
  return dataResponse;
};

export const getResults = (
  token: string,
  isLogged: boolean,
  query: string,
  size: number,
  engine = DEFAULT_ENGINE,
  authEngine = DEFAULT_ENGINE,
  language = '',
) => {
  const none = ALL_LANGS.filter((l) => l !== language).map((l) => ({ language: l }));

  let searchParams = generateSearchParams(query, size, language, none);

  if (getUseAuthEngine({ language, isAuthenticated: isLogged })) {
    searchParams = pipe(
      set('result_fields.is_partner_only', {
        raw: {},
      }),
      set('result_fields.category', {
        raw: {},
      }),
      set('facets.category', {
        type: 'value',
        size: 30,
      }),
    )(searchParams);
  }

  return searchEngineService({
    engine,
    authEngine,
    isAuthenticated: isLogged,
    urlPath: '/search',
    data: searchParams,
    language,
    token,
  });
};

export const getSuggestion = (
  token: string,
  queryValue: string,
  size: number,
  isAuthenticated: boolean,
  engine = DEFAULT_ENGINE,
  language: string,
) => {
  return searchEngineService({
    engine,
    urlPath: '/query_suggestion',
    isAuthenticated,
    data: {
      query: queryValue,
      types: {
        documents: {
          fields: ['title', 'body', 'description'],
        },
      },
      size,
    },
    language,
    token,
  });
};

export const trackSearchClick = async (
  token: string,
  query: string,
  engine = DEFAULT_ENGINE,
  document_id: string,
  language: string,
  request_id?: string,
  tags?: string[],
) => {
  try {
    await searchEngineService({
      engine,
      urlPath: '/click',
      isAuthenticated: false,
      data: {
        query,
        document_id,
        ...(request_id && { request_id }),
        ...(tags && { tags }),
      },
      language,
      token,
    });
    return true;
  } catch (e) {
    console.error(e);
    return null;
  }
};
