import { PropertyValues } from 'lit';
import { customElement } from 'lit/decorators.js';
import { captureClicks } from '../core/utils';
import { GlobalHeaderBase } from './gntc-global-header-base';

@customElement('gntc-global-header')
class GlobalHeader extends GlobalHeaderBase {
  public ready = false;

  protected firstUpdated(_changedProperties: PropertyValues) {
    super.firstUpdated(_changedProperties);

    Promise.resolve().then(() => {
      if (this.shadowRoot) {
        const newReadyEvent = new CustomEvent('ready', {
          bubbles: true,
          composed: true,
        });
        this.shadowRoot.dispatchEvent(newReadyEvent);
        this.ready = true;

        captureClicks({
          context: this.shadowRoot,
          isExternal: this.isExternal,
        });

        document.addEventListener('click', () => this.rootElement?.classList.remove('a11y'));
        document.addEventListener('keydown', (e: KeyboardEvent) => {
          const isTab = /^tab$/i.test(e.key);

          if (isTab) {
            this.rootElement?.classList.add('a11y');
          }
        });
      }
    });
  }
}

export default GlobalHeader;

declare global {
  interface HTMLElementTagNameMap {
    'gntc-global-header': GlobalHeader;
  }
}
