import { html, TemplateResult } from 'lit';
import { ifDefined } from 'lit/directives/if-defined.js';

export interface RenderLinkProps {
  linkProps: LinkType;
  classes?: any;
  icon?: TemplateResult;
  onClick?: (e: MouseEvent) => void;
  datalayerScenario?: string;
  datalayerType?: string;
  datalayerVariant?: string;
  datalayerIgnoreLink?: boolean;
}

export class GntcGlobalHeaderLinkMixin {
  constructor(private $headerRef: any) {}

  getLink({
    linkProps,
    classes,
    icon,
    onClick,
    datalayerScenario = 'menu-link',
    datalayerType,
    datalayerVariant = '',
    datalayerIgnoreLink,
  }: RenderLinkProps) {
    const { displayText, openInNewWindow, href } = linkProps;

    if (!displayText || displayText.trim().length === 0) return;

    if (!href) {
      return html`
        <span class="${classes}" @click=${(e: MouseEvent) => onClick && onClick(e)}>
          ${icon}
          <span class="text">${displayText}</span>
        </span>
      `;
    }

    return html`
      <a
        data-datalayer-scenario=${ifDefined(datalayerScenario ? datalayerScenario : undefined)}
        data-datalayer-type=${ifDefined(datalayerType ? datalayerType : undefined)}
        data-datalayer-ignore-link=${ifDefined(
          datalayerIgnoreLink ? datalayerIgnoreLink : undefined,
        )}
        data-datalayer-variant=${ifDefined(
          datalayerVariant || this.$headerRef.datalayerVariant
            ? datalayerVariant || this.$headerRef.datalayerVariant
            : undefined,
        )}
        class="${classes}"
        tabindex="0"
        href="${href}"
        target="${openInNewWindow ? '_blank' : '_self'}"
        @click=${(e: MouseEvent) => onClick && onClick(e)}
      >
        ${icon}
        <span class="text">${displayText}</span>
      </a>
    `;
  }
}
