const button = (theme: Theme) => ({
  ...theme.typography.button,
  display: 'flex',
  alignItems: 'center',
  alignContent: 'center',
  padding: theme.spacing(0, 3),
  height: '40px',
  color: 'inherit',
  textDecoration: 'none',
  borderRadius: '4px',
  paddingBottom: '1px',

  '& .icon': {
    marginLeft: '-10px',
    width: '36px',
    height: '36px',
    position: 'relative',
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',

    '& img': {
      maxWidth: '24px',
    },
  },

  '& .text': {
    whiteSpace: 'nowrap',
    position: 'relative',
  },
});

const buttonSmall = (theme: Theme) => ({
  ...button(theme),
  height: '36px',
  fontSize: '12px',
  fontWeight: theme.typography.fontWeightMedium,
  padding: theme.spacing(0, 1.5),
  paddingBottom: '2px',

  [theme.responsive(theme.mobileBreakpoint)]: {
    fontSize: '13px',
    padding: theme.spacing(0, 2.375),
  },
});

const filled = (theme: Theme) => ({
  backgroundColor: theme.palette.secondary.color,
  color: theme.palette.secondary.text,
  transition: 'background-color 150ms ease-in-out',

  '&:hover, &:focus': {
    backgroundColor: theme.palette.secondary.light.color,
  },
});

const outlined = (theme: Theme) => ({
  border: `1px solid ${
    theme.mode === 'dark' ? theme.palette.secondary.color : theme.palette.secondary.dark.color
  }`,
  color:
    theme.mode === 'dark'
      ? theme.palette.background.dark.text
      : theme.palette.background.light.text,
  boxSizing: 'border-box',
  transition: 'border-color 150ms ease-in-out',
  position: 'relative',

  '& span': {
    position: 'relative',
  },

  '&:before': {
    backgroundColor: '#000000',
    content: '""',
    height: '100%',
    left: 0,
    opacity: 0,
    position: 'absolute',
    top: 0,
    transition: 'opacity 150ms ease-in-out',
    width: '100%',
  },

  '&:hover, &:focus': {
    borderColor:
      theme.mode === 'dark' ? theme.palette.secondary.light.color : theme.palette.secondary.color,

    '&:before': {
      opacity: theme.mode === 'dark' ? 0.15 : 0.05,
    },
  },
});

export default (theme: Theme) => ({
  normal: {
    filled: { ...button(theme), ...filled(theme) },
    outlined: { ...button(theme), ...outlined(theme) },
  },
  small: {
    filled: { ...buttonSmall(theme), ...filled(theme) },
    outlined: { ...buttonSmall(theme), ...outlined(theme) },
  },
});
