import { html, LitElement, PropertyValues } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { captureClicks } from '../../core/utils';
import useMenuLinkStyles from './gntc-menu-link.styles';

@customElement('gntc-menu-link')
export class GntcMenuLink extends LitElement {
  static styles = useMenuLinkStyles().css;

  @property({ type: String, reflect: true })
  protected iconUrl = '';

  @property({ type: String, reflect: true })
  protected href = '';

  @property({ type: String, reflect: true })
  protected label = '';

  @property({ type: String, reflect: true })
  protected target = '_self';

  @property({ type: String, reflect: true })
  protected color = 'white';

  protected firstUpdated(_changedProperties: PropertyValues) {
    super.firstUpdated(_changedProperties);

    Promise.resolve().then(() => {
      if (this.shadowRoot) {
        captureClicks({ context: this.shadowRoot });
      }
    });
  }

  protected render(): unknown {
    const { classes } = useMenuLinkStyles();
    const linkClasses = classMap({
      [classes.menuLink]: true,
      [classes.colorBlueLink]: this.color === 'blue',
      [classes.colorWhiteLink]: this.color === 'white',
      [classes.isLink]: !!this.href,
    });
    if (!this.href) {
      return html`
        <span class="${linkClasses}">
          ${this.iconUrl && html`<img src="${this.iconUrl}" alt="" />`} ${this.label}
        </span>
      `;
    }
    return html`
      <a class="${linkClasses}" href="${this.href}" target="${this.target}">
        ${this.iconUrl && html`<img src="${this.iconUrl}" alt="" />`} ${this.label}
      </a>
    `;
  }
}
