import { makeStyles2 } from '../../core';
import { linkHoverStyle } from '../gntc-global-header.styles';

const gntcMenuLinkStyles = ({ spacing, palette }: any) => ({
  menuLink: {
    display: 'inline-flex',
    alignItems: 'center',
    textDecoration: 'none',
    fontSize: '14px',
    padding: spacing(0.5, 1),
    margin: spacing(-0.5, -1),
    userSelect: 'none',
    '& > img ': {
      margin: spacing(0, 1, 0, 0),
      width: '14px',
      height: '14px',
    },
  },
  isLink: {
    cursor: 'pointer',
    ...linkHoverStyle(),
  },
  colorBlueLink: {
    '&:not(:hover), &:hover': {
      color: palette?.secondary?.color,
    },
  },
  colorWhiteLink: {
    '&:not(:hover), &:hover': {
      color: palette?.primary?.text,
    },
  },
});

export default makeStyles2(gntcMenuLinkStyles);
