import { makeStyles2 } from '../../core';

const notificationsOptionStyles = () => ({
  notificationsOptionContainer: {
    position: 'relative',
  },
  icon: {
    width: '16px',
  },
  notificationBadge: {
    position: 'absolute',
    top: '6px',
    right: '6px',
  },
  contentWrapper: {
    minHeight: '60px',
  },
  mobileCloseIcon: {
    position: 'absolute',
    top: '24px',
    right: '32px',
  },
});

export default makeStyles2(notificationsOptionStyles);
