import arrow from './arrow';
import facebook from './facebook';
import twitter from './twitter';
import linkedin from './linkedin';
import instagram from './instagram';

export { closeSvgIcon } from './close';

export const arrowSvg = arrow;
export const facebookSvg = facebook;
export const twitterSvg = twitter;
export const linkedinSvg = linkedin;

export const instagramSvg = instagram;
