import useStyles from '../gntc-global-header.styles';
import { MenuGroup, MenuGroupSection } from '../gntc-global-header.interface';

export enum LinkStyles {
  standard = 'standard',
  highlighted = 'highlighted',
  button = 'button',
  filled = 'filled',
}

export class GntcMainNavigationUtilsMixin {
  constructor(protected $globalHeaderRef: any) {}
  getMenuItemStyleClass(style: LinkStyles = LinkStyles.standard) {
    const linkClassMap: Record<LinkStyles, any> = {
      standard: useStyles.classes.secondaryNavStandardLink,
      highlighted: useStyles.classes.secondaryNavHighlightLink,
      button: useStyles.classes.buttonLink,
      filled: useStyles.classes.filledLink,
    };

    return linkClassMap[style] || useStyles.classes.secondaryNavStandardLink;
  }

  extractFullWidthFormGroups(menuGroups: MenuGroup[]): [MenuGroup[], MenuGroup[]] {
    const columnMenuGroups = menuGroups.filter((mg) => !mg.fullWidth);
    const fullWidthMenuGroups = menuGroups.filter((mg) => mg.fullWidth);
    return [columnMenuGroups, fullWidthMenuGroups];
  }

  hasPromoOrIconLink(menuGroupList: MenuGroupSection[]) {
    const variants = menuGroupList?.map((menuGroupSection) => menuGroupSection?.variant);
    return variants.some((variant) => variant === 'promo' || variant === 'iconLink');
  }

  hasSectionWithTitle(menuGroupList: MenuGroupSection[]) {
    return menuGroupList.some(
      (menuGroupSection) => menuGroupSection.primaryNavigationColumnTitle?.displayText,
    );
  }
}
