import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { MenuGroup } from '../../gntc-global-header.interface';
import useStyles from '../../gntc-global-header.styles';
import '../../shared/gntc-horizontal-divider';
import '../../shared/gntc-vertical-divider';
import { ILoginSectionLabels } from '../interfaces';
import './content/gntc-app-services';
import './content/gntc-partner-portal-login';

@customElement('gntc-login-content')
export class LoginSectionBase extends LitElement {
  @property({ type: Array, reflect: true })
  menuGroup = [];

  @property({ type: String, reflect: false })
  datalayerVariant?: string = '';

  @property({ type: Boolean, reflect: true })
  isLoginMobile = false;

  @property({ reflect: true })
  loginSectionLabels: ILoginSectionLabels = {};

  @property({ type: String, reflect: true })
  registrationUrl = '';

  @property({ type: Boolean, reflect: true })
  protected isExternal = false;

  protected onLoginClicked(e: Event) {
    e.preventDefault();
    this.dispatchEvent(new CustomEvent('onLoginClick'));
  }

  protected getMenuSection(menuGroup: MenuGroup[]) {
    const hasNavigation = menuGroup?.length > 0;
    return html`${hasNavigation ? this.getMenuGroup(menuGroup) : undefined} `;
  }

  protected getMenuGroup(menuGroups: MenuGroup[]) {
    const {
      secNavContainer: secNavContainerClass,
      secNavMenuGroup: secNavMenuGroupClass,
      loginContentWrapper,
    } = useStyles.classes;
    const secNavClasses = classMap({
      secNavMain: true,
      loginSecNavMainMobile: this.isLoginMobile,
    });
    const secNavContainerClasses = classMap({
      [secNavContainerClass]: true,
      secNavContainer: true,
    });
    const secNavMenuGroupClasses = classMap({
      [secNavMenuGroupClass]: true,
      ['columns-2']: !this.isExternal,
      ['columns-1']: this.isExternal,
      [loginContentWrapper]: true,
    });

    return html`
      <div class="${secNavClasses}">
        <div class="${secNavContainerClasses}">
          <div class="${secNavMenuGroupClasses}">
            ${!this.isExternal
              ? html`
                  <gntc-partner-portal-login
                    class="column"
                    datalayerVariant="${this.datalayerVariant}"
                    .loginSectionTitle="${this.loginSectionLabels.login_section_title_label}"
                    .loginButtonLabel="${this.loginSectionLabels.login_label}"
                    .notAccountLabel="${this.loginSectionLabels.not_account_label}"
                    .registerActionLabel="${this.loginSectionLabels.register_action_label}"
                    .registrationUrl="${this.registrationUrl}"
                    @onLoginClick="${(e: Event) => this.onLoginClicked(e)}"
                  ></gntc-partner-portal-login>
                  ${this.isLoginMobile
                    ? html`<gntc-horizontal-divider></gntc-horizontal-divider>`
                    : html`<gntc-vertical-divider></gntc-vertical-divider>`}
                `
              : null}
            <gntc-app-services class="column" .appServices="${menuGroups}"></gntc-app-services>
          </div>
        </div>
      </div>
    `;
  }

  protected render(): unknown {
    return html` <div>${this.getMenuSection(this.menuGroup)}</div> `;
  }

  createRenderRoot() {
    return this;
  }
}
