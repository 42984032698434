import { LitElement, PropertyValues, TemplateResult, unsafeCSS, html } from 'lit';
import { query, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { getHref } from '../core/resolvers';
import { isPathAbsolute, safeJSONParse } from '../core/utils';
import { when } from '../libs/custom-lit-directives/when';
import { facebookSvg, instagramSvg, linkedinSvg, twitterSvg } from '../shared/icons';
import { OnetrustCheckIcon } from '../shared/icons/onetrust-check';
import { Category } from './gntc-global-footer.interface';
import useStyles from './gntc-global-footer.styles';

export class GlobalFooterBase extends LitElement {
  static styles = unsafeCSS(useStyles.toString());

  @query(`.${useStyles.classes.root}`)
  rootElement?: HTMLDivElement;

  @query(`.${useStyles.classes.footer}`)
  footerElement?: HTMLDivElement;

  @property({ type: Boolean, reflect: true })
  protected isExternal = false;

  @property({ type: String, reflect: true })
  protected cmsBaseUrl = '';

  @property({ type: String, reflect: true })
  protected spaBaseUrl = '';

  @property({ type: Array, reflect: false })
  protected categories?: Category[];

  @property({ type: String, reflect: false })
  protected config?: Record<string, string>;

  @property({ type: String, reflect: false })
  protected labels?: Record<string, string>;

  @property({ type: String, reflect: false })
  datalayerVariant?: string = '';

  protected getLogo(image: string, label: string) {
    const { logo: logoClass } = useStyles.classes;
    return html`<img class="${logoClass}" src="${this.getUrl(image)}" alt="${label}" />`;
  }

  // TODO: livechat temporarily removed
  // protected getButtonLink() {
  //   const { live_chat_icon } = this.config || {};
  //   const { live_chat_text } = this.labels || {};
  //   const { buttonLink: buttonLinkClass } = useStyles.classes;
  //   const buttonLinkClasses = classMap({
  //     [buttonLinkClass]: true,
  //   });

  //   const handleOpenLivechat = (e: MouseEvent) => {
  //     e.preventDefault();
  //     const newEvent = new CustomEvent('openLivechat');

  //     this.dispatchEvent(newEvent);
  //   };

  //   const linkProps = {
  //     displayText: live_chat_text || 'Live Chat',
  //     openInNewWindow: false,
  //     href: '#',
  //   };

  //   return live_chat_text
  //     ? html`${this.getLink(
  //         linkProps,
  //         buttonLinkClasses,
  //         live_chat_icon,
  //         false,
  //         handleOpenLivechat,
  //         'livechat',
  //       )}`
  //     : undefined;
  // }

  protected getLink(
    linkProps: LinkType,
    classes: ReturnType<typeof classMap>,
    icon?: string | TemplateResult,
    iconOnly = false,
    onClick?: (e: MouseEvent) => void,
    datalayerScenario = 'footer',
    appendIcon?: TemplateResult,
  ) {
    const { displayText, openInNewWindow, href } = linkProps;

    const getIcon = () => html`
      <div class="icon">
        ${typeof icon === 'string'
          ? html`<img src="${this.getUrl(icon as string)}" alt="${displayText || ''}" />`
          : icon}
      </div>
    `;

    const getText = () => html`
      ${icon ? getIcon() : undefined}
      ${iconOnly ? undefined : html`<span class="text">${displayText}</span>`}
    `;

    if (!href) {
      return html`<span class="${classes}">${getText()}</span>`;
    }

    return html`
      <a
        data-datalayer-scenario=${ifDefined(datalayerScenario ? datalayerScenario : undefined)}
        data-datalayer-variant=${ifDefined(
          this.datalayerVariant ? this.datalayerVariant : undefined,
        )}
        class="${classes}"
        tabindex="0"
        href="${href}"
        target="${openInNewWindow ? '_blank' : '_self'}"
        aria-label="${icon ? displayText || '' : ''}"
        @click=${(e: MouseEvent) => onClick && onClick(e)}
      >
        ${getText()} ${when(appendIcon, () => appendIcon)}
      </a>
    `;
  }

  protected getTermsLink(linkProps: LinkType, onClick?: () => void, appendIcon?: TemplateResult) {
    const { footerLink: footerLinkClass } = useStyles.classes;
    const props = { ...linkProps, openInNewWindow: isPathAbsolute(linkProps.href || '') };
    const footerLinkClasses = classMap({
      [footerLinkClass]: true,
      legals: true,
    });

    return html` ${this.getLink(
      props,
      footerLinkClasses,
      undefined,
      false,
      onClick,
      'footer',
      appendIcon,
    )}`;
  }

  protected getCategory(footerList: Category) {
    const {
      footerColumn: footerColumnClass,
      footerList: footerListClass,
      footerCategoryLink: footerCategoryLinkClass,
    } = useStyles.classes;

    const categoryLinkClass = classMap({
      [footerCategoryLinkClass]: true,
    });

    return html`
      <div class="${footerColumnClass}">
        <slot name="${footerList.name}"></slot>
        ${this.getLink(footerList.title, categoryLinkClass)}
        <ul class="${footerListClass}">
          ${footerList.links.map((link) => this.getCategoryItem(link))}
        </ul>
      </div>
    `;
  }

  protected getConnectCategory() {
    const { connect_category_text, contact_text } = this.labels || {};
    const { contact_link } = this.config || {};
    const {
      footerColumn: footerColumnClass,
      footerList: footerListClass,
      footerCategoryLink: footerCategoryLinkClass,
    } = useStyles.classes;
    const categoryLinkClass = classMap({
      [footerCategoryLinkClass]: true,
    });
    const contactProps = {
      displayText: contact_text,
      openInNewWindow: false,
      href: this.getUrl(contact_link),
    };

    return html`
      <div class="${footerColumnClass}">
        ${this.getLink({ displayText: connect_category_text }, categoryLinkClass)}
        <ul class="${footerListClass}">
          ${this.getCategoryItem(contactProps)} ${this.getSocialIcons()}
        </ul>
      </div>
    `;
  }

  protected getSocialIcons() {
    const { socialLinks: socialLinksClass, socialLink: socialLinkClass } = useStyles.classes;
    const socialLinkClasses = classMap({ [socialLinkClass]: true });
    const { facebook_link, twitter_link, linkedin_link, instagram_link } = this.config || {};
    const { facebook_text, twitter_text, linkedin_text, instagram_text } = this.labels || {};
    const facebookLinkProps = facebook_link && {
      displayText: facebook_text,
      openInNewWindow: true,
      href: facebook_link,
    };
    const twitterLinkProps = twitter_link && {
      displayText: twitter_text,
      openInNewWindow: true,
      href: twitter_link,
    };
    const linkedinLinkProps = linkedin_link && {
      displayText: linkedin_text,
      openInNewWindow: true,
      href: linkedin_link,
    };
    const instagramLinkProps = instagram_link && {
      displayText: instagram_text,
      openInNewWindow: true,
      href: instagram_link,
    };

    return html`
      <li class="${socialLinksClass}">
        ${facebookLinkProps
          ? this.getLink(
              facebookLinkProps,
              socialLinkClasses,
              facebookSvg,
              true,
              undefined,
              'social',
            )
          : undefined}
        ${twitterLinkProps
          ? this.getLink(twitterLinkProps, socialLinkClasses, twitterSvg, true, undefined, 'social')
          : undefined}
        ${linkedinLinkProps
          ? this.getLink(
              linkedinLinkProps,
              socialLinkClasses,
              linkedinSvg,
              true,
              undefined,
              'social',
            )
          : undefined}
        ${instagramLinkProps
          ? this.getLink(
              instagramLinkProps,
              socialLinkClasses,
              instagramSvg,
              true,
              undefined,
              'social',
            )
          : undefined}
      </li>
    `;
  }

  protected getCategoryItem(linkProps: LinkType) {
    const { footerLink: footerLinkClass } = useStyles.classes;

    const footerLinkClasses = classMap({
      [footerLinkClass]: true,
    });

    return html`<li>${this.getLink(linkProps, footerLinkClasses)}</li>`;
  }

  protected getLegals() {
    const {
      privacy_link,
      terms_link,
      legal_link,
      patents_link,
      secure_comm_link,
      social_resp_link,
      email_link,
    } = this.config || {};
    const {
      privacy_text,
      terms_text,
      patents_text,
      legal_text,
      secure_comm_text,
      copyright_text,
      social_resp_text,
      email_text,
      cookie_text,
    } = this.labels || {};
    const {
      footerCopyright: footerCopyrightClass,
      footerTerms: footerTermsClass,
      footerLegals: footerLegalsClass,
      cookieWrapper: cookieWrapperClass,
    } = useStyles.classes;

    return html`
      <div class="${footerLegalsClass}">
        <div class="${footerCopyrightClass}">${copyright_text}</div>
        <div class="${footerTermsClass}">
          ${privacy_text
            ? this.getTermsLink({
                displayText: privacy_text,
                href: this.getUrl(privacy_link),
              })
            : undefined}
          <span class="divider">${terms_text ? ' | ' : undefined}</span>
          ${terms_text
            ? this.getTermsLink({
                displayText: terms_text,
                href: this.getUrl(terms_link),
              })
            : undefined}
          <span class="divider">${legal_text ? ' | ' : undefined}</span>
          ${legal_text
            ? this.getTermsLink({
                displayText: legal_text,
                href: this.getUrl(legal_link),
              })
            : undefined}
          <span class="divider">${patents_text ? ' | ' : undefined}</span>
          ${patents_text
            ? this.getTermsLink({
                displayText: patents_text,
                href: this.getUrl(patents_link),
              })
            : undefined}
          <span class="divider">${secure_comm_text ? ' | ' : undefined}</span>
          ${secure_comm_text
            ? this.getTermsLink({
                displayText: secure_comm_text,
                href: this.getUrl(secure_comm_link),
              })
            : undefined}
          <span class="divider">${social_resp_text ? ' | ' : undefined}</span>
          ${social_resp_text
            ? this.getTermsLink({
                displayText: social_resp_text,
                href: this.getUrl(social_resp_link),
              })
            : undefined}
          <span class="divider">${social_resp_text ? ' | ' : undefined}</span>
          ${when(email_text, () =>
            this.getTermsLink({
              displayText: email_text,
              href: this.getUrl(email_link),
            }),
          )}
          <span class="divider">${cookie_text ? ' | ' : undefined}</span>
          <span class="${cookieWrapperClass}">
            ${when(cookie_text, () =>
              this.getTermsLink(
                {
                  displayText: cookie_text,
                  href: 'javascript:void(0)',
                },
                () => {
                  const onCookieSettingClick = new CustomEvent('onCookieSettingClick');
                  this.dispatchEvent(onCookieSettingClick);
                },
                OnetrustCheckIcon,
              ),
            )}
          </span>
        </div>
      </div>
    `;
  }

  protected getContainer() {
    const { genetec_logo: genetecLogoUrl } = this.config || {};
    const { genetec_logo: genetecLogoLabel } = this.labels || {};
    const {
      footerContainer: footerContainerClass,
      footerRow: footerRowClass,
      footerLogo: footerLogoClass,
    } = useStyles.classes;

    return html`
      <div class="${footerContainerClass}">
        <div class="${footerRowClass}">
          <slot name="menuList"></slot>
          <div class="${footerLogoClass}">
            ${genetecLogoUrl ? this.getLogo(genetecLogoUrl, genetecLogoLabel) : undefined}
          </div>
          ${this.categories ? this.categories.map((cat) => this.getCategory(cat)) : undefined}
          ${this.getConnectCategory()}
        </div>

        ${this.getLegals()}
      </div>
    `;
  }
  //  TODO: livechat temporarily removed from above:
  //  <div class="${footerLiveChatClass}">
  //   ${!this.isExternal ? html`${this.getButtonLink()}` : undefined}
  // </div>

  protected getFooter() {
    const { root: rootClass, footer: footerClass } = useStyles.classes;
    const footerClasses = classMap({
      [footerClass]: true,
    });

    return html`
      <div class="${rootClass}">
        <div class="${footerClasses}">${this.getContainer()}</div>
      </div>
    `;
  }

  protected render() {
    return html`${this.getFooter()}`;
  }

  protected shouldUpdate(_changedProperties: PropertyValues) {
    if (_changedProperties.has('config')) {
      this.config = safeJSONParse(this.config);
    }

    if (_changedProperties.has('labels')) {
      this.labels = safeJSONParse(this.labels);
    }

    if (_changedProperties.has('categories')) {
      this.categories = safeJSONParse(this.categories);
    }

    return super.shouldUpdate(_changedProperties);
  }

  protected getUrl(path: string) {
    return getHref({ url: path, spaBaseUrl: this.spaBaseUrl });
  }
}
